.working {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cb333b;
  padding: 25% 0 0;
  height: 100%;
  width: 100%;
}

.working-row {
  align-items: flex-end;
  height: 85%;
}

.working-col-text {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.working-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
}

.working-col-text h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
}

.working-col-text img {
  width: 30%;
}

.progress-end {
  background-color: #fff;
  margin: 0 3rem;
  padding: 0.125rem;
  width: 80%;
  border: 0.188rem solid #000;
  height: 1.875rem;
}

.progress-end .progress-end-bar {
  height: 100%;
  width: 100%;
  background-color: #cb333b;
  animation: fill-bar 3s infinite;
}

.working-col-arrow {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.working-col-arrow p {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.5rem;
  margin: 0;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .working-col-arrow p {
    font-size: 4rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .working-col-text h1 {
    font-size: 2.6rem;
  }

  .working-col-text h2 {
    font-size: 1.35rem;
  }

  .working-col-arrow p {
    font-size: 4.5rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .working-col-text h1 {
    font-size: 2.65rem;
  }

  .working-col-text h2 {
    font-size: 1.375rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .working-col-text h1 {
    font-size: 2.659rem;
  }

  .working-col-text h2 {
    font-size: 1.379rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .working-col-text h1 {
    font-size: 2.8rem;
  }

  .working-col-text h2 {
    font-size: 1.5rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .working-col-text h1 {
    font-size: 2.9rem;
  }

  .working-col-text h2 {
    font-size: 1.6rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 1072px) {
  .working-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .working-col-text h2 {
    font-size: 1.7rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-width: 690px) {
  .working-col-text h1 {
    font-size: 3.5rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .working-col-text h2 {
    font-size: 2.2rem;
    width: 75%;
  }

  .working-col-text img {
    width: 20%;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-width: 921px) {
  .working-col-text h1 {
    font-size: 4rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .working-col-text h2 {
    font-size: 2.4rem;
  }

  .working-col-arrow p {
    font-size: 6rem;
  }
}

@media screen and (min-width: 992px) {
  .working {
    padding: 8% 0 0;
  }

  .working-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 85%;
  }

  .working-col-text h2 {
    font-size: 2.8rem;
    width: 70%;
  }

  .working-col-text img {
    width: 10%;
  }

  .working-col-arrow p {
    font-size: 6.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .working-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 4%;
    width: 75%;
  }

  .working-col-text h2 {
    font-size: 3rem;
    width: 65%;
  }

  .working-col-arrow p {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1680px) {
  .working-col-text h1 {
    font-size: 5.5rem;
    margin: 0 0 3%;
    width: 70%;
  }

  .working-col-text img {
    width: 8%;
  }

  .working-col-text h2 {
    font-size: 3.4rem;
    width: 50%;
  }
}
