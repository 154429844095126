.BlogProfile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.BlogProfile-Title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 25%;
  width: 80%;
}

.BlogProfile-Title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.BlogProfile-Title h1 {
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  color: #000;
  line-height: 1;
  margin: 0 0 1rem;
  width: 40%;
}

.BlogProfile-Title a {
  display: none;
}

.BlogProfile-Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 60%;
}

.BlogProfile-Article {
  display: flex;
  flex-direction: column;
  width: 23%;
  height: 100%;
}

.BlogProfile-Article h2 {
  color: #000;
  font-size: 2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 0.5rem;
}

.BlogProfile-Article h4 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 1rem 0 0;
}

@media screen and (max-width: 479px) {
  .BlogProfile-Title {
    padding: 5%;
    width: 100%;
    height: 20%;
  }

  .BlogProfile-Title h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  }

  .BlogProfile-Title h1 {
    font-size: 3rem;
    line-height: 1;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogProfile-Title a {
    display: block;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    color: unset;
    text-decoration: underline;
  }

  .BlogProfile-Content {
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 70%;
  }

  .BlogProfile-Article {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    width: 100%;
    height: 23%;
  }

  .BlogProfile-Article img {
    width: 80%;
    height: 50%;
  }

  .BlogProfile-Article h2 {
    font-size: 1.5rem;
    margin: 0;
    width: 100%;
  }

  .BlogProfile-Article h4 {
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogProfile-Title h3 {
    font-size: smaller;
  }

  .BlogProfile-Title h1 {
    font-size: 2rem;
  }

  .BlogProfile-Title a {
    font-size: smaller;
  }

  .BlogProfile-Article img {
    width: 80%;
    height: 50%;
  }

  .BlogProfile-Article h2 {
    font-size: 1.2rem;
    margin: 0;
    width: 100%;
  }

  .BlogProfile-Article h4 {
    font-size: smaller;
  }
}

@media screen and (min-height: 719px) and (min-width: 414px) {
  .BlogProfile-Article img {
    width: 80%;
    height: 70%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogProfile-Title {
    padding: 5%;
    width: 100%;
    height: 20%;
  }

  .BlogProfile-Title h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: large;
  }

  .BlogProfile-Title h1 {
    font-size: 4rem;
    line-height: 1;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogProfile-Title a {
    display: block;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    color: unset;
    text-decoration: underline;
    font-size: 1.2rem;
  }

  .BlogProfile-Content {
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 70%;
  }

  .BlogProfile-Article {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    width: 100%;
    height: 23%;
  }

  .BlogProfile-Article img {
    width: 80%;
    height: 70%;
  }

  .BlogProfile-Article h2 {
    font-size: 1.5rem;
    margin: 0;
    width: 100%;
  }

  .BlogProfile-Article h4 {
    margin: 0.5rem 0;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogProfile-Title h1 {
    font-size: 5rem;
    width: 50%;
  }

  .BlogProfile-Title h3 {
    font-size: 1.5rem;
    width: 100%;
  }

  .BlogProfile-Article h2 {
    font-size: 3rem;
  }

  .BlogProfile-Article h4 {
    font-size: 1.2rem;
  }
}
