.contact {
  background-color: #cb333b;
  align-items: flex-end;
  min-height: 100vh;
  width: 100%;
}

.contact-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.contact-col-title h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 5% 0 0;
}

.contact-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.contact-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.contact-col-text h2 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.contact-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.contact-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 0.8rem;
}

.contact-col-calendar {
  height: 40vh;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .contact-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .contact-col-text p {
    font-size: 0.8rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .contact-col-text h1 {
    font-size: 1.8rem;
  }

  .contact-col-text p {
    font-size: 0.9rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .contact-col-title h3 {
    font-size: medium;
  }

  .contact-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .contact-col-text h2 {
    font-size: large;
  }

  .contact-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .contact-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .contact-col-title h3 {
    font-size: large;
  }

  .contact-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .contact-col-text h2 {
    font-size: x-large;
  }

  .contact-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .contact-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .contact-col-title h3 {
    font-size: large;
  }

  .contact-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .contact-col-text h2 {
    font-size: x-large;
  }

  .contact-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .contact-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .contact-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .contact-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-width: 690px) {
  .contact-col-title h3 {
    font-size: large;
  }

  .contact-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .contact-col-text h2 {
    font-size: x-large;
  }

  .contact-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .contact {
    align-items: center;
    align-content: space-evenly;
  }

  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .contact-col-text a {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .contact-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .contact-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .contact-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .contact-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}
