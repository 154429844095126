.BlogIntroduction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #000;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.BlogIntroduction-Left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}

.BlogIntroduction-Left h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 1rem;
  width: 55%;
}

.BlogIntroduction-Left h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogIntroduction-Left h4 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogIntroduction-Left p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 60%;
}

.BlogIntroduction-Left-Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.BlogIntroduction-Left-Title h1 {
  color: #fff;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
}

.BlogIntroduction-Left-Title img {
  width: 15%;
  margin-right: 2rem;
}

.BlogIntroduction-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}

.BlogIntroduction-Right h1 {
  color: #fff;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
}

.BlogIntroduction-Right h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
  margin: 0 0 1rem;
}

.BlogIntroduction-Right h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogIntroduction-Right h4 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogIntroduction-Right p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1;
  width: 75%;
}

.BlogIntroduction-Right img {
  width: 100%;
}

.BlogIntroduction-Left-Content {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.BlogIntroduction-Right-Newsletter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.BlogNewsletter-Description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.BlogNewsletter-Description h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 100%;
}

.BlogNewsletter-Description p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  line-height: 1.3;
  width: 90%;
}

.BlogNewsletter-Form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 70%;
}

.BlogNewsletter-InputName {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.BlogNewsletter-InputName input {
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 50%;
  height: 3rem;
}

.BlogNewsletter-InputName input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-InputName input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Input {
  display: flex;
  width: 75%;
}

.BlogNewsletter-Input input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 100%;
  height: 3rem;
}

.BlogNewsletter-Input input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Input input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 75%;
  margin: 1.5rem 0 1rem;
}

.BlogNewsletter-Button button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 8rem;
  height: 2rem;
}

.BlogNewsletter-Button button:hover {
  cursor: pointer;
}

.Title-Mobile {
  display: none;
}

.Subtitle-Mobile {
  display: none;
}

.BlogIntroduction-ArticleMobile {
  display: none;
}

.BlogIntroduction-NewsletterMobile {
  display: none;
}

.SpiralMobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .BlogIntroduction {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 40% 5% 5%;
    height: 100%;
    width: 100%;
  }

  .Blog-Spiral {
    display: none;
  }

  .SpiralMobile {
    display: flex;
  }

  .BlogIntroduction-Left {
    display: none;
  }

  .BlogIntroduction-Right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .Title-Mobile {
    display: block;
    font-size: 3rem !important;
  }

  .Subtitle-Mobile {
    display: block;
    margin: 0 0 8%;
  }

  .BlogIntroduction-ArticleMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 30%;
  }

  .BlogIntroduction-ArticleMobile img {
    width: 70%;
  }

  .BlogIntroduction-ArticleMobile p {
    margin: 0 0 0.3rem;
    width: 70%;
  }

  .BlogIntroduction-Right img {
    width: 80%;
    height: auto;
  }

  .BlogIntroduction-Right h1 {
    font-size: 1.5rem;
    width: 90%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 1.2rem;
    width: 70%;
    margin: 0 0 0.5rem;
  }

  .BlogIntroduction-Right h3 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    margin: 0 0 2rem;
  }

  .BlogIntroduction-Right h4 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .BlogIntroduction-Right p {
    font-size: 0.75rem;
    width: 75%;
  }

  .BlogIntroduction-Left-Content {
    width: 100%;
  }

  .BlogIntroduction-Right-Newsletter {
    display: none;
  }

  .BlogIntroduction-NewsletterMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BlogNewsletterMobile-Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
  }

  .BlogNewsletterMobile-Description h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1;
    width: 100%;
  }

  .BlogNewsletterMobile-Description p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 0.625rem;
    line-height: 1.438rem;
    width: 100%;
  }

  .BlogNewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 65%;
  }

  .BlogNewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .BlogNewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3rem;
  }

  .BlogNewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input {
    display: flex;
    width: 90%;
  }

  .BlogNewsletterMobile-Input input {
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 3rem;
  }

  .BlogNewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .BlogNewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 8rem;
    height: 2rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogIntroduction {
    padding: 40% 5% 0;
  }

  .BlogIntroduction-ArticleMobile img {
    width: 60%;
  }

  .BlogIntroduction-ArticleMobile p {
    font-size: 0.75rem;
    line-height: 1.2;
    margin: 0 0 0.3rem;
    width: 70%;
  }

  .BlogIntroduction-Right img {
    width: 70%;
    height: auto;
  }

  .BlogIntroduction-Right h1 {
    font-size: 1.2rem;
    width: 90%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 1rem;
    width: 70%;
    margin: 0 0 0.5rem;
  }

  .BlogIntroduction-Right h3 {
    font-size: small;
  }

  .BlogIntroduction-Right h4 {
    font-size: smaller;
  }

  .BlogIntroduction-Right p {
    font-size: 0.75rem;
    width: 75%;
  }

  .BlogNewsletterMobile-Description h2 {
    font-size: 1.2rem;
    line-height: 1;
  }

  .BlogNewsletterMobile-Description p {
    font-size: 0.75rem;
    line-height: 1.1;
  }

  .BlogNewsletterMobile-InputName input {
    font-size: 0.8rem;
    width: 50%;
    height: 2rem;
  }

  .BlogNewsletterMobile-InputName input::placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-InputName input:focus {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Input input {
    font-size: 0.8rem;
    width: 100%;
    height: 2rem;
  }

  .BlogNewsletterMobile-Input input::placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Input input:focus {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Button button {
    font-size: 0.7rem;
    width: 7rem;
    height: 2rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 1050px) {
  .BlogIntroduction {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 20% 10% 0;
    height: 100%;
    width: 100%;
  }

  .Blog-Spiral {
    display: none;
  }

  .SpiralMobile {
    display: flex;
  }

  .BlogIntroduction-Left {
    display: none;
  }

  .BlogIntroduction-Right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .Title-Mobile {
    display: block;
    font-size: 5rem !important;
  }

  .Subtitle-Mobile {
    display: block;
    margin: 0 0 8%;
  }

  .BlogIntroduction-ArticleMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 30%;
  }

  .BlogIntroduction-ArticleMobile img {
    width: 70%;
  }

  .BlogIntroduction-ArticleMobile p {
    margin: 0 0 0.3rem;
    width: 70%;
  }

  .BlogIntroduction-Right img {
    width: 60%;
    height: auto;
  }

  .BlogIntroduction-Right h1 {
    font-size: 3rem;
    width: 90%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 2rem;
    width: 70%;
    margin: 0 0 0.5rem;
  }

  .BlogIntroduction-Right h3 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: large;
    margin: 0 0 2rem;
  }

  .BlogIntroduction-Right h4 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: large;
  }

  .BlogIntroduction-Right p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 75%;
  }

  .BlogIntroduction-Left-Content {
    width: 100%;
  }

  .BlogIntroduction-Right-Newsletter {
    display: none;
  }

  .BlogIntroduction-NewsletterMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BlogNewsletterMobile-Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
  }

  .BlogNewsletterMobile-Description h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 1;
    width: 100%;
  }

  .BlogNewsletterMobile-Description p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1.1;
    width: 100%;
  }

  .BlogNewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 65%;
  }

  .BlogNewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .BlogNewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 4rem;
  }

  .BlogNewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input {
    display: flex;
    width: 90%;
  }

  .BlogNewsletterMobile-Input input {
    color: #fff;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 4rem;
  }

  .BlogNewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .BlogNewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 9rem;
    height: 2.5rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogIntroduction-Left-Title {
    margin: 0 0 6%;
  }

  .BlogIntroduction-Left-Title h1 {
    font-size: 7rem;
  }

  .BlogIntroduction-Left-Title img {
    width: 20%;
    margin-right: 2rem;
  }

  .BlogIntroduction-Left h2 {
    font-size: 3.5rem;
    width: 65%;
  }

  .BlogIntroduction-Left h4 {
    font-size: 1.2rem;
  }

  .BlogIntroduction-Left p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 58%;
  }

  .BlogIntroduction-Article-Image {
    width: 80%;
  }

  .BlogIntroduction-Right h1 {
    font-size: 4.5rem;
    width: 75%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 3rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogIntroduction-Right h3 {
    font-size: 1.5rem;
  }

  .BlogIntroduction-Right h4 {
    font-size: 1.2rem;
  }

  .BlogIntroduction-Right p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .BlogNewsletter-InputName input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .BlogNewsletter-InputName input::placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-InputName input:focus {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Input input {
    font-size: 1.8rem;
    width: 100%;
    height: 3.5rem;
  }

  .BlogNewsletter-Input input::placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Input input:focus {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Button button {
    font-size: 1.188rem;
    width: 9rem;
    height: 2.5rem;
  }
}
