body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Trona";
  src: local("Trona"), url(/static/media/font.5f4b5817.woff) format("truetype");
}

@font-face {
  font-family: "CaslonProSemiboldItalic";
  src: local("CaslonProSemiboldItalic"),
    url(/static/media/font.c526c4ed.woff)
      format("truetype");
}

@font-face {
  font-family: "CaslonRegular";
  src: local("CaslonRegular"),
    url(/static/media/font.3a71a76b.woff) format("truetype");
}

@font-face {
  font-family: "CaslonBold";
  src: local("CaslonBold"),
    url(/static/media/font.b73fe591.woff) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BaiserCircleBold";
  src: local("BaiserCircleBold"),
    url(/static/media/basiercircle-bold-webfont.4d07b869.ttf)
      format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BaiserSquareBold";
  src: local("BaiserSquareBold"),
    url(/static/media/BasierSquare-Bold.8764eb80.otf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Dida";
  src: local("Dida"), url(/static/media/Dida.93c19f1e.otf) format("truetype");
}

.peak {
  width: 50% !important;
  height: 75% !important;
  margin-left: 50%;
}

.peak-right {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.peak-right img {
  width: 55%;
}

.squeeze {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.squeeze img {
  width: 25%;
}

.squeeze-cpnt {
  width: 100%;
  height: 100vh;
}

.sticky-cpnt {
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 991px) {
  .peak {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .squeeze img {
    width: 15%;
  }
}

@media screen and (min-width: 1680px) {
  .peak-right img {
    width: 50%;
  }
}

.header-tfa {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.navbar-logo {
  width: 20%;
}

.navbar-mobile-button {
  display: none;
}

.navbar-menu {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 55%;
}

.navbar-menu li {
  padding: 0 5%;
  list-style-type: none;
}

.navbar-menu li a {
  text-decoration: none;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1.063rem;
  color: inherit;
}

.navbar-menu li::after {
  display: block;
  content: "";
  border-bottom: solid 0.125rem #fff;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}

.navbar-menu.black li::after {
  border-bottom: solid 0.125rem #000;
}

.navbar-menu li:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.navbar-list {
  display: block;
  content: "";
  border-bottom: solid 0.125rem #fff;
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.navbar-list.black {
  border-bottom: solid 0.125rem #000;
}

.navbar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1.063rem;
  width: 15%;
  height: 2.5rem;
}

.navbar-button:hover {
  cursor: pointer;
}

.navbar-blank {
  display: none;
}

.navbar-footer {
  display: none;
}

.navbar-mobile {
  display: none;
}

.navbar-mobile button {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 10%;
}

@media screen and (max-width: 966px) {
  .navbar-logo {
    padding: 0 0 0 2%;
    width: 30%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    height: 100%;
  }

  .navbar-mobile-button.active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
  }

  .navbar-mobile-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    color: #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 2.5rem;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 40%;
    margin: 0 0 10%;
    padding: 0;
    width: 100%;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li a {
    font-size: 1.5rem;
  }

  .navbar-button {
    display: none;
  }

  .navbar-blank.active {
    display: flex;
    height: 15%;
  }

  .navbar-footer.active {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 10%;
  }

  .navbar-close {
    height: 40%;
  }

  .navbar-whats {
    width: 7%;
  }

  .navbar-mobile {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
  }

  .mobile-image {
    width: 45%;
  }

  .navbar-mobile button {
    width: 30%;
    height: auto;
  }

  .button-image {
    width: 60%;
  }
}

@media screen and (max-width: 800px) and (min-width: 687px) {
  .navbar-mobile-button button {
    font-size: 1.4rem;
    width: 50%;
    height: 4rem;
  }

  .navbar-logo {
    width: 50%;
  }

  .navbar-whats {
    width: 9%;
  }

  .navbar-mobile {
    width: 20%;
  }
}

@media screen and (min-width: 687px) and (max-width: 966px) {
  .navbar-logo {
    width: 35%;
  }
}

@media screen and (max-width: 686px) {
  .navbar-logo {
    width: 45%;
  }

  .navbar-whats {
    width: 10%;
  }

  .navbar-mobile {
    width: 30%;
  }
}

@media screen and (max-width: 500px) {
  .navbar-logo {
    width: 60%;
  }

  .navbar-whats {
    width: 13%;
  }

  .navbar-mobile {
    width: 40%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .navbar-menu li a {
    font-size: 1rem;
  }

  .navbar-mobile-button {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .navbar-menu {
    justify-content: center;
  }

  .navbar-menu li a {
    font-size: 1.375rem;
  }

  .navbar-button {
    font-size: 1.25rem;
    height: 3rem;
  }
}

.Footer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.Footer-Top {
  display: flex;
  margin: 2% 0 0;
  width: 100%;
  height: 25%;
}

.Footer-Title {
  display: flex;
  padding: 0 1%;
  width: 50%;
  height: 100%;
}

.Footer-Title img {
  width: 22rem;
}

.Footer-Newsletter {
  display: flex;
  width: 50%;
}

.Newsletter-Description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.Newsletter-Description h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}

.Newsletter-Description p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 80%;
}

.Newsletter-Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.Newsletter-InputName {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.Newsletter-InputName input {
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 50%;
  height: 3rem;
}

.Newsletter-InputName input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-InputName input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-InputName input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-InputName input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Input {
  display: flex;
  width: 75%;
}

.Newsletter-Input input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 100%;
  height: 3rem;
}

.Newsletter-Input input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Input input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Input input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Input input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 75%;
  margin: 1.5rem 0 1rem;
}

.Newsletter-Button button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 8rem;
  height: 2rem;
}

.Newsletter-Button button:hover {
  cursor: pointer;
}

.Footer-Middle {
  display: flex;
  width: 100%;
  height: 30%;
}

.Middle-Left {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

.Middle-Right {
  display: flex;
  width: 50%;
  height: 100%;
}

.Middle-First {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.Middle-First p {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.Middle-First p:hover {
  cursor: pointer;
}

.Middle-Container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.Middle-Container h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.Middle-Container h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.Middle-Container p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
}

.Middle-Container a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.Middle-Container a:hover {
  cursor: pointer;
}

.Middle-ContainerTwo {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.Middle-ContainerTwo h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.Middle-ContainerTwo h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.Middle-ContainerTwo p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
}

.Middle-ContainerThree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Middle-ContainerThree img {
  width: 35%;
  margin: 13% 0 0;
}

.Middle-ContainerThree img:hover {
  cursor: pointer;
}

.Middle-ContainerThree p {
  color: #fff;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  margin: 1.5rem 0;
  width: 55%;
}

.Newsletter-Mobile {
  display: none;
}

.Footer-Copyright {
  display: none;
}

.Footer-Bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15%;
  width: 100%;
}

.WhatsappImage {
  width: 4%;
  margin: 0 1rem 1rem;
}

.WhatsappImage:hover {
  cursor: pointer;
}

.TwoforallImage {
  display: none;
}

@media screen and (max-width: 479px) {
  .Footer {
    align-items: center;
  }

  .Footer-Top {
    display: none;
  }

  .Footer-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 65%;
  }

  .Middle-Left {
    width: 100%;
    height: 50%;
  }

  .Middle-Right {
    flex-direction: row-reverse;
    width: 100%;
    height: 50%;
  }

  .Middle-First {
    padding: 15% 10% 0;
    width: 50%;
  }

  .Middle-First p {
    font-size: 0.613rem;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }

  .Middle-Container {
    padding: 10% 0 0;
    width: 50%;
  }

  .Middle-Container h1 {
    font-size: 1.5rem;
  }

  .Middle-Container h3 {
    font-size: 0.875rem;
  }

  .Middle-Container p {
    font-size: 0.813rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerTwo {
    padding: 2% 0 0;
    width: 50%;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1.5rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 0.875rem;
  }

  .Middle-ContainerTwo p {
    font-size: 0.813rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerThree {
    width: 50%;
    height: 100%;
  }

  .Middle-ContainerThree img {
    width: 30%;
    margin: 25% 0 0;
  }

  .Middle-ContainerThree p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0.5rem 0 0;
    width: 75%;
  }

  .Newsletter-Mobile {
    display: flex;
    width: 100%;
    height: 25%;
  }

  .NewsletterMobile-Desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 5%;
    width: 35%;
  }

  .NewsletterMobile-Desc h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1;
  }

  .NewsletterMobile-Desc p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .NewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
  }

  .NewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 75%;
  }

  .NewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3rem;
  }

  .NewsletterMobile-InputName input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input {
    display: flex;
    width: 75%;
  }

  .NewsletterMobile-Input input {
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 3rem;
  }

  .NewsletterMobile-Input input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .NewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 8rem;
    height: 2rem;
  }

  .Footer-Copyright {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
  }

  .Footer-Copyright p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: smaller;
  }

  .Footer-Copyright i {
    color: #fff;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .Footer-Bottom {
    justify-content: space-around;
    align-items: center;
  }

  .WhatsappImage {
    width: 14%;
  }

  .TwoforallImage {
    display: block;
    width: 60%;
  }
}

@media screen and (max-height: 570px) {
  .Middle-ContainerTwo h1 {
    font-size: 1.2rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 0.813rem;
  }

  .Middle-ContainerTwo p {
    font-size: 0.75rem;
    width: 92%;
  }

  .Middle-Container h1 {
    font-size: 1.2rem;
  }

  .Middle-Container h3 {
    font-size: 0.813rem;
  }

  .Middle-Container p {
    font-size: 0.75rem;
  }

  .Middle-First p {
    font-size: 0.625rem;
  }
}

@media screen and (min-width: 414px) and (min-height: 736px) and (max-width: 480px) and (max-height: 840px) {
  .Footer-Middle {
    height: 55%;
  }
}

@media screen and (min-width: 390px) and (min-height: 844px) and (max-width: 480px) and (max-height: 840px) {
  .Footer-Middle {
    height: 52%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .Middle-ContainerThree img {
    width: 35%;
  }

  .Middle-ContainerThree p {
    font-size: smaller;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 0.7rem;
  }

  .Middle-ContainerTwo p {
    font-size: 0.75rem;
    width: 92%;
  }

  .Middle-Container h1 {
    font-size: 1.1rem;
  }

  .Middle-Container h3 {
    font-size: 0.7rem;
  }

  .Middle-Container p {
    font-size: 0.75rem;
  }

  .Middle-First p {
    font-size: 0.53rem;
  }

  .NewsletterMobile-Desc h2 {
    font-size: 1.1rem;
  }

  .NewsletterMobile-Desc p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100%;
  }

  .NewsletterMobile-InputName input {
    font-size: 1rem;
    width: 50%;
    height: 2rem;
  }

  .NewsletterMobile-InputName input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-InputName input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-InputName input::placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-InputName input:focus {
    font-size: 1rem;
  }

  .NewsletterMobile-Input input {
    font-size: 1rem;
    width: 100%;
    height: 2rem;
  }

  .NewsletterMobile-Input input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-Input input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-Input input::placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-Input input:focus {
    font-size: 1rem;
  }

  .NewsletterMobile-Button button {
    font-size: 0.8rem;
    width: 7rem;
    height: 2rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .Footer {
    align-items: center;
  }

  .Footer-Top {
    display: none;
  }

  .Footer-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 65%;
  }

  .Middle-Left {
    width: 100%;
    height: 50%;
  }

  .Middle-Right {
    flex-direction: row-reverse;
    width: 100%;
    height: 50%;
  }

  .Middle-First {
    padding: 15% 10% 0;
    width: 50%;
  }

  .Middle-First p {
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }

  .Middle-Container {
    padding: 10% 0 0;
    width: 50%;
  }

  .Middle-Container h1 {
    font-size: 1.8rem;
  }

  .Middle-Container h3 {
    font-size: 1rem;
  }

  .Middle-Container p {
    font-size: 1.1rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerTwo {
    padding: 2% 0 0;
    width: 50%;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1.8rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 1rem;
  }

  .Middle-ContainerTwo p {
    font-size: 1.1rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerThree {
    width: 50%;
    height: 100%;
  }

  .Middle-ContainerThree img {
    width: 25%;
    margin: 15% 0 0;
  }

  .Middle-ContainerThree p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0.5rem 0 0;
    width: 50%;
  }

  .Newsletter-Mobile {
    display: flex;
    width: 100%;
    height: 35%;
  }

  .NewsletterMobile-Desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 5%;
    width: 35%;
  }

  .NewsletterMobile-Desc h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    line-height: 1;
  }

  .NewsletterMobile-Desc p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1.1;
    width: 100%;
  }

  .NewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
  }

  .NewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 75%;
  }

  .NewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3.2rem;
  }

  .NewsletterMobile-InputName input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input {
    display: flex;
    width: 75%;
  }

  .NewsletterMobile-Input input {
    color: #fff;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 3.2rem;
  }

  .NewsletterMobile-Input input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .NewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 10rem;
    height: 2.2rem;
  }

  .Footer-Copyright {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
  }

  .Footer-Copyright p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: medium;
  }

  .Footer-Copyright i {
    color: #fff;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .Footer-Bottom {
    justify-content: space-around;
    align-items: center;
  }

  .WhatsappImage {
    width: 10%;
  }

  .TwoforallImage {
    display: block;
    width: 50%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1220px) and (min-height: 625px) and (max-height: 640px) {
  .Newsletter-Description h2 {
    font-size: 1.5rem;
    line-height: 1;
  }

  .Newsletter-Description p {
    font-size: 0.75rem;
    line-height: 1rem;
    width: 100%;
  }

  .Newsletter-InputName input {
    border-bottom: 0.063rem solid #fff;
    font-size: 1rem;
    width: 50%;
    height: 2rem;
  }

  .Newsletter-InputName input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .Newsletter-InputName input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .Newsletter-InputName input::placeholder {
    font-size: 1rem;
  }

  .Newsletter-InputName input:focus {
    font-size: 1rem;
  }

  .Newsletter-Input input {
    font-size: 1rem;
    border-bottom: 0.063rem solid #fff;
    height: 2rem;
  }

  .Newsletter-Input input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .Newsletter-Input input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .Newsletter-Input input::placeholder {
    font-size: 1rem;
  }

  .Newsletter-Input input:focus {
    font-size: 1rem;
  }

  .Newsletter-Button button {
    font-size: 0.875rem;
    width: 8rem;
    height: 2rem;
  }

  .Middle-First p {
    font-size: small;
  }

  .Middle-Container h1 {
    font-size: 1.5rem;
  }

  .Middle-Container h3 {
    font-size: small;
  }

  .Middle-Container p {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1.5rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: small;
  }

  .Middle-ContainerTwo p {
    font-size: 0.75rem;
    line-height: 1rem;
    width: 92%;
  }

  .Middle-ContainerThree p {
    font-size: 0.8rem;
    margin: 1.5rem 0;
    width: 55%;
  }
}

@media screen and (min-width: 1400px) and (min-height: 640px) {
  .Footer-Title img {
    width: 22rem;
  }

  .Newsletter-Description h2 {
    font-size: 2rem;
    line-height: 1;
  }

  .Newsletter-Description p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 100%;
  }

  .Newsletter-InputName {
    width: 75%;
  }

  .Newsletter-InputName input {
    border-bottom: 0.063rem solid #fff;
    font-size: 1.2rem;
    width: 50%;
    height: 3rem;
  }

  .Newsletter-InputName input::-webkit-input-placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-InputName input:-ms-input-placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-InputName input::placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-InputName input:focus {
    font-size: 1.2rem;
  }

  .Newsletter-Input {
    width: 75%;
  }

  .Newsletter-Input input {
    font-size: 1.2rem;
    border-bottom: 0.063rem solid #fff;
    height: 3rem;
  }

  .Newsletter-Input input::-webkit-input-placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-Input input:-ms-input-placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-Input input::placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-Input input:focus {
    font-size: 1.2rem;
  }

  .Newsletter-Button {
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .Newsletter-Button button {
    font-size: 1rem;
    width: 8rem;
    height: 2rem;
  }

  .Middle-First p {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .Middle-Container h1 {
    font-size: 2rem;
  }

  .Middle-Container h3 {
    font-size: 1rem;
  }

  .Middle-Container p {
    font-size: 1.188rem;
    line-height: 1.438rem;
  }

  .Middle-ContainerTwo h1 {
    font-size: 2rem;
  }

  .Middle-ContainerTwo h3 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .Middle-ContainerTwo p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 92%;
  }

  .Middle-ContainerThree p {
    font-size: 1rem;
    margin: 1.5rem 0;
    width: 55%;
  }

  .WhatsappImage {
    width: 5%;
    margin: 0 1rem 1rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .Footer-Title img {
    width: 60%;
  }

  .Newsletter-Description h2 {
    font-size: 3rem;
    line-height: 1;
  }

  .Newsletter-Description p {
    font-size: 1.438rem;
    line-height: 1.688rem;
    width: 90%;
  }

  .Newsletter-InputName input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .Newsletter-InputName input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-InputName input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-InputName input::placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-InputName input:focus {
    font-size: 1.8rem;
  }

  .Newsletter-Input input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .Newsletter-Input input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-Input input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-Input input::placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-Input input:focus {
    font-size: 1.8rem;
  }

  .Newsletter-Button button {
    font-size: 1.188rem;
    width: 9rem;
    height: 2.5rem;
    margin: 0 0 8%;
  }

  .Middle-First p {
    font-size: 1.2rem;
    margin: 0 0 1.5rem;
  }

  .Middle-Container h1 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
  }

  .Middle-Container h3 {
    font-size: 1.2rem;
  }

  .Middle-Container p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.438rem;
    line-height: 1.688rem;
  }

  .Middle-ContainerTwo h1 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 1.2rem;
  }

  .Middle-ContainerTwo p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.438rem;
    line-height: 1.688rem;
    width: 100%;
  }

  .Middle-ContainerThree p {
    font-size: 1.5rem;
    width: 60%;
  }

  .Footer-Bottom {
    height: 10%;
  }

  .WhatsappImage {
    width: 4.5%;
    margin: 1rem;
  }
}

.BlogHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.BlogHome-Title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 8% 0 0;
  height: 30%;
  width: 100%;
}

.BlogHome-Left-Title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.BlogHome-Left-Title h1 {
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 5rem;
}

.BlogHome-Left-Title h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Title-Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
}

.BlogHome-Right-Title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.BlogHome-Right-Title h1 {
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 60%;
}

.BlogHome-Right-Title h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Right-Title h4 {
  display: none;
}

.BlogHome-Right-TitleMobile {
  display: none;
}

.BlogHome-Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60%;
}

.BlogHome-Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3% 0 0;
  width: 50%;
  height: 100%;
}

.BlogHome-Left h2 {
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 0.5rem;
}

.BlogHome-Left h2:hover {
  cursor: pointer;
}

.BlogHome-Left h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Left h4 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogHome-Left hr {
  border-top: 0.063rem solid #000;
  width: 100%;
}

.BlogHome-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2.5% 0 0;
  width: 50%;
  height: 100%;
}

.BlogHome-Right h2 {
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
  margin: 0 0 1rem;
}

.BlogHome-Right h2:hover {
  cursor: pointer;
}

.BlogHome-Right h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Right h4 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogHome-Right p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 75%;
}

.BlogHome-Right p:hover {
  cursor: pointer;
}

.BlogHome-Left-Content {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.BlogHome-Left-Content img {
  width: 20rem;
  margin: 0 0 1.5rem;
}

.BlogHome-Left-Content img:hover {
  cursor: pointer;
}

.BlogHome-Right-Content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.BlogHome-Right-Content img {
  width: 25rem;
  margin: 0 0 2rem;
}

.BlogHome-Right-Content img:hover {
  cursor: pointer;
}

@media screen and (max-width: 479px) {
  .BlogHome-Title {
    flex-direction: column;
    justify-content: center;
    padding: 10% 10% 0;
    height: 25%;
  }

  .BlogHome-Left-Title {
    width: 100%;
    height: 35%;
  }

  .BlogHome-Left-Title h1 {
    font-size: 4rem;
    margin: 0 0 1rem;
  }

  .BlogHome-Left-Title h3 {
    display: none;
  }

  .BlogHome-Title-Content {
    width: 100%;
  }

  .BlogHome-Right-Title {
    width: 100%;
  }

  .BlogHome-Right-Title h1 {
    font-size: 2rem;
    width: 100%;
  }

  .BlogHome-Right-Title h3 {
    display: none;
  }

  .BlogHome-Right-Title h4 {
    display: block;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .BlogHome-Right-TitleMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .BlogHome-Right-TitleMobile h4 {
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Top {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 10%;
    width: 100%;
    height: 65%;
  }

  .BlogHome-Left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .BlogHome-Left h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Left hr {
    width: 100%;
  }

  .BlogHome-Right {
    width: 100%;
  }

  .BlogHome-Right h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogHome-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .BlogHome-Left-Content {
    margin-top: 0;
    align-items: flex-start;
    width: 100%;
  }

  .BlogHome-Left-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content {
    margin-top: 5%;
    width: 100%;
  }

  .BlogHome-Right-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }
}

@media screen and (max-height: 670px) and (max-width: 479px) {
  .BlogHome-Left-Title h1 {
    font-size: 3rem;
  }

  .BlogHome-Right h4 {
    font-size: small;
  }

  .BlogHome-Left h4 {
    font-size: small;
  }

  .BlogHome-Right-Title h1 {
    font-size: 1.5rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .BlogHome-Left h2 {
    font-size: 1.2rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Right h2 {
    font-size: 1.67rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Right p {
    font-size: 0.813rem;
    line-height: 1;
    margin: 0 0 0.2rem;
    width: 75%;
  }

  .BlogHome-Left-Content img {
    margin: 0 0 0.5rem;
  }

  .BlogHome-Right-Content img {
    margin: 0 0 1rem;
  }
}

@media screen and (max-height: 570px) {
  .BlogHome-Left-Title h1 {
    font-size: 2.5rem;
  }

  .BlogHome-Right-Title h1 {
    font-size: 1.1rem;
    width: 80%;
  }

  .BlogHome-Left h2 {
    font-size: 1.1rem;
  }

  .BlogHome-Right h2 {
    font-size: 1.1rem;
  }

  .BlogHome-Right p {
    font-size: 0.75rem;
    width: 70%;
  }

  .BlogHome-Left-Content img {
    width: 60%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content img {
    width: 70%;
    margin: 0 0 1rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogHome-Title {
    flex-direction: column;
    justify-content: center;
    padding: 10% 10% 0;
    height: 25%;
  }

  .BlogHome-Left-Title {
    width: 100%;
    height: 35%;
  }

  .BlogHome-Left-Title h1 {
    font-size: 4rem;
    margin: 0 0 1rem;
  }

  .BlogHome-Left-Title h3 {
    display: none;
  }

  .BlogHome-Title-Content {
    width: 100%;
  }

  .BlogHome-Right-Title {
    width: 100%;
    height: 65%;
  }

  .BlogHome-Right-Title h1 {
    font-size: 2rem;
    width: 100%;
  }

  .BlogHome-Right-Title h3 {
    display: none;
  }

  .BlogHome-Right-Title h4 {
    display: block;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .BlogHome-Right-TitleMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .BlogHome-Right-TitleMobile h4 {
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Top {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 10%;
    width: 100%;
    height: 65%;
  }

  .BlogHome-Left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .BlogHome-Left h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Left hr {
    width: 100%;
  }

  .BlogHome-Right {
    width: 100%;
  }

  .BlogHome-Right h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogHome-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .BlogHome-Left-Content {
    margin-top: 0;
    align-items: flex-start;
    width: 100%;
  }

  .BlogHome-Left-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content {
    margin-top: 5%;
    width: 100%;
  }

  .BlogHome-Right-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogHome-Title {
    padding: 8% 0 0;
  }

  .BlogHome-Left {
    padding: 0;
  }

  .BlogHome-Right {
    padding: 0;
  }

  .BlogHome-Left-Title h1 {
    font-size: 7rem;
  }

  .BlogHome-Left-Title h3 {
    font-size: 1.3rem;
  }

  .BlogHome-Right-Title h1 {
    font-size: 4.5rem;
    width: 70%;
  }

  .BlogHome-Right-Title h3 {
    font-size: 1.3rem;
  }

  .BlogHome-Left h2 {
    font-size: 3rem;
  }

  .BlogHome-Left h4 {
    font-size: 1.1rem;
  }

  .BlogHome-Right h4 {
    font-size: 1.1rem;
  }

  .BlogHome-Right h2 {
    font-size: 3rem;
  }

  .BlogHome-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    margin: 0 0 0.5rem;
    width: 75%;
  }

  .BlogHome-Left-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content img {
    width: 80%;
    margin: 0 0 1rem;
  }
}

.WhatsappCpnt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.WhatsappCpnt img {
  width: 5%;
  margin: 1.5rem;
}

.WhatsappCpnt img:hover {
  cursor: pointer;
}

.WhatsappCpnt button {
  display: none;
}

@media screen and (max-width: 479px) {
  .WhatsappCpnt {
    justify-content: space-around;
    align-items: center;
  }

  .WhatsappCpnt img {
    width: 14%;
  }

  .WhatsappCpnt button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 8rem;
    height: 3rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .WhatsappCpnt img {
    width: 10%;
    margin: 1.5rem;
  }
}

.our-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cb333b;
  min-height: 100vh;
  width: 100%;
}

.our-services-row {
  align-items: flex-end;
  height: 80vh;
}

.our-services-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.our-services-col-title h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
}

.our-services-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.our-services-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.our-services-col-text h2 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.our-services-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.our-services-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 0.8rem;
}

.our-services-col-image {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.our-services-col-image img {
  width: 35%;
}

.our-services-col-image button {
  display: none;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .our-services-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .our-services-col-text p {
    font-size: 0.8rem;
  }

  .our-services-col-image img {
    width: 50%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .our-services-col-text h1 {
    font-size: 1.8rem;
  }

  .our-services-col-text p {
    font-size: 0.9rem;
  }

  .our-services-col-image img {
    width: 50%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .our-services-col-title h3 {
    font-size: medium;
  }

  .our-services-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .our-services-col-text h2 {
    font-size: large;
  }

  .our-services-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .our-services-col-image img {
    width: 50%;
  }

  .our-services-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .our-services-col-title h3 {
    font-size: large;
  }

  .our-services-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .our-services-col-text h2 {
    font-size: x-large;
  }

  .our-services-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .our-services-col-image img {
    width: 50%;
  }

  .our-services-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .our-services-col-title h3 {
    font-size: large;
  }

  .our-services-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .our-services-col-text h2 {
    font-size: x-large;
  }

  .our-services-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .our-services-col-image img {
    width: 50%;
  }

  .our-services-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .our-services-col-title h3 {
    font-size: x-large;
  }

  .our-services-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .our-services-col-text h2 {
    font-size: xx-large;
  }

  .our-services-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .our-services-col-image img {
    width: 70%;
  }

  .our-services-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .our-services-col-title h3 {
    font-size: x-large;
  }

  .our-services-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .our-services-col-text h2 {
    font-size: xx-large;
  }

  .our-services-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .our-services-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-width: 690px) {
  .our-services-col-title h3 {
    font-size: large;
  }

  .our-services-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .our-services-col-text h2 {
    font-size: x-large;
  }

  .our-services-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .our-services-col-title h3 {
    font-size: x-large;
  }

  .our-services-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .our-services-col-text h2 {
    font-size: xx-large;
  }

  .our-services-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .our-services-col-title h3 {
    font-size: x-large;
  }

  .our-services-row {
    align-items: center;
    align-content: space-evenly;
  }

  .our-services-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .our-services-col-text h2 {
    font-size: xx-large;
  }

  .our-services-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .our-services-col-text a {
    display: none;
  }

  .our-services-col-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 50%;
    height: 3rem;
  }

  .our-services-col-image button:hover {
    cursor: pointer;
  }

  .our-services-col-image img {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .our-services-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .our-services-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .our-services-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .our-services-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.Introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Introduction-Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 85%;
  width: 100%;
}

.Introduction-Left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.Introduction-Left h1 {
  color: #fff;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  width: 55%;
  margin: 0;
  line-height: 1;
}

.Introduction-Right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.Introduction-Right img {
  display: none;
}

.Introduction-Right p {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.75rem;
  margin: 0;
}

/* Target portrait smartphones */
@media screen and (max-width: 479px) {
  .Introduction-Top {
    flex-direction: column;
    justify-content: flex-start;
  }

  .Introduction-Left {
    flex-direction: column;
    justify-content: flex-end;
    height: 30%;
    width: 100%;
  }

  .Introduction-Left h1 {
    font-size: 2.5rem;
    width: 60%;
  }

  .Introduction-Right {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 70%;
    width: 100%;
  }

  .Introduction-Right img {
    display: block;
    width: 60%;
  }

  .Introduction-Right p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 354px) and (max-height: 626px) {
  .Introduction-Left h1 {
    font-size: 2.5rem;
  }

  .Introduction-Right p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .Introduction-Left h1 {
    font-size: 2.4rem;
    width: 55%;
  }

  .Introduction-Right p {
    font-size: 3.5rem;
  }

  .Introduction-Right img {
    width: 50%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .Introduction-Top {
    flex-direction: column;
    justify-content: flex-start;
  }

  .Introduction-Left {
    flex-direction: column;
    justify-content: flex-end;
    height: 30%;
    width: 100%;
  }

  .Introduction-Left h1 {
    font-size: 5rem;
    width: 60%;
  }

  .Introduction-Right {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 70%;
    width: 100%;
  }

  .Introduction-Right img {
    display: block;
    width: 50%;
  }

  .Introduction-Right p {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .Introduction-Left h1 {
    font-size: 7rem;
  }

  .Introduction-Right p {
    font-size: 6.25rem;
  }
}

.find-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0 0;
  height: 100%;
  width: 100%;
}

.find-out-row {
  align-items: flex-end;
  height: 85%;
}

.find-out-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 0.5rem;
  width: 80%;
}

.find-out-col-text h3 {
  display: none;
}

.find-out-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  margin: 0;
  width: 85%;
}

.find-out-col-text a {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  text-decoration: underline;
}

.find-out-start {
  display: none;
}

.find-out-col-img {
  text-align: center;
}

.find-out-col-img img {
  width: 40%;
}

.find-out-col-img p {
  color: #000;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .find-out-col-text h1 {
    font-size: 1.6rem;
  }

  .find-out-col-text p {
    font-size: 0.8rem;
  }

  .find-out-col-text a {
    font-size: 0.75rem;
  }

  .find-out-col-img img {
    width: 45%;
  }

  .find-out-col-img p {
    font-size: 0.9rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .find-out-col-text h1 {
    font-size: 1.8rem;
  }

  .find-out-col-text p {
    font-size: 0.9rem;
  }

  .find-out-col-text a {
    font-size: 0.85rem;
  }

  .find-out-col-img img {
    width: 50%;
  }

  .find-out-col-img p {
    font-size: 1rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .find-out-col-text h1 {
    font-size: 2rem;
    width: 90%;
  }

  .find-out-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .find-out-col-text a {
    font-size: 0.95rem;
  }

  .find-out-col-img img {
    width: 55%;
  }

  .find-out-col-img p {
    font-size: 1.1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .find-out-col-text h1 {
    font-size: 2.4rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.15rem;
  }

  .find-out-col-img img {
    width: 56%;
  }

  .find-out-col-img p {
    font-size: 1.3rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .find-out-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.35rem;
  }

  .find-out-col-img img {
    width: 55%;
  }

  .find-out-col-img p {
    font-size: 1.5rem;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .find-out-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.45rem;
  }

  .find-out-col-img img {
    width: 60%;
  }

  .find-out-col-img p {
    font-size: 1.6rem;
  }
}

@media screen and (min-height: 1072px) {
  .find-out-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.75rem;
  }

  .find-out-col-img img {
    width: 60%;
  }

  .find-out-col-img p {
    font-size: 1.9rem;
  }
}

@media screen and (min-width: 500px) {
  .find-out-col-img img {
    width: 38%;
  }
}

@media screen and (min-width: 690px) {
  .find-out-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.83rem;
  }

  .find-out-col-img img {
    width: 30%;
  }

  .find-out-col-img p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 921px) {
  .find-out-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .find-out-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }

  .find-out-col-text a {
    font-size: 1.83rem;
  }

  .find-out-col-img img {
    width: 30%;
  }

  .find-out-col-img p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .find-out-row {
    align-items: center;
  }

  .find-out-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 85%;
  }

  .find-out-col-text h3 {
    display: block;
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
  }

  .find-out-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 85%;
  }

  .find-out-start {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
  }

  .find-out-start img {
    width: 20%;
  }

  .find-out-start button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3rem;
  }

  .find-out-start button:hover {
    cursor: pointer;
  }

  .find-out-col-text a {
    display: none;
  }

  .find-out-col-img img {
    display: none;
  }

  .find-out-col-img p {
    font-size: 1.5rem;
  }

  .find-out-col-img p {
    margin: 100% 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .find-out-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 1rem;
    width: 75%;
  }

  .find-out-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 75%;
  }
}

@media screen and (min-width: 1680px) {
  .find-out-col-text h1 {
    font-size: 6rem;
    margin: 0 0 1rem;
    width: 70%;
  }

  .find-out-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 70%;
  }

  .find-out-start button {
    width: 40%;
  }

  .find-out-col-img p {
    font-size: 2rem;
  }
}

.twoforall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #cb333b;
  min-height: 100vh;
  width: 100%;
}

.twoforall-row {
  align-items: flex-end;
  height: 80vh;
}

.twoforall-col-title h1 {
  color: #fff;
  font-size: 2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
  width: 80%;
}

.twoforall-col-text h1 {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  margin: 0 0 3%;
  width: 100%;
}

.twoforall-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 100%;
}

.twoforall-col-text a {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  text-decoration: underline;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .twoforall-col-title h1 {
    font-size: 2.1rem;
  }

  .twoforall-col-text h1 {
    font-size: 1.6rem;
  }

  .twoforall-col-text p {
    font-size: 0.8rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .twoforall-col-title h1 {
    font-size: 3rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 1.8rem;
  }

  .twoforall-col-text p {
    font-size: 0.9rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .twoforall-col-title h1 {
    font-size: 3.2rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2rem;
  }

  .twoforall-col-text a {
    font-size: small;
  }

  .twoforall-col-text p {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .twoforall-col-title h1 {
    font-size: 3.2rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2.4rem;
  }

  .twoforall-col-text p {
    font-size: 1.1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .twoforall-col-title h1 {
    font-size: 3.4rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: large;
  }

  .twoforall-col-text p {
    font-size: 1.4rem;
    line-height: 1;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .twoforall-col-title h1 {
    font-size: 3.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }
}

@media screen and (min-height: 1072px) {
  .twoforall-col-title h1 {
    font-size: 3.8rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .twoforall-col-text a {
    font-size: xx-large;
  }

  .twoforall-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }
}

@media screen and (min-width: 690px) {
  .twoforall-col-title h1 {
    font-size: 5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .twoforall-col-text a {
    font-size: large;
  }

  .twoforall-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .twoforall-col-title h1 {
    font-size: 5.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3.6rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
  }
}

@media screen and (min-width: 992px) {
  .twoforall-row {
    align-items: flex-start;
    padding: 8% 0 0;
  }

  .twoforall-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
  }
}

@media screen and (min-width: 1200px) {
  .twoforall-col-title h1 {
    font-size: 6.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3.5rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}

@media screen and (min-width: 1680px) {
  .twoforall-col-title h1 {
    font-size: 7.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 5rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 80%;
  }
}

.OurMethod {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5% 0 0;
  min-height: 100vh;
  width: 100%;
}

.OurMethod-Title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 15vh;
}

.OurMethod-Title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.OurMethod-Content {
  display: flex;
  width: 100%;
  height: 60vh;
}

.OurMethod-Parallax {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.OurMethod-Parallax:hover {
  cursor: pointer;
}

.OurMethod-Parallax-Arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5%;
}

.OurMethod-Parallax-Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.OurMethod-Parallax-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.OurMethod-Parallax-Middle {
  display: none;
}

.OurMethod-Parallax-Arrow p {
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.75rem;
  margin: 0;
}

.OurMethod-Parallax h1 {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.125rem;
  -webkit-text-stroke-color: black;
  font-family: "Dida", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  line-height: 1;
  margin: 3rem 0 0;
}

.OurMethod-Parallax h2 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 4rem;
}

.OurMethod-Paragraph {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  text-align: center;
  width: 54%;
  margin: 0;
}

.methods-parallax {
  height: 60% !important;
  overflow: hidden !important;
}

@media screen and (max-width: 479px) {
  .OurMethod-Title {
    height: 10vh;
  }

  .OurMethod-Title h3 {
    font-size: small;
  }

  .OurMethod-Content {
    flex-direction: column;
    height: 80vh;
  }

  .OurMethod-Parallax {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .OurMethod-Parallax-Left {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Right {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Arrow {
    display: none;
  }

  .OurMethod-Parallax-Middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
  }

  .OurMethod-Parallax-Middle p {
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }

  .OurMethod-Parallax h1 {
    font-size: 3rem;
    margin: 0;
  }

  .OurMethod-Parallax h2 {
    font-size: 2.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 80%;
  }

  .methods-parallax {
    height: 80% !important;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .OurMethod-Parallax h1 {
    font-size: 2.5rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .OurMethod-Parallax h1 {
    font-size: 2.2rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.8rem;
    margin: 0 0 0.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.938rem;
    line-height: 1.188rem;
    width: 80%;
  }
}

@media screen and (max-width: 375px) and (min-width: 361px) and (max-height: 612px) {
  .OurMethod-Parallax h1 {
    font-size: 2rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.9rem;
    line-height: 1.1;
    width: 80%;
  }
}

@media screen and (max-height: 570px) {
  .OurMethod-Parallax h1 {
    font-size: 2rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.625rem;
    width: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .OurMethod-Parallax-Middle p {
    font-size: 1.8rem;
  }

  .OurMethod-Parallax h1 {
    font-size: 1.8rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.2rem;
    margin: 0 0 0.3rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.625rem;
    line-height: 1.2;
    width: 80%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .OurMethod-Title {
    height: 15%;
  }

  .OurMethod-Title h3 {
    font-size: x-large;
  }

  .OurMethod-Content {
    flex-direction: column;
    height: 80vh;
  }

  .OurMethod-Parallax {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .OurMethod-Parallax-Left {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Right {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Arrow {
    display: none;
  }

  .OurMethod-Parallax-Middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
  }

  .OurMethod-Parallax-Middle p {
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 5rem;
    margin: 0;
  }

  .OurMethod-Parallax h1 {
    font-size: 5rem;
    margin: 0;
  }

  .OurMethod-Parallax h2 {
    font-size: 3rem;
  }

  .OurMethod-Paragraph {
    font-size: 1.2rem;
    line-height: 1;
    width: 80%;
  }

  .methods-parallax {
    height: 80% !important;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .OurMethod-Title h3 {
    font-size: 1.5rem;
  }

  .OurMethod-Parallax-Arrow p {
    font-size: 6.25rem;
  }

  .OurMethod-Parallax h1 {
    font-size: 7rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 5.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 54%;
  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 2% 0 0;
  min-height: 100vh;
  width: 100%;
}

.about-row {
  align-items: flex-end;
  height: 80vh;
}

.about-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 0.5rem;
  width: 80%;
}

.about-col-text h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 0.2rem;
}

.about-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  margin: 0;
  width: 85%;
}

.about-col-img {
  text-align: center;
}

.about-img {
  width: 38%;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .about-col-text h1 {
    font-size: 1.6rem;
  }

  .about-col-text p {
    font-size: 0.8rem;
  }

  .about-img {
    width: 45%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .about-col-text h1 {
    font-size: 1.8rem;
  }

  .about-col-text p {
    font-size: 0.9rem;
  }

  .about-img {
    width: 50%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .about-col-text h1 {
    font-size: 2rem;
    width: 90%;
  }

  .about-col-text h2 {
    font-size: medium;
  }

  .about-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .about-img {
    width: 55%;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .about-col-text h1 {
    font-size: 2.4rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: large;
  }

  .about-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .about-img {
    width: 56%;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .about-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .about-img {
    width: 55%;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .about-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .about-img {
    width: 60%;
  }
}

@media screen and (min-height: 1072px) {
  .about-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: xx-large;
  }

  .about-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .about-img {
    width: 60%;
  }
}

@media screen and (min-width: 500px) {
  .about-img {
    width: 38%;
  }
}

@media screen and (min-width: 690px) {
  .about-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }

  .about-img {
    width: 30%;
  }
}

@media screen and (min-width: 921px) {
  .about-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }

  .about-img {
    width: 30%;
  }
}

@media screen and (min-width: 992px) {
  .about-row {
    align-items: center;
  }

  .about-col-text {
    margin: 0;
  }

  .about-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 85%;
  }

  .about-col-text h2 {
    font-size: large;
  }

  .about-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 85%;
  }

  .about-img {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .about-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 1rem;
    width: 75%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 75%;
  }
}

@media screen and (min-width: 1680px) {
  .about-col-text h1 {
    font-size: 6rem;
    margin: 0 0 1rem;
    width: 70%;
  }

  .about-col-text h2 {
    font-size: xx-large;
  }

  .about-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 70%;
  }
}

.investment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #edb8bb;
  min-height: 100vh;
  width: 100%;
}

.investment-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.investment h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  text-align: center;
  width: 85%;
}

.investment h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: x-small;
}

.investment-img {
  position: absolute;
  width: 30%;
  will-change: transform;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .investment h1 {
    font-size: 1.8rem;
    line-height: 1;
    width: 95%;
  }

  .investment h2 {
    font-size: smaller;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .investment h1 {
    font-size: 2rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: smaller;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .investment h1 {
    font-size: 2.2rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: small;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .investment h1 {
    font-size: 2.4rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: small;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .investment h1 {
    font-size: 2.6rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: medium;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .investment h1 {
    font-size: 3rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: medium;
  }
}

@media screen and (min-height: 1072px) {
  .investment h1 {
    font-size: 3.4rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: large;
  }
}

@media screen and (min-width: 500px) {
  .investment h2 {
    font-size: medium;
  }
}

@media screen and (min-width: 800px) {
  .investment h1 {
    font-size: 3.5rem;
    width: 60%;
  }

  .investment h2 {
    font-size: large;
  }
}

@media screen and (min-width: 1024px) {
  .investment h1 {
    font-size: 4rem;
    width: 70%;
  }

  .investment h2 {
    font-size: x-large;
  }

  .investment-img {
    width: 20%;
  }
}

@media screen and (min-width: 1200px) {
  .investment h1 {
    font-size: 5rem;
    width: 80%;
  }

  .investment h2 {
    font-size: x-large;
  }
}

@media screen and (min-width: 1680px) {
  .investment h1 {
    font-size: 5.5rem;
    width: 80%;
  }
}

.art {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6eb61;
  min-height: 100vh;
  width: 100%;
}

.art-row {
  align-items: flex-end;
  height: 85%;
}

.art-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.art-col-title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 10%;
}

.art-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.art-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.art-col-text h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.art-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.art-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #000;
  text-decoration: underline;
  font-size: 0.8rem;
}

.art-col-image {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.art-col-image img {
  width: 40%;
}

.art-col-image button {
  display: none;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .art-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .art-col-text p {
    font-size: 0.8rem;
  }

  .art-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .art-col-text h1 {
    font-size: 1.8rem;
  }

  .art-col-text p {
    font-size: 0.9rem;
  }

  .art-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .art-col-title h3 {
    font-size: medium;
  }

  .art-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .art-col-text h2 {
    font-size: large;
  }

  .art-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .art-col-image img {
    width: 50%;
  }

  .art-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .art {
    padding: 20% 0 0;
  }

  .art-col-title h3 {
    font-size: large;
  }

  .art-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .art-col-text h2 {
    font-size: x-large;
  }

  .art-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .art-col-image img {
    width: 50%;
    margin: 10% 0;
  }

  .art-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .art-col-title h3 {
    font-size: large;
  }

  .art-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .art-col-text h2 {
    font-size: x-large;
  }

  .art-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .art-col-image img {
    width: 60%;
  }

  .art-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .art-col-title h3 {
    font-size: x-large;
  }

  .art-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .art-col-image img {
    width: 70%;
  }

  .art-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .art-col-title h3 {
    font-size: x-large;
  }

  .art-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .art-col-text a {
    font-size: x-large;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) and (max-height: 700px) {
  .art {
    padding: 10% 10% 0;
  }
}

@media screen and (min-width: 690px) {
  .art-col-title h3 {
    font-size: large;
  }

  .art-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .art-col-text h2 {
    font-size: x-large;
  }

  .art-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .art-col-title h3 {
    font-size: x-large;
  }

  .art-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .art {
    padding: 10% 0 0;
  }

  .art-col-title h3 {
    font-size: x-large;
    margin: 0 0 5%;
  }

  .art-row {
    align-items: center;
    align-content: space-evenly;
  }

  .art-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .art-col-text a {
    display: none;
  }

  .art-col-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 50%;
    height: 3rem;
  }

  .art-col-image button:hover {
    cursor: pointer;
  }

  .art-col-image img {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .art-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .art-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .art-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .art-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.crypto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00c65e;
  min-height: 100vh;
  width: 100%;
}

.crypto-row {
  align-items: flex-end;
  height: 85%;
}

.crypto-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.crypto-col-title h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 10%;
}

.crypto-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.crypto-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.crypto-col-text h2 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.crypto-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.crypto-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 0.8rem;
}

.crypto-col-image {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.crypto-col-image img {
  width: 35%;
}

.crypto-col-image button {
  display: none;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .crypto-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .crypto-col-text p {
    font-size: 0.8rem;
  }

  .crypto-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .crypto-col-text h1 {
    font-size: 1.8rem;
  }

  .crypto-col-text p {
    font-size: 0.9rem;
  }

  .crypto-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .crypto-col-title h3 {
    font-size: medium;
  }

  .crypto-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .crypto-col-text h2 {
    font-size: large;
  }

  .crypto-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .crypto-col-image img {
    width: 40%;
  }

  .crypto-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .crypto {
    padding: 20% 0 0;
  }

  .crypto-col-title h3 {
    font-size: large;
  }

  .crypto-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .crypto-col-text h2 {
    font-size: x-large;
  }

  .crypto-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .crypto-col-image img {
    width: 50%;
    margin: 10% 0;
  }

  .crypto-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .crypto-col-title h3 {
    font-size: large;
  }

  .crypto-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .crypto-col-text h2 {
    font-size: x-large;
  }

  .crypto-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .crypto-col-image img {
    width: 40%;
  }

  .crypto-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .crypto-col-title h3 {
    font-size: x-large;
  }

  .crypto-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .crypto-col-text h2 {
    font-size: xx-large;
  }

  .crypto-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .crypto-col-image img {
    width: 60%;
  }

  .crypto-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .crypto-col-title h3 {
    font-size: x-large;
  }

  .crypto-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .crypto-col-text h2 {
    font-size: xx-large;
  }

  .crypto-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .crypto-col-text a {
    font-size: x-large;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) and (max-height: 700px) {
  .crypto {
    padding: 10% 10% 0;
  }
}

@media screen and (min-width: 690px) {
  .crypto-col-title h3 {
    font-size: large;
  }

  .crypto-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .crypto-col-text h2 {
    font-size: x-large;
  }

  .crypto-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .crypto-col-title h3 {
    font-size: x-large;
  }

  .crypto-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .crypto-col-text h2 {
    font-size: xx-large;
  }

  .crypto-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .crypto {
    padding: 10% 0 0;
  }

  .crypto-col-title h3 {
    font-size: x-large;
    margin: 0 0 5%;
  }

  .crypto-row {
    align-items: center;
    align-content: space-evenly;
  }

  .crypto-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .crypto-col-text h2 {
    font-size: xx-large;
  }

  .crypto-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .crypto-col-text a {
    display: none;
  }

  .crypto-col-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 50%;
    height: 3rem;
  }

  .crypto-col-image button:hover {
    cursor: pointer;
  }

  .crypto-col-image img {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .crypto-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .crypto-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .crypto-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .crypto-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.estate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0857c3;
  min-height: 100vh;
  width: 100%;
}

.estate-row {
  align-items: flex-end;
  height: 85%;
}

.estate-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.estate-col-title h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 10%;
}

.estate-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.estate-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.estate-col-text h2 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.estate-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.estate-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 0.8rem;
}

.estate-col-image {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.estate-col-image img {
  width: 35%;
}

.estate-col-image button {
  display: none;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .estate-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .estate-col-text p {
    font-size: 0.8rem;
  }

  .estate-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .estate-col-text h1 {
    font-size: 1.8rem;
  }

  .estate-col-text p {
    font-size: 0.9rem;
  }

  .estate-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .estate-col-title h3 {
    font-size: medium;
  }

  .estate-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .estate-col-text h2 {
    font-size: large;
  }

  .estate-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .estate-col-image img {
    width: 40%;
  }

  .estate-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .estate {
    padding: 20% 0 0;
  }

  .estate-col-title h3 {
    font-size: large;
  }

  .estate-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .estate-col-text h2 {
    font-size: x-large;
  }

  .estate-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .estate-col-image img {
    width: 50%;
    margin: 10% 0;
  }

  .estate-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .estate-col-title h3 {
    font-size: large;
  }

  .estate-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .estate-col-text h2 {
    font-size: x-large;
  }

  .estate-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .estate-col-image img {
    width: 40%;
  }

  .estate-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .estate-col-title h3 {
    font-size: x-large;
  }

  .estate-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .estate-col-text h2 {
    font-size: xx-large;
  }

  .estate-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .estate-col-image img {
    width: 60%;
  }

  .estate-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .estate-col-title h3 {
    font-size: x-large;
  }

  .estate-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .estate-col-text h2 {
    font-size: xx-large;
  }

  .estate-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .estate-col-text a {
    font-size: x-large;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) and (max-height: 700px) {
  .estate {
    padding: 10% 10% 0;
  }
}

@media screen and (min-width: 690px) {
  .estate-col-title h3 {
    font-size: large;
  }

  .estate-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .estate-col-text h2 {
    font-size: x-large;
  }

  .estate-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .estate-col-title h3 {
    font-size: x-large;
  }

  .estate-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .estate-col-text h2 {
    font-size: xx-large;
  }

  .estate-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .estate {
    padding: 10% 0 0;
  }

  .estate-col-title h3 {
    font-size: x-large;
    margin: 0 0 5%;
  }

  .estate-row {
    align-items: center;
    align-content: space-evenly;
  }

  .estate-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .estate-col-text h2 {
    font-size: xx-large;
  }

  .estate-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .estate-col-text a {
    display: none;
  }

  .estate-col-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 50%;
    height: 3rem;
  }

  .estate-col-image button:hover {
    cursor: pointer;
  }

  .estate-col-image img {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .estate-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .estate-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .estate-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .estate-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.trading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
}

.trading-row {
  align-items: flex-end;
  height: 85%;
}

.trading-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.trading-col-title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 10%;
}

.trading-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.trading-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.trading-col-text h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.trading-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.trading-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #000;
  text-decoration: underline;
  font-size: 0.8rem;
}

.trading-col-image {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.trading-col-image img {
  width: 40%;
}

.trading-col-image button {
  display: none;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .trading-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .trading-col-text p {
    font-size: 0.8rem;
  }

  .trading-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .trading-col-text h1 {
    font-size: 1.8rem;
  }

  .trading-col-text p {
    font-size: 0.9rem;
  }

  .trading-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .trading-col-title h3 {
    font-size: medium;
  }

  .trading-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .trading-col-text h2 {
    font-size: large;
  }

  .trading-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .trading-col-image img {
    width: 50%;
  }

  .trading-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .trading {
    padding: 20% 0 0;
  }

  .trading-col-title h3 {
    font-size: large;
  }

  .trading-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .trading-col-text h2 {
    font-size: x-large;
  }

  .trading-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .trading-col-image img {
    width: 50%;
    margin: 10% 0;
  }

  .trading-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .trading-col-title h3 {
    font-size: large;
  }

  .trading-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .trading-col-text h2 {
    font-size: x-large;
  }

  .trading-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .trading-col-image img {
    width: 60%;
  }

  .trading-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .trading-col-title h3 {
    font-size: x-large;
  }

  .trading-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .trading-col-text h2 {
    font-size: xx-large;
  }

  .trading-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .trading-col-image img {
    width: 70%;
  }

  .trading-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .trading-col-title h3 {
    font-size: x-large;
  }

  .trading-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .trading-col-text h2 {
    font-size: xx-large;
  }

  .trading-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .trading-col-text a {
    font-size: x-large;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) and (max-height: 700px) {
  .trading {
    padding: 10% 10% 0;
  }
}

@media screen and (min-width: 690px) {
  .trading-col-title h3 {
    font-size: large;
  }

  .trading-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .trading-col-text h2 {
    font-size: x-large;
  }

  .trading-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .trading-col-title h3 {
    font-size: x-large;
  }

  .trading-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .trading-col-text h2 {
    font-size: xx-large;
  }

  .trading-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .trading {
    padding: 10% 0 0;
  }

  .trading-col-title h3 {
    font-size: x-large;
    margin: 0 0 5%;
  }

  .trading-row {
    align-items: center;
    align-content: space-evenly;
  }

  .trading-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .trading-col-text h2 {
    font-size: xx-large;
  }

  .trading-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .trading-col-text a {
    display: none;
  }

  .trading-col-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 50%;
    height: 3rem;
  }

  .trading-col-image button:hover {
    cursor: pointer;
  }

  .trading-col-image img {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .trading-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .trading-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .trading-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .trading-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.wealth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cb333b;
  padding: 40% 0 0;
  min-height: 100vh;
  width: 100%;
}

.wealth-row {
  align-items: flex-end;
  height: 90%;
}

.wealth-col-text {
  display: grid !important;
  justify-items: center;
  align-items: center;
  margin: 0 0 5%;
}

.wealth-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}

.wealth-col-text h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
}

.wealth-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  text-align: center;
  width: 100%;
}

.wealth-col-arrow {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.wealth-col-arrow p {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.5rem;
  margin: 0;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .wealth-col-text h1 {
    font-size: 1.6rem;
  }

  .wealth-col-text p {
    font-size: 0.8rem;
  }

  .wealth-col-arrow p {
    font-size: 4rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .wealth-col-text h1 {
    font-size: 1.8rem;
  }

  .wealth-col-text p {
    font-size: 0.9rem;
  }

  .wealth-col-arrow p {
    font-size: 4.5rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .wealth-col-text h1 {
    font-size: 2rem;
    width: 90%;
  }

  .wealth-col-text h4 {
    font-size: medium;
  }

  .wealth-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .wealth-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .wealth-col-text h1 {
    font-size: 2.4rem;
    width: 95%;
  }

  .wealth-col-text h4 {
    font-size: large;
  }

  .wealth-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .wealth-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .wealth-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .wealth-col-text h4 {
    font-size: x-large;
  }

  .wealth-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .wealth-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .wealth-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .wealth-col-text h4 {
    font-size: x-large;
  }

  .wealth-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .wealth-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 1072px) {
  .wealth-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .wealth-col-text h4 {
    font-size: xx-large;
  }

  .wealth-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .wealth-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (max-width: 920px) and (min-width: 550px) and (max-height: 700px) {
  .wealth {
    padding: 10%;
  }
}

@media screen and (min-width: 690px) {
  .wealth-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .wealth-col-text h4 {
    font-size: x-large;
  }

  .wealth-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }

  .wealth-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-width: 921px) {
  .wealth {
    padding: 10% 0 0;
  }

  .wealth-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .wealth-col-text h4 {
    font-size: x-large;
  }

  .wealth-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }

  .wealth-col-arrow p {
    font-size: 6rem;
  }
}

@media screen and (min-width: 992px) {
  .wealth {
    align-items: flex-end;
  }

  .wealth-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 85%;
  }

  .wealth-col-text h4 {
    font-size: large;
  }

  .wealth-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .wealth-col-text {
    margin: 0 0 2%;
  }

  .wealth-col-arrow p {
    font-size: 6.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .wealth-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 4%;
    width: 75%;
  }

  .wealth-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }

  .wealth-col-arrow p {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1680px) {
  .wealth {
    padding: 8% 0 0;
  }

  .wealth-col-text h1 {
    font-size: 6rem;
    margin: 0 0 3%;
    width: 70%;
  }

  .wealth-col-text h4 {
    font-size: x-large;
  }

  .wealth-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.Stepper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.Stepper-Top {
  display: flex;
  color: #fff;
  height: 85%;
  width: 100%;
}

@media screen and (max-width: 479px) {
  .Stepper {
    padding: 40% 0 0;
  }
}

.Step {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Step-Top {
  display: flex;
  flex-direction: row;
  margin: 0 0 10%;
  width: 100%;
  height: 85%;
}

.Step-Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  height: 100%;
}

.Step-Left h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 65%;
  text-align: end;
}

.Step-Left p {
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 50%;
  text-align: end;
}

.Step-Right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.Step-Radio {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  height: 40%;
  width: 75%;
}

.Step-RadioButton {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #000 !important;
  border: 0.125rem solid #fff !important;
  color: #fff !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-right: 1rem !important;
  line-height: 1rem !important;
  width: 12rem !important;
  height: 3rem !important;
}

.Step-RadioButton:hover {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  border: 0.125rem solid #000 !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-right: 1rem !important;
  line-height: 1rem !important;
  width: 12rem !important;
  height: 3rem !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  border: 0.125rem solid #000 !important;
  color: #000 !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-right: 1rem !important;
  line-height: 1rem !important;
  width: 12rem !important;
  height: 3rem !important;
}

.ant-radio-button-wrapper:hover {
  color: #000 !important;
}

.Step-Bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.Step-Arrows {
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
}

.Step-Arrows:hover {
  cursor: pointer;
}

.Progress {
  background-color: #fff;
  margin: 0 3rem;
  padding: 0.125rem;
  width: 60%;
  border: 0.188rem solid #fff;
  height: 1.875rem;
}

.Progress .Progress-Bar {
  height: 100%;
  background-color: #000;
  -webkit-animation: fill-bar 3s infinite;
          animation: fill-bar 3s infinite;
}

.StepFinal-Top {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 85%;
}

.StepFinal-Bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.StepFinal-Left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80%;
  height: 100%;
}

.StepFinal-Left button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 5% 0;
  font-size: 1rem;
  width: 8rem;
  height: 2.8rem;
}

.StepFinal-Left button:hover {
  cursor: pointer;
}

.StepFinal-Left-Title {
  display: flex;
  justify-content: center;
  width: 100%;
}

.StepFinal-Left-Title h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  text-align: start;
  margin: 0 0 1rem;
}

.StepFinal-MobileHand {
  display: none;
}

.StepFinal-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
}

.StepFinal-Right p {
  color: #fff;
  font-size: 1.1rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 70%;
  text-align: start;
  margin-bottom: 75%;
}

.StepFinal-Right img {
  width: 45%;
}

.StepFinal-Hand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  margin-bottom: 1rem;
}

.StepFinal-Hand p {
  color: #fff;
  font-size: 6rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  margin: 0 1rem;
  padding-bottom: 4rem;
  width: 10%;
}

.StepFinal-InputName {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.StepFinal-Input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.StepFinal-InputName,
.StepFinal-Input p {
  color: #fff;
  font-size: 2.2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
}

.StepFinal-InputName input {
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 22.8%;
  height: 3rem;
  margin: 0 1rem 0;
}

.StepFinal-InputName input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-InputName input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-InputName input::placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-InputName input:focus {
  outline: none;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-Input input {
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 60%;
  height: 3rem;
  margin: 0 1rem 0;
}

.StepFinal-Input input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-Input input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-Input input::placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-Input input:focus {
  outline: none;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-InputMobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .Step-Top {
    flex-direction: column;
    width: 100%;
    height: 80%;
  }

  .Step-Left {
    justify-content: flex-end;
    padding: 5%;
    width: 100%;
    height: 50%;
  }

  .Step-Left h1 {
    font-size: 3rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 1rem;
    width: 100%;
    text-align: end;
  }

  .Step-Right {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 50%;
  }

  .Step-Radio {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }

  .Step-RadioButton {
    font-size: 0.75rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 3rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 0.75rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 3rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 0.75rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 3rem !important;
  }

  .ant-radio-button-wrapper:hover {
    color: #000 !important;
  }

  .Step-Bottom {
    height: 20%;
  }

  .Step-Arrows {
    font-size: 2rem;
  }

  .Progress {
    margin: 0 1.5rem;
  }

  .StepFinal-Top {
    flex-direction: column;
  }

  .StepFinal-Left {
    flex-direction: column;
    padding: 0 5%;
    width: 100%;
    height: 100%;
  }

  .StepFinal-Left button {
    font-size: 0.813rem;
    width: 30%;
    height: 2.5rem;
  }

  .StepFinal-Left-Title {
    flex-direction: column;
    width: 100%;
  }

  .StepFinal-Left-Title h1 {
    font-size: 3rem;
  }

  .StepFinal-MobileHand {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .StepFinal-MobileHand img {
    width: 20%;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 1rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-Right {
    display: none;
  }

  .StepFinal-InputName {
    display: none;
  }

  .StepFinal-Input {
    width: 100%;
  }

  .StepFinal-InputMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .StepFinal-Input p {
    color: #fff;
    font-size: 1.8rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile p {
    color: #fff;
    font-size: 1.8rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 60%;
    height: 3rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-Input input {
    width: 60%;
    height: 3rem;
    margin: 0 1rem 0;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .Step-Left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 0.75rem;
    width: 100%;
    text-align: end;
  }

  .StepFinal-Left-Title h1 {
    font-size: 1.5rem;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 0.75rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-MobileHand img {
    width: 15%;
  }
}

@media screen and (height: 719px) and (width: 414px) {
  .StepFinal-Left-Title h1 {
    font-size: 2.5rem;
  }

  .StepFinal-MobileHand p {
    font-size: 1rem;
  }

  .StepFinal-MobileHand img {
    width: 18%;
  }

  .StepFinal-InputMobile p {
    font-size: 1.6rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1.2rem;
    width: 65%;
    height: 2.5rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::-webkit-input-placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-InputMobile input:-ms-input-placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-InputMobile input::placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-InputMobile input:focus {
    font-size: 1.2rem;
  }

  .StepFinal-Input p {
    font-size: 1.6rem;
  }

  .StepFinal-Input input {
    font-size: 1.2rem;
    width: 65%;
    height: 2.5rem;
    margin: 0 1rem 0;
  }

  .StepFinal-Input input::-webkit-input-placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-Input input:-ms-input-placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-Input input::placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-Input input:focus {
    font-size: 1.2rem;
  }

  .StepFinal-Left button {
    font-size: 0.813rem;
    margin: 2% 0 0;
    width: 30%;
    height: 2.5rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .Step-Left h1 {
    font-size: 1.8rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100%;
    text-align: end;
  }

  .Step-RadioButton {
    font-size: 0.55rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 2.5rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 0.55rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 2.5rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 0.55rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 2.5rem !important;
  }

  .Step-Arrows {
    font-size: 1.5rem;
  }

  .Progress {
    width: 60%;
    height: 1.75rem;
  }

  .StepFinal-Left-Title h1 {
    font-size: 1.5rem;
  }

  .StepFinal-InputMobile p {
    font-size: 1.2rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1rem;
    width: 60%;
    height: 2rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .StepFinal-InputMobile input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .StepFinal-InputMobile input::placeholder {
    font-size: 1rem;
  }

  .StepFinal-InputMobile input:focus {
    font-size: 1rem;
  }

  .StepFinal-Input p {
    font-size: 1.2rem;
  }

  .StepFinal-Input input {
    font-size: 1rem;
    width: 60%;
    height: 2rem;
    margin: 0 1rem 0;
  }

  .StepFinal-Input input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .StepFinal-Input input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .StepFinal-Input input::placeholder {
    font-size: 1rem;
  }

  .StepFinal-Input input:focus {
    font-size: 1rem;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 0.75rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-MobileHand img {
    width: 15%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .Step-Top {
    flex-direction: column;
    width: 100%;
    height: 80%;
  }

  .Step-Left {
    justify-content: flex-end;
    padding: 5%;
    width: 100%;
    height: 50%;
  }

  .Step-Left h1 {
    font-size: 4rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 1.4rem;
    width: 100%;
    text-align: end;
  }

  .Step-Right {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 50%;
  }

  .Step-Radio {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 0 10% !important;
    height: 90%;
    width: 100%;
  }

  .Step-RadioButton {
    font-size: 1rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 4rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 1rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 4rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 1rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 4rem !important;
  }

  .ant-radio-button-wrapper:hover {
    color: #000 !important;
  }

  .Step-Bottom {
    height: 20%;
  }

  .Step-Arrows {
    font-size: 2rem;
  }

  .Progress {
    margin: 0 1.5rem;
  }

  .StepFinal-Top {
    flex-direction: column;
  }

  .StepFinal-Left {
    flex-direction: column;
    padding: 0 5%;
    width: 100%;
    height: 100%;
  }

  .StepFinal-Left button {
    font-size: 0.813rem;
    margin: 2% 0 0;
    width: 20%;
    height: 3rem;
  }

  .StepFinal-Left-Title {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .StepFinal-Left-Title h1 {
    font-size: 3.5rem;
  }

  .StepFinal-MobileHand {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .StepFinal-MobileHand img {
    width: 15%;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-Right {
    display: none;
  }

  .StepFinal-InputName {
    display: none;
  }

  .StepFinal-Input {
    width: 100%;
  }

  .StepFinal-InputMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .StepFinal-Input p {
    color: #fff;
    font-size: 2.2rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile p {
    color: #fff;
    font-size: 2.2rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 70%;
    height: 3rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input::placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input:focus {
    outline: none;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-Input input {
    width: 70%;
    height: 3rem;
    margin: 0 1rem 0;
  }
}

@media screen and (min-width: 1400px) {
  .Step-Left h1 {
    font-size: 5rem;
    line-height: 1;
    width: 70%;
  }

  .Step-Left p {
    font-size: 1.5rem;
    line-height: 1;
    width: 50%;
  }

  .StepFinal-Left-Title h1 {
    font-size: 5rem;
    margin: 0 0 2.5rem;
  }

  .StepFinal-Right p {
    font-size: 1.3rem;
    width: 70%;
    margin-bottom: 70%;
  }

  .StepFinal-Right img {
    width: 8rem;
  }

  .StepFinal-Hand p {
    font-size: 6rem;
    margin: 0 1rem;
    padding-bottom: 4rem;
    width: 10%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .Step-Left h1 {
    font-size: 5.5rem;
    width: 65%;
  }

  .Step-Left p {
    font-size: 2rem;
    width: 50%;
  }

  .Step-RadioButton {
    font-size: 1.188rem !important;
    margin: 0 2rem 2rem 0 !important;
    line-height: 1 !important;
    width: 14rem !important;
    height: 5rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 1.188rem !important;
    margin: 0 2rem 2rem 0 !important;
    line-height: 1 !important;
    width: 14rem !important;
    height: 5rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 1.188rem !important;
    margin: 0 2rem 2rem 0 !important;
    line-height: 1 !important;
    width: 14rem !important;
    height: 5rem !important;
  }

  .StepFinal-Left-Title h1 {
    font-size: 6rem;
  }

  .StepFinal-Left button {
    font-size: 1.188rem;
    width: 10rem;
    height: 3.5rem;
  }

  .StepFinal-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 70%;
    margin-bottom: 80%;
  }

  .StepFinal-InputName,
  .StepFinal-Input p {
    font-size: 3rem;
  }

  .StepFinal-InputName input {
    font-size: 2.5rem;
    height: 4rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputName input::-webkit-input-placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-InputName input:-ms-input-placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-InputName input::placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-InputName input:focus {
    font-size: 2.5rem;
  }

  .StepFinal-Input input {
    font-size: 2.5rem;
    height: 4rem;
    margin: 0 1rem 0;
  }

  .StepFinal-Input input::-webkit-input-placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-Input input:-ms-input-placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-Input input::placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-Input input:focus {
    font-size: 2.5rem;
  }
}

.SpinnerModal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 12.5rem; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.LoaderModal {
  margin: 6.25rem auto;
  font-size: 1.563rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.working {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cb333b;
  padding: 25% 0 0;
  height: 100%;
  width: 100%;
}

.working-row {
  align-items: flex-end;
  height: 85%;
}

.working-col-text {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.working-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
}

.working-col-text h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
}

.working-col-text img {
  width: 30%;
}

.progress-end {
  background-color: #fff;
  margin: 0 3rem;
  padding: 0.125rem;
  width: 80%;
  border: 0.188rem solid #000;
  height: 1.875rem;
}

.progress-end .progress-end-bar {
  height: 100%;
  width: 100%;
  background-color: #cb333b;
  -webkit-animation: fill-bar 3s infinite;
          animation: fill-bar 3s infinite;
}

.working-col-arrow {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.working-col-arrow p {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.5rem;
  margin: 0;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .working-col-arrow p {
    font-size: 4rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .working-col-text h1 {
    font-size: 2.6rem;
  }

  .working-col-text h2 {
    font-size: 1.35rem;
  }

  .working-col-arrow p {
    font-size: 4.5rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .working-col-text h1 {
    font-size: 2.65rem;
  }

  .working-col-text h2 {
    font-size: 1.375rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .working-col-text h1 {
    font-size: 2.659rem;
  }

  .working-col-text h2 {
    font-size: 1.379rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .working-col-text h1 {
    font-size: 2.8rem;
  }

  .working-col-text h2 {
    font-size: 1.5rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .working-col-text h1 {
    font-size: 2.9rem;
  }

  .working-col-text h2 {
    font-size: 1.6rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-height: 1072px) {
  .working-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .working-col-text h2 {
    font-size: 1.7rem;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-width: 690px) {
  .working-col-text h1 {
    font-size: 3.5rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .working-col-text h2 {
    font-size: 2.2rem;
    width: 75%;
  }

  .working-col-text img {
    width: 20%;
  }

  .working-col-arrow p {
    font-size: 5rem;
  }
}

@media screen and (min-width: 921px) {
  .working-col-text h1 {
    font-size: 4rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .working-col-text h2 {
    font-size: 2.4rem;
  }

  .working-col-arrow p {
    font-size: 6rem;
  }
}

@media screen and (min-width: 992px) {
  .working {
    padding: 8% 0 0;
  }

  .working-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 85%;
  }

  .working-col-text h2 {
    font-size: 2.8rem;
    width: 70%;
  }

  .working-col-text img {
    width: 10%;
  }

  .working-col-arrow p {
    font-size: 6.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .working-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 4%;
    width: 75%;
  }

  .working-col-text h2 {
    font-size: 3rem;
    width: 65%;
  }

  .working-col-arrow p {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1680px) {
  .working-col-text h1 {
    font-size: 5.5rem;
    margin: 0 0 3%;
    width: 70%;
  }

  .working-col-text img {
    width: 8%;
  }

  .working-col-text h2 {
    font-size: 3.4rem;
    width: 50%;
  }
}

.BlogProfile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.BlogProfile-Title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 25%;
  width: 80%;
}

.BlogProfile-Title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.BlogProfile-Title h1 {
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  color: #000;
  line-height: 1;
  margin: 0 0 1rem;
  width: 40%;
}

.BlogProfile-Title a {
  display: none;
}

.BlogProfile-Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 60%;
}

.BlogProfile-Article {
  display: flex;
  flex-direction: column;
  width: 23%;
  height: 100%;
}

.BlogProfile-Article h2 {
  color: #000;
  font-size: 2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 0.5rem;
}

.BlogProfile-Article h4 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 1rem 0 0;
}

@media screen and (max-width: 479px) {
  .BlogProfile-Title {
    padding: 5%;
    width: 100%;
    height: 20%;
  }

  .BlogProfile-Title h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  }

  .BlogProfile-Title h1 {
    font-size: 3rem;
    line-height: 1;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogProfile-Title a {
    display: block;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    color: unset;
    text-decoration: underline;
  }

  .BlogProfile-Content {
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 70%;
  }

  .BlogProfile-Article {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    width: 100%;
    height: 23%;
  }

  .BlogProfile-Article img {
    width: 80%;
    height: 50%;
  }

  .BlogProfile-Article h2 {
    font-size: 1.5rem;
    margin: 0;
    width: 100%;
  }

  .BlogProfile-Article h4 {
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogProfile-Title h3 {
    font-size: smaller;
  }

  .BlogProfile-Title h1 {
    font-size: 2rem;
  }

  .BlogProfile-Title a {
    font-size: smaller;
  }

  .BlogProfile-Article img {
    width: 80%;
    height: 50%;
  }

  .BlogProfile-Article h2 {
    font-size: 1.2rem;
    margin: 0;
    width: 100%;
  }

  .BlogProfile-Article h4 {
    font-size: smaller;
  }
}

@media screen and (min-height: 719px) and (min-width: 414px) {
  .BlogProfile-Article img {
    width: 80%;
    height: 70%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogProfile-Title {
    padding: 5%;
    width: 100%;
    height: 20%;
  }

  .BlogProfile-Title h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: large;
  }

  .BlogProfile-Title h1 {
    font-size: 4rem;
    line-height: 1;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogProfile-Title a {
    display: block;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    color: unset;
    text-decoration: underline;
    font-size: 1.2rem;
  }

  .BlogProfile-Content {
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 70%;
  }

  .BlogProfile-Article {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    width: 100%;
    height: 23%;
  }

  .BlogProfile-Article img {
    width: 80%;
    height: 70%;
  }

  .BlogProfile-Article h2 {
    font-size: 1.5rem;
    margin: 0;
    width: 100%;
  }

  .BlogProfile-Article h4 {
    margin: 0.5rem 0;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogProfile-Title h1 {
    font-size: 5rem;
    width: 50%;
  }

  .BlogProfile-Title h3 {
    font-size: 1.5rem;
    width: 100%;
  }

  .BlogProfile-Article h2 {
    font-size: 3rem;
  }

  .BlogProfile-Article h4 {
    font-size: 1.2rem;
  }
}

.NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.NotFound-Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 90%;
  width: 100%;
}

.NotFound-Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0 0;
  width: 50%;
  height: 100%;
}

.NotFound-Left h1 {
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  width: 70%;
  line-height: 1;
  margin: 0;
}

.NotFound-Left p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 62%;
  margin: 1.5rem 4rem 0 0;
}

.NotFound-Search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0 0;
}

.NotFound-Search input {
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.125rem solid #000;
  width: 17rem;
  height: 3rem;
  margin: 0 1rem 0;
}

.NotFound-Search input::-webkit-input-placeholder {
  color: #a5a5a5;
  font-size: 1rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.NotFound-Search input:-ms-input-placeholder {
  color: #a5a5a5;
  font-size: 1rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.NotFound-Search input::placeholder {
  color: #a5a5a5;
  font-size: 1rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.NotFound-Search input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.NotFound-Search button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 8rem;
  height: 2.8rem;
}

.NotFound-Search button:hover {
  cursor: pointer;
}

.NotFound-Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0 0;
  width: 50%;
  height: 100%;
}

.NotFound-Right p {
  color: #000;
  font-size: 1.3rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  text-align: center;
  width: 40%;
}

.NotFound-Right img {
  width: 22rem;
}

.NotFound-Paragraph {
  display: none;
}

.NotFound-SearchMobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .NotFound-Top {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 85%;
    width: 100%;
  }

  .NotFound-Left {
    flex-direction: column;
    align-items: center;
    padding: 0 5% 0;
    width: 100%;
    height: 35%;
  }

  .NotFound-Left h1 {
    font-size: 3rem;
    width: 100%;
  }

  .NotFound-Left p {
    display: none;
  }

  .NotFound-Search {
    display: none;
  }

  .NotFound-Right {
    width: 100%;
    height: 65%;
  }

  .NotFound-Right img {
    width: 50%;
  }

  .NotFound-Right p {
    font-size: 1rem;
    width: 60%;
  }

  .NotFound-Paragraph {
    display: block;
    color: #000;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.438rem;
    text-align: justify;
    width: 80%;
    margin: 0 0 1rem;
  }

  .NotFound-SearchMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .NotFound-SearchMobile input {
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.125rem solid #000;
    width: 60%;
    height: 3rem;
    margin: 0 1rem 0;
  }

  .NotFound-SearchMobile input::-webkit-input-placeholder {
    color: #a5a5a5;
    font-size: 1rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NotFound-SearchMobile input:-ms-input-placeholder {
    color: #a5a5a5;
    font-size: 1rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NotFound-SearchMobile input::placeholder {
    color: #a5a5a5;
    font-size: 1rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NotFound-SearchMobile input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NotFound-SearchMobile button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 30%;
    height: 2.8rem;
  }

  .NotFound-SearchMobile button:hover {
    cursor: pointer;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .NotFound-Right img {
    width: 40%;
  }
}

@media screen and (max-height: 570px) {
  .NotFound-Right p {
    font-size: 0.8rem;
  }

  .NotFound-Left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .NotFound-Paragraph {
    display: block;
    color: #000;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    line-height: 1.2;
    text-align: justify;
    width: 80%;
    margin: 0 0 1rem;
  }

  .NotFound-SearchMobile button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 25%;
    height: 2.8rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .NotFound {
    padding: 2% 0 0;
  }

  .NotFound-Left h1 {
    font-size: 7rem;
  }

  .NotFound-Left p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    margin: 1.5rem 4.2rem 1.5rem 0;
    width: 60%;
  }

  .NotFound-Search input {
    font-size: 1.8rem;
    width: 30rem;
    height: 3.5rem;
  }

  .NotFound-Search input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .NotFound-Search input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .NotFound-Search input::placeholder {
    font-size: 1.8rem;
  }

  .NotFound-Search input:focus {
    font-size: 1.8rem;
  }

  .NotFound-Search button {
    font-size: 1.188rem;
    width: 10rem;
    height: 3.5rem;
  }

  .NotFound-Right img {
    width: 50%;
  }

  .NotFound-Right p {
    font-size: 1.5rem;
    width: 35%;
  }
}

.BlogIntroduction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #000;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.BlogIntroduction-Left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}

.BlogIntroduction-Left h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 1rem;
  width: 55%;
}

.BlogIntroduction-Left h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogIntroduction-Left h4 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogIntroduction-Left p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 60%;
}

.BlogIntroduction-Left-Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.BlogIntroduction-Left-Title h1 {
  color: #fff;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
}

.BlogIntroduction-Left-Title img {
  width: 15%;
  margin-right: 2rem;
}

.BlogIntroduction-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}

.BlogIntroduction-Right h1 {
  color: #fff;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
}

.BlogIntroduction-Right h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
  margin: 0 0 1rem;
}

.BlogIntroduction-Right h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogIntroduction-Right h4 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogIntroduction-Right p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1;
  width: 75%;
}

.BlogIntroduction-Right img {
  width: 100%;
}

.BlogIntroduction-Left-Content {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.BlogIntroduction-Right-Newsletter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.BlogNewsletter-Description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.BlogNewsletter-Description h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 100%;
}

.BlogNewsletter-Description p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  line-height: 1.3;
  width: 90%;
}

.BlogNewsletter-Form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 70%;
}

.BlogNewsletter-InputName {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.BlogNewsletter-InputName input {
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 50%;
  height: 3rem;
}

.BlogNewsletter-InputName input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-InputName input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-InputName input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-InputName input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Input {
  display: flex;
  width: 75%;
}

.BlogNewsletter-Input input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 100%;
  height: 3rem;
}

.BlogNewsletter-Input input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Input input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Input input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Input input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogNewsletter-Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 75%;
  margin: 1.5rem 0 1rem;
}

.BlogNewsletter-Button button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 8rem;
  height: 2rem;
}

.BlogNewsletter-Button button:hover {
  cursor: pointer;
}

.Title-Mobile {
  display: none;
}

.Subtitle-Mobile {
  display: none;
}

.BlogIntroduction-ArticleMobile {
  display: none;
}

.BlogIntroduction-NewsletterMobile {
  display: none;
}

.SpiralMobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .BlogIntroduction {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 40% 5% 5%;
    height: 100%;
    width: 100%;
  }

  .Blog-Spiral {
    display: none;
  }

  .SpiralMobile {
    display: flex;
  }

  .BlogIntroduction-Left {
    display: none;
  }

  .BlogIntroduction-Right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .Title-Mobile {
    display: block;
    font-size: 3rem !important;
  }

  .Subtitle-Mobile {
    display: block;
    margin: 0 0 8%;
  }

  .BlogIntroduction-ArticleMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 30%;
  }

  .BlogIntroduction-ArticleMobile img {
    width: 70%;
  }

  .BlogIntroduction-ArticleMobile p {
    margin: 0 0 0.3rem;
    width: 70%;
  }

  .BlogIntroduction-Right img {
    width: 80%;
    height: auto;
  }

  .BlogIntroduction-Right h1 {
    font-size: 1.5rem;
    width: 90%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 1.2rem;
    width: 70%;
    margin: 0 0 0.5rem;
  }

  .BlogIntroduction-Right h3 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    margin: 0 0 2rem;
  }

  .BlogIntroduction-Right h4 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .BlogIntroduction-Right p {
    font-size: 0.75rem;
    width: 75%;
  }

  .BlogIntroduction-Left-Content {
    width: 100%;
  }

  .BlogIntroduction-Right-Newsletter {
    display: none;
  }

  .BlogIntroduction-NewsletterMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BlogNewsletterMobile-Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
  }

  .BlogNewsletterMobile-Description h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1;
    width: 100%;
  }

  .BlogNewsletterMobile-Description p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 0.625rem;
    line-height: 1.438rem;
    width: 100%;
  }

  .BlogNewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 65%;
  }

  .BlogNewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .BlogNewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3rem;
  }

  .BlogNewsletterMobile-InputName input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input {
    display: flex;
    width: 90%;
  }

  .BlogNewsletterMobile-Input input {
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 3rem;
  }

  .BlogNewsletterMobile-Input input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .BlogNewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 8rem;
    height: 2rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogIntroduction {
    padding: 40% 5% 0;
  }

  .BlogIntroduction-ArticleMobile img {
    width: 60%;
  }

  .BlogIntroduction-ArticleMobile p {
    font-size: 0.75rem;
    line-height: 1.2;
    margin: 0 0 0.3rem;
    width: 70%;
  }

  .BlogIntroduction-Right img {
    width: 70%;
    height: auto;
  }

  .BlogIntroduction-Right h1 {
    font-size: 1.2rem;
    width: 90%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 1rem;
    width: 70%;
    margin: 0 0 0.5rem;
  }

  .BlogIntroduction-Right h3 {
    font-size: small;
  }

  .BlogIntroduction-Right h4 {
    font-size: smaller;
  }

  .BlogIntroduction-Right p {
    font-size: 0.75rem;
    width: 75%;
  }

  .BlogNewsletterMobile-Description h2 {
    font-size: 1.2rem;
    line-height: 1;
  }

  .BlogNewsletterMobile-Description p {
    font-size: 0.75rem;
    line-height: 1.1;
  }

  .BlogNewsletterMobile-InputName input {
    font-size: 0.8rem;
    width: 50%;
    height: 2rem;
  }

  .BlogNewsletterMobile-InputName input::-webkit-input-placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-InputName input:-ms-input-placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-InputName input::placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-InputName input:focus {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Input input {
    font-size: 0.8rem;
    width: 100%;
    height: 2rem;
  }

  .BlogNewsletterMobile-Input input::-webkit-input-placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Input input:-ms-input-placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Input input::placeholder {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Input input:focus {
    font-size: 0.8rem;
  }

  .BlogNewsletterMobile-Button button {
    font-size: 0.7rem;
    width: 7rem;
    height: 2rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 1050px) {
  .BlogIntroduction {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 20% 10% 0;
    height: 100%;
    width: 100%;
  }

  .Blog-Spiral {
    display: none;
  }

  .SpiralMobile {
    display: flex;
  }

  .BlogIntroduction-Left {
    display: none;
  }

  .BlogIntroduction-Right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .Title-Mobile {
    display: block;
    font-size: 5rem !important;
  }

  .Subtitle-Mobile {
    display: block;
    margin: 0 0 8%;
  }

  .BlogIntroduction-ArticleMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 30%;
  }

  .BlogIntroduction-ArticleMobile img {
    width: 70%;
  }

  .BlogIntroduction-ArticleMobile p {
    margin: 0 0 0.3rem;
    width: 70%;
  }

  .BlogIntroduction-Right img {
    width: 60%;
    height: auto;
  }

  .BlogIntroduction-Right h1 {
    font-size: 3rem;
    width: 90%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 2rem;
    width: 70%;
    margin: 0 0 0.5rem;
  }

  .BlogIntroduction-Right h3 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: large;
    margin: 0 0 2rem;
  }

  .BlogIntroduction-Right h4 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: large;
  }

  .BlogIntroduction-Right p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 75%;
  }

  .BlogIntroduction-Left-Content {
    width: 100%;
  }

  .BlogIntroduction-Right-Newsletter {
    display: none;
  }

  .BlogIntroduction-NewsletterMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BlogNewsletterMobile-Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
  }

  .BlogNewsletterMobile-Description h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 1;
    width: 100%;
  }

  .BlogNewsletterMobile-Description p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1.1;
    width: 100%;
  }

  .BlogNewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 65%;
  }

  .BlogNewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .BlogNewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 4rem;
  }

  .BlogNewsletterMobile-InputName input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input {
    display: flex;
    width: 90%;
  }

  .BlogNewsletterMobile-Input input {
    color: #fff;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 4rem;
  }

  .BlogNewsletterMobile-Input input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .BlogNewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .BlogNewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 9rem;
    height: 2.5rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogIntroduction-Left-Title {
    margin: 0 0 6%;
  }

  .BlogIntroduction-Left-Title h1 {
    font-size: 7rem;
  }

  .BlogIntroduction-Left-Title img {
    width: 20%;
    margin-right: 2rem;
  }

  .BlogIntroduction-Left h2 {
    font-size: 3.5rem;
    width: 65%;
  }

  .BlogIntroduction-Left h4 {
    font-size: 1.2rem;
  }

  .BlogIntroduction-Left p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 58%;
  }

  .BlogIntroduction-Article-Image {
    width: 80%;
  }

  .BlogIntroduction-Right h1 {
    font-size: 4.5rem;
    width: 75%;
  }

  .BlogIntroduction-Right h2 {
    font-size: 3rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogIntroduction-Right h3 {
    font-size: 1.5rem;
  }

  .BlogIntroduction-Right h4 {
    font-size: 1.2rem;
  }

  .BlogIntroduction-Right p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .BlogNewsletter-InputName input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .BlogNewsletter-InputName input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-InputName input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-InputName input::placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-InputName input:focus {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Input input {
    font-size: 1.8rem;
    width: 100%;
    height: 3.5rem;
  }

  .BlogNewsletter-Input input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Input input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Input input::placeholder {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Input input:focus {
    font-size: 1.8rem;
  }

  .BlogNewsletter-Button button {
    font-size: 1.188rem;
    width: 9rem;
    height: 2.5rem;
  }
}

.BlogPagination {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000;
  height: 100%;
  width: 100%;
}

.BlogPagination hr {
  border-top: 0.063rem solid #fff;
  width: 80%;
}

.BlogPagination-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 10%;
}

.BlogPagination-Header h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
  width: 20%;
}

.BlogPagination-Header input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 40%;
  height: 3rem;
}

.BlogPagination-Header input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogPagination-Header input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogPagination-Header input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogPagination-Header input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogPagination-Header-Select {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 5% 0;
  width: 70%;
}

.BlogPagination-Header-Select h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 0;
}

.BlogPagination-Content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 75%;
}

.ant-pagination-prev,
.ant-pagination-next {
  font-family: "Trona", Arial, Helvetica, sans-serif !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent !important;
  border: none !important;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  color: #fff !important;
  font-size: 2rem !important;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #fff !important;
  font-size: 1.5rem !important;
}

.ant-pagination-item {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif !important;
  background-color: transparent !important;
  border: none !important;
}

.ant-pagination-item a {
  color: #fff !important;
  font-size: 1rem !important;
}

.ant-pagination-item-active a {
  border-bottom: 0.125rem solid #fff;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #fff !important;
}

.ant-pagination-options {
  display: none !important;
}

@media screen and (max-width: 479px) {
  .BlogPagination hr {
    display: none;
  }

  .BlogPagination-Header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;
    width: 100%;
    height: 15%;
  }

  .BlogPagination-Header h2 {
    color: #fff;
    font-size: 2.5rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    line-height: 1;
    margin: 0 0 1rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    display: none;
  }

  .BlogPagination-Header-Select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .BlogPagination-Header-Select h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    margin: 0 1rem;
  }

  .BlogPagination-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogPagination-Header h2 {
    font-size: 2.5rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    font-size: 1.5rem;
    height: 3.5rem;
  }

  .BlogPagination-Header input::-webkit-input-placeholder {
    font-size: 1.5rem;
  }

  .BlogPagination-Header input:-ms-input-placeholder {
    font-size: 1.5rem;
  }

  .BlogPagination-Header input::placeholder {
    font-size: 1.5rem;
  }

  .BlogPagination-Header input:focus {
    font-size: 1.5rem;
  }

  .BlogPagination-Header-Select h4 {
    font-size: 0.8rem;
  }

  .CardArticle h2 {
    font-size: 2.5rem;
  }

  .CardArticle h4 {
    font-size: 1rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogPagination hr {
    display: none;
  }

  .BlogPagination-Header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;
    width: 100%;
    height: 15%;
  }

  .BlogPagination-Header h2 {
    color: #fff;
    font-size: 3.5rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    line-height: 1;
    margin: 0 0 1rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    display: none;
  }

  .BlogPagination-Header-Select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .BlogPagination-Header-Select h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: large;
    margin: 0 1rem;
  }

  .BlogPagination-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 80%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogPagination-Header h2 {
    font-size: 4rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .BlogPagination-Header input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .BlogPagination-Header input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .BlogPagination-Header input::placeholder {
    font-size: 1.8rem;
  }

  .BlogPagination-Header input:focus {
    font-size: 1.8rem;
  }

  .BlogPagination-Header-Select h4 {
    font-size: 1.2rem;
  }

  .CardArticle h2 {
    font-size: 2.8rem;
  }

  .CardArticle h4 {
    font-size: 1.2rem;
  }
}

.CardArticle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 5%;
  width: 32%;
  height: 45%;
}

.CardArticle:hover {
  cursor: pointer;
}

.CardArticle h2 {
  color: #fff;
  font-size: 2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
  margin: 0;
}

.CardArticle h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 1rem 0 0;
}

@media screen and (max-width: 479px) {
  .CardArticle {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5%;
    width: 100%;
    height: 23%;
  }

  .CardArticle h2 {
    font-size: 1.5rem;
    width: 80%;
    margin: 0;
  }

  .CardArticle h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    margin: 1rem 0 0;
  }

  .CardArticle img {
    width: 80%;
    height: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .CardArticle h2 {
    font-size: 1.2rem;
    width: 80%;
    margin: 0;
  }

  .CardArticle h4 {
    font-size: smaller;
    margin: 1rem 0 0;
  }

  .CardArticle img {
    width: 80%;
    height: 80%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .CardArticle {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5% 10%;
    width: 100%;
    height: 23%;
  }

  .CardArticle h2 {
    font-size: 2.5rem;
    width: 80%;
    margin: 0;
  }

  .CardArticle h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: large;
    margin: 1rem 0 0;
  }

  .CardArticle img {
    width: 90%;
    height: 90%;
  }
}

@media screen and (min-width: 1400px) {
  .CardArticle h2 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .CardArticle h2 {
    font-size: 2.8rem;
  }

  .CardArticle h4 {
    font-size: 1.2rem;
  }
}

.help {
  align-items: flex-start;
  background-color: #cb333b;
  padding: 12% 0 0;
  height: 100%;
  width: 100%;
}

.help-col-q h1 {
  color: #fff;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 2rem;
  width: 70%;
}

.help-col-q h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 0 0 1rem;
  width: 70%;
}

.help-col-q h4:hover {
  cursor: pointer;
}

.help-col-q input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 70%;
  height: 3rem;
  margin: 0 0 2rem;
}

.help-col-q input::-webkit-input-placeholder {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.help-col-q input:-ms-input-placeholder {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.help-col-q input::placeholder {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.help-col-q input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.help-col-a h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 1rem;
  width: 80%;
}

.help-col-a p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 80%;
}

@media screen and (max-width: 479px) {
  .help {
    align-items: center;
    padding: 40% 0 0;
  }

  .help-col-q h1 {
    font-size: 3rem;
    width: 80%;
  }

  .help-col-q h4 {
    font-size: 0.938rem;
    margin: 0 0 0.5rem;
    width: 90%;
  }

  .help-col-a h2 {
    font-size: 2rem;
    margin: 0 0 1rem;
    width: 100%;
  }

  .help-col-a p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .help {
    padding: 40% 0 0;
  }

  .help-col-q h1 {
    font-size: 2.2rem;
    width: 80%;
  }

  .help-col-q h4 {
    font-size: 0.8rem;
    margin: 0 0 0.5rem;
    width: 90%;
  }

  .help-col-a h2 {
    font-size: 1.7rem;
    margin: 0 0 1rem;
    width: 100%;
  }

  .help-col-a p {
    font-size: 0.9rem;
    line-height: 1.2;
    width: 100%;
  }

  .help-col-q input {
    font-size: 1.8rem;
    width: 90%;
    height: 3rem;
    margin: 0 0 2rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .help {
    align-items: center;
    padding: 40% 0 0;
  }

  .help-col-q h1 {
    font-size: 6rem;
    width: 80%;
  }

  .help-col-q h4 {
    font-size: 1.4rem;
    margin: 0 0 0.5rem;
    width: 90%;
  }

  .help-col-a h2 {
    font-size: 3.5rem;
    margin: 0 0 1.5rem;
    width: 100%;
  }

  .help-col-a p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 100%;
  }

  .help-col-q input {
    color: #fff;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 90%;
    height: 4rem;
    margin: 0 0 2rem;
  }

  .help-col-q input::-webkit-input-placeholder {
    color: #fff;
    font-size: 1.8rem;
  }

  .help-col-q input:-ms-input-placeholder {
    color: #fff;
    font-size: 1.8rem;
  }

  .help-col-q input::placeholder {
    color: #fff;
    font-size: 1.8rem;
  }

  .help-col-q input:focus {
    outline: none;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1400px) {
  .help-col-q h1 {
    font-size: 5rem;
    width: 70%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .help-col-q h1 {
    font-size: 7rem;
  }

  .help-col-q h4 {
    font-size: 1.2rem;
  }

  .help-col-q input {
    font-size: 1.8rem;
    height: 3.5rem;
    margin: 0 0 2.5rem;
  }

  .help-col-q input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .help-col-q input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .help-col-q input::placeholder {
    font-size: 1.8rem;
  }

  .help-col-q input:focus {
    font-size: 1.8rem;
  }

  .help-col-a h2 {
    font-size: 4rem;
    margin: 0 0 2rem;
  }

  .help-col-a p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }
}

.ContactForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #000;
  padding: 10% 0;
  min-height: 100vh;
  width: 100%;
}

.ContactForm-Left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4% 4% 2%;
  width: 40%;
  height: 100%;
}

.ContactForm-Left h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  line-height: 1;
  width: 80%;
}

.ContactForm-Left h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
}

.ContactForm-Left h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.ContactForm-Left p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 72%;
}

.ContactForm-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6% 2% 2%;
  width: 60%;
  height: 100%;
}

.ContactForm-Right h1 {
  display: none;
}

.ContactForm-Right h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
  margin: 2rem 0 0;
}

.ContactForm-Right p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 75%;
}

.ContactForm-Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.ContactForm-Mobile {
  display: none;
}

.ContactForm-InputName {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.ContactForm-InputName input {
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 50%;
  height: 3rem;
}

.ContactForm-InputName input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-InputName input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-InputName input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-InputName input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Input {
  display: flex;
  width: 75%;
}

.ContactForm-Input input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 100%;
  height: 3rem;
}

.ContactForm-Input input::-webkit-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Input input:-ms-input-placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Input input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Input input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 75%;
  margin: 0.5rem 0 3rem;
}

.ContactForm-Button button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 7.5rem;
  height: 2rem;
}

.ContactForm-Button button:hover {
  cursor: pointer;
}

.ContactForm-Checkbox {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.ant-checkbox-inner {
  background-color: #000 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #fff !important;
}

.ant-checkbox + span {
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
}

.Checkbox-Left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.Checkbox-Right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

@media screen and (max-width: 479px) {
  .ContactForm {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 40% 0 5%;
  }

  .ContactForm-Left {
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 25%;
  }

  .ContactForm-Left h1 {
    display: none;
  }

  .ContactForm-Left h2 {
    font-size: 1.5rem;
  }

  .ContactForm-Left h3 {
    font-size: small;
  }

  .ContactForm-Left p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .ContactForm-Right {
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 75%;
  }

  .ContactForm-Right h1 {
    display: block;
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 3.75rem;
    line-height: 1;
    margin: 0 0 1rem;
    width: 100%;
  }

  .ContactForm-Right h3 {
    font-size: small;
  }

  .ContactForm-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    margin: 0;
    width: 100%;
  }

  .ContactForm-Form {
    margin: 5% 0;
    height: 60%;
  }

  .ContactForm-InputName {
    display: none;
  }

  .ContactForm-Input {
    display: none;
  }

  .ContactForm-Mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2% 0;
    width: 100%;
  }

  .ContactForm-Mobile p {
    color: #fff;
    font-size: 1.5rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
    width: 45%;
  }

  .ContactForm-Mobile input {
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 2rem;
    margin: 0 1rem 0;
  }

  .ContactForm-Mobile input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ant-checkbox-wrapper {
    font-size: 0.625rem !important;
  }

  .ContactForm-Button {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30%;
    margin: 0;
  }

  .Checkbox-Left {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .Checkbox-Right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }

  .Button-Checkbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40%;
  }

  .Button-Checkbox button {
    font-size: 0.813rem;
    width: 70%;
    height: 2.5rem;
  }

  .Button-Checkbox button:hover {
    cursor: pointer;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .ContactForm-InputName input {
    font-size: 1rem;
    width: 80%;
    height: 2rem;
  }

  .ContactForm-InputName input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .ContactForm-InputName input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .ContactForm-InputName input::placeholder {
    font-size: 1rem;
  }

  .ContactForm-InputName input:focus {
    font-size: 1rem;
  }

  .ContactForm-Input input {
    font-size: 1rem;
    width: 80%;
    height: 2rem;
  }

  .ContactForm-Input input::-webkit-input-placeholder {
    font-size: 1rem;
  }

  .ContactForm-Input input:-ms-input-placeholder {
    font-size: 1rem;
  }

  .ContactForm-Input input::placeholder {
    font-size: 1rem;
  }

  .ContactForm-Input input:focus {
    font-size: 1rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .ContactForm-Left h2 {
    font-size: 1.5rem;
  }

  .ContactForm-Left h3 {
    font-size: smaller;
  }

  .ContactForm-Left p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100%;
  }

  .ContactForm-Right h1 {
    font-size: 2.5rem;
    width: 70%;
  }

  .ContactForm-Right h3 {
    font-size: smaller;
  }

  .ContactForm-Right p {
    font-size: 0.75rem;
    line-height: 1.2;
    margin: 0;
    width: 100%;
  }

  .ContactForm-Mobile p {
    font-size: 1.2rem;
    width: 45%;
  }

  .ContactForm-Mobile input {
    font-size: 0.8rem;
    width: 100%;
    height: 1.5rem;
    margin: 0 1rem 0;
  }

  .ContactForm-Mobile input::-webkit-input-placeholder {
    font-size: 0.8rem;
  }

  .ContactForm-Mobile input:-ms-input-placeholder {
    font-size: 0.8rem;
  }

  .ContactForm-Mobile input::placeholder {
    font-size: 0.8rem;
  }

  .ContactForm-Mobile input:focus {
    font-size: 0.8rem;
  }

  .Button-Checkbox button {
    font-size: 0.7rem;
    width: 60%;
    height: 1.8rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .ContactForm {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 20% 0 5%;
  }

  .ContactForm-Left {
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 25%;
  }

  .ContactForm-Left h1 {
    display: none;
  }

  .ContactForm-Left h2 {
    font-size: 3rem;
  }

  .ContactForm-Left h3 {
    font-size: x-large;
  }

  .ContactForm-Left p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 100%;
  }

  .ContactForm-Right {
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 75%;
  }

  .ContactForm-Right h1 {
    display: block;
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 7rem;
    line-height: 1;
    margin: 0 0 1rem;
    width: 100%;
  }

  .ContactForm-Right h3 {
    font-size: x-large;
  }

  .ContactForm-Right p {
    font-size: 1.4rem;
    line-height: 1.1;
    margin: 0;
    width: 100%;
  }

  .ContactForm-Form {
    margin: 5% 0;
    height: 60%;
  }

  .ContactForm-InputName {
    display: none;
  }

  .ContactForm-Input {
    display: none;
  }

  .ContactForm-Mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2% 0;
    width: 100%;
  }

  .ContactForm-Mobile p {
    color: #fff;
    font-size: 2rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
    width: 45%;
  }

  .ContactForm-Mobile input {
    font-size: 1.8em;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 2.5rem;
    margin: 0 1rem 0;
  }

  .ContactForm-Mobile input::-webkit-input-placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input:-ms-input-placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input::placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input:focus {
    outline: none;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ant-checkbox-wrapper {
    font-size: 1.2rem !important;
  }

  .ContactForm-Button {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30%;
    margin: 0;
  }

  .Checkbox-Left {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .Checkbox-Right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }

  .Button-Checkbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40%;
  }

  .Button-Checkbox button {
    font-size: 1.2rem;
    width: 50%;
    height: 3rem;
  }

  .Button-Checkbox button:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .ContactForm {
    padding: 4% 0;
  }

  .ContactForm-Left {
    padding: 4% 0% 2% 10%;
  }

  .ContactForm-Left h1 {
    font-size: 6rem;
  }

  .ContactForm-Left h2 {
    font-size: 3rem;
  }

  .ContactForm-Left h3 {
    font-size: 1.5rem;
  }

  .ContactForm-Left p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 68%;
  }

  .ContactForm-Right h3 {
    font-size: 1.5rem;
    margin: 2rem 0 0;
  }

  .ContactForm-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 75%;
  }

  .ContactForm-InputName input {
    font-size: 1.8rem;
    height: 4rem;
  }

  .ContactForm-InputName input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-InputName input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-InputName input::placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-InputName input:focus {
    font-size: 1.8rem;
  }

  .ContactForm-Input input {
    font-size: 1.8rem;
    height: 4rem;
  }

  .ContactForm-Input input::-webkit-input-placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-Input input:-ms-input-placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-Input input::placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-Input input:focus {
    font-size: 1.8rem;
  }

  .ContactForm-Button button {
    font-size: 1.188rem;
    width: 9rem;
    height: 3rem;
  }

  .ant-checkbox-wrapper {
    font-size: 1.313rem !important;
  }
}

.contact {
  background-color: #cb333b;
  align-items: flex-end;
  min-height: 100vh;
  width: 100%;
}

.contact-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.contact-col-title h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 5% 0 0;
}

.contact-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.contact-col-text h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.contact-col-text h2 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.contact-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.contact-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #fff;
  text-decoration: underline;
  font-size: 0.8rem;
}

.contact-col-calendar {
  height: 40vh;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .contact-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .contact-col-text p {
    font-size: 0.8rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .contact-col-text h1 {
    font-size: 1.8rem;
  }

  .contact-col-text p {
    font-size: 0.9rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .contact-col-title h3 {
    font-size: medium;
  }

  .contact-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .contact-col-text h2 {
    font-size: large;
  }

  .contact-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .contact-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .contact-col-title h3 {
    font-size: large;
  }

  .contact-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .contact-col-text h2 {
    font-size: x-large;
  }

  .contact-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .contact-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .contact-col-title h3 {
    font-size: large;
  }

  .contact-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .contact-col-text h2 {
    font-size: x-large;
  }

  .contact-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .contact-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .contact-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .contact-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-width: 690px) {
  .contact-col-title h3 {
    font-size: large;
  }

  .contact-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .contact-col-text h2 {
    font-size: x-large;
  }

  .contact-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .contact {
    align-items: center;
    align-content: space-evenly;
  }

  .contact-col-title h3 {
    font-size: x-large;
  }

  .contact-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .contact-col-text h2 {
    font-size: xx-large;
  }

  .contact-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .contact-col-text a {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .contact-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .contact-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .contact-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .contact-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}

.privacy-notice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #000;
  padding: 12% 0 0;
  height: 100%;
  width: 100%;
}

.privacy-notice h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 0 0.5rem;
}

.privacy-notice h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 0 0 1.5rem;
}

.privacy-notice h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  margin: 0 0 1rem;
  text-decoration: underline;
}

.privacy-notice p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  text-align: center;
  width: 80%;
}

@media screen and (max-width: 479px) {
  .privacy-notice {
    padding: 40% 0 0;
  }

  .privacy-notice h1 {
    font-size: 3.75rem;
    margin: 0 0 1rem;
    line-height: 1;
    text-align: center;
    width: 60%;
  }

  .privacy-notice h3 {
    font-size: 0.9rem;
    margin: 0 0 1.5rem;
  }

  .privacy-notice h4 {
    font-size: 0.8rem;
  }

  .privacy-notice p {
    font-size: 0.9rem;
    line-height: 1.1;
    text-align: center;
    width: 90%;
  }
}

@media screen and (max-height: 570px) and (max-width: 480px) {
  .privacy-notice h1 {
    font-size: 2.2rem;
    width: 60%;
  }

  .privacy-notice h3 {
    font-size: 0.7rem;
    margin: 0 0 1.5rem;
  }

  .privacy-notice h4 {
    font-size: 0.6rem;
  }

  .privacy-notice p {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .privacy-notice {
    padding: 8% 0 0;
  }

  .privacy-notice h1 {
    font-size: 7rem;
  }

  .privacy-notice h3 {
    font-size: 1.5rem;
  }

  .privacy-notice h4 {
    font-size: 1.3rem;
  }

  .privacy-notice p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }
}

.terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 12% 0 0;
  height: 100%;
  width: 100%;
}

.terms h1 {
  color: #000;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 0 0.5rem;
}

.terms h2 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3rem;
  margin: 0 0 1.5rem;
  text-align: justify;
  width: 70%;
}

.terms p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.1;
  text-align: justify;
  width: 70%;
}

@media screen and (max-width: 479px) {
  .terms {
    padding: 40% 0 0;
  }

  .terms h1 {
    font-size: 3.75rem;
    margin: 0 0 1rem;
    line-height: 1;
    text-align: center;
    width: 70%;
  }

  .terms h2 {
    font-size: 1.2rem;
    margin: 0 0 1.5rem;
    width: 90%;
  }

  .terms p {
    font-size: 0.9rem;
    line-height: 1.1;
    text-align: center;
    width: 90%;
  }
}

@media screen and (max-height: 570px) and (max-width: 480px) {
  .terms h1 {
    font-size: 2.2rem;
    width: 60%;
  }

  .terms h2 {
    font-size: 1rem;
    width: 80%;
  }

  .terms p {
    font-size: 0.74rem;
    width: 80%;
  }
}

@media screen and (min-width: 414px) and (min-height: 736px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 375px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1220px) and (min-height: 625px) and (max-height: 640px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1600px) and (min-height: 625px) and (max-height: 640px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .terms {
    padding: 8% 0 0;
  }

  .terms h1 {
    font-size: 7rem;
  }

  .terms h2 {
    font-size: 5rem;
  }

  .terms p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }
}

.BlogArticle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.BlogArticle-Top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
}

.BlogArticle-Top h1 {
  color: #000;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: center;
  width: 60%;
  margin: 0 0 1rem;
}

.BlogArticle-Top h2 {
  color: #000;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: center;
  width: 50%;
  margin: 0;
}

.BlogArticle-Top h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.BlogArticle-Top p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 50%;
}

.BlogArticle-Top hr {
  border-top: 0.063rem solid #000;
  width: 80%;
}

.BlogArticle-Middle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40%;
  width: 100%;
}

.BlogArticle-Left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 40%;
}

.BlogArticle-Social {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  height: 5%;
}

.BlogArticle-Social img {
  width: 2rem;
}

.BlogArticle-Sections {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
}

.BlogArticle-Sections h2 {
  color: #000;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 4rem 0 1rem;
}

.BlogArticle-Sections h3 {
  color: #000;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
}

.BlogArticle-Sections h4 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 1rem 0 0;
}

.BlogArticle-Sections hr {
  border-top: 0.063rem solid #000;
  width: 100%;
}

.BlogArticle-Right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 60%;
}

.BlogArticle-SocialMobile {
  display: none;
}

.BlogArticle-Right h3 {
  color: #000;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: left;
  width: 80%;
  margin: 0 0 2rem;
}

.BlogArticle-Right p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 80%;
}

@media screen and (max-width: 479px) {
  .BlogArticle {
    padding: 40% 0 0;
  }

  .BlogArticle-Top {
    height: 33%;
    justify-content: flex-end;
  }

  .BlogArticle-Top h1 {
    font-size: 2.2rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Top h2 {
    font-size: 1.2rem;
    width: 80%;
    margin: 1rem 0 0.5rem;
  }

  .BlogArticle-Top h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: small;
  }

  .BlogArticle-Top p {
    font-size: 1rem;
    width: 80%;
  }

  .BlogArticle-Top hr {
    border-top: 0.063rem solid #000;
    width: 80%;
  }

  .BlogArticle-Top img {
    width: 80%;
  }

  .BlogArticle-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 62%;
    width: 100%;
  }

  .BlogArticle-Left {
    display: none;
  }

  .BlogArticle-Right {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .BlogArticle-SocialMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    width: 80%;
  }

  .BlogArticle-SocialMobile img {
    width: 6%;
    margin: 0 2%;
  }

  .BlogArticle-Right h3 {
    font-size: 2rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogArticle {
    padding: 40% 0 0;
  }

  .BlogArticle-Top h1 {
    font-size: 1.5rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Top h2 {
    font-size: 1rem;
    width: 80%;
    margin: 1rem 0 0.5rem;
  }

  .BlogArticle-Top h3 {
    font-size: smaller;
  }

  .BlogArticle-Top p {
    font-size: 1rem;
    width: 80%;
  }

  .BlogArticle-Top hr {
    border-top: 0.063rem solid #000;
    width: 80%;
  }

  .BlogArticle-Top img {
    width: 80%;
  }

  .BlogArticle-SocialMobile img {
    width: 6%;
    margin: 0 2%;
  }

  .BlogArticle-Right h3 {
    font-size: 1.5rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Right p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 80%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogArticle-Top {
    padding: 20% 10% 0;
  }

  .BlogArticle-Top {
    height: 42%;
    justify-content: flex-end;
  }

  .BlogArticle-Top h1 {
    font-size: 3.2rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Top h2 {
    font-size: 2.2rem;
    width: 80%;
    margin: 1rem 0 0.5rem;
  }

  .BlogArticle-Top h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: x-large;
  }

  .BlogArticle-Top p {
    font-size: 2rem;
    width: 80%;
  }

  .BlogArticle-Top hr {
    border-top: 0.063rem solid #000;
    width: 80%;
  }

  .BlogArticle-Top img {
    width: 80%;
  }

  .BlogArticle-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58%;
    width: 100%;
  }

  .BlogArticle-Left {
    display: none;
  }

  .BlogArticle-Right {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .BlogArticle-SocialMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    width: 80%;
  }

  .BlogArticle-SocialMobile img {
    width: 8%;
    margin: 0 2%;
  }

  .BlogArticle-Right h3 {
    font-size: 3rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Right p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 80%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogArticle-Top h1 {
    font-size: 5.5rem;
    width: 50%;
  }

  .BlogArticle-Top h2 {
    font-size: 3rem;
    margin: 2% 0 0;
    width: 60%;
  }

  .BlogArticle-Top h3 {
    font-size: 1.5rem;
  }

  .BlogArticle-Top p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }

  .BlogArticle-Top img {
    width: 60%;
  }

  .BlogArticle-Top hr {
    width: 85%;
  }

  .BlogArticle-Middle {
    height: 45%;
  }

  .BlogArticle-Social img {
    width: 3rem;
  }

  .BlogArticle-Sections h2 {
    font-size: 4rem;
    margin: 4rem 0 1rem;
    width: 100%;
  }

  .BlogArticle-Sections h3 {
    font-size: 3rem;
  }

  .BlogArticle-Sections h4 {
    font-size: 1.2rem;
  }

  .BlogArticle-Right h3 {
    font-size: 4rem;
    width: 80%;
    margin: 0 0 2rem;
  }

  .BlogArticle-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 80%;
  }
}

