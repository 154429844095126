.investment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #edb8bb;
  min-height: 100vh;
  width: 100%;
}

.investment-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}

.investment h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  text-align: center;
  width: 85%;
}

.investment h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: x-small;
}

.investment-img {
  position: absolute;
  width: 30%;
  will-change: transform;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .investment h1 {
    font-size: 1.8rem;
    line-height: 1;
    width: 95%;
  }

  .investment h2 {
    font-size: smaller;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .investment h1 {
    font-size: 2rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: smaller;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .investment h1 {
    font-size: 2.2rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: small;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .investment h1 {
    font-size: 2.4rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: small;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .investment h1 {
    font-size: 2.6rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: medium;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .investment h1 {
    font-size: 3rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: medium;
  }
}

@media screen and (min-height: 1072px) {
  .investment h1 {
    font-size: 3.4rem;
    line-height: 1;
  }

  .investment h2 {
    font-size: large;
  }
}

@media screen and (min-width: 500px) {
  .investment h2 {
    font-size: medium;
  }
}

@media screen and (min-width: 800px) {
  .investment h1 {
    font-size: 3.5rem;
    width: 60%;
  }

  .investment h2 {
    font-size: large;
  }
}

@media screen and (min-width: 1024px) {
  .investment h1 {
    font-size: 4rem;
    width: 70%;
  }

  .investment h2 {
    font-size: x-large;
  }

  .investment-img {
    width: 20%;
  }
}

@media screen and (min-width: 1200px) {
  .investment h1 {
    font-size: 5rem;
    width: 80%;
  }

  .investment h2 {
    font-size: x-large;
  }
}

@media screen and (min-width: 1680px) {
  .investment h1 {
    font-size: 5.5rem;
    width: 80%;
  }
}
