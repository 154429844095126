.CardArticle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 5%;
  width: 32%;
  height: 45%;
}

.CardArticle:hover {
  cursor: pointer;
}

.CardArticle h2 {
  color: #fff;
  font-size: 2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
  margin: 0;
}

.CardArticle h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 1rem 0 0;
}

@media screen and (max-width: 479px) {
  .CardArticle {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5%;
    width: 100%;
    height: 23%;
  }

  .CardArticle h2 {
    font-size: 1.5rem;
    width: 80%;
    margin: 0;
  }

  .CardArticle h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    margin: 1rem 0 0;
  }

  .CardArticle img {
    width: 80%;
    height: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .CardArticle h2 {
    font-size: 1.2rem;
    width: 80%;
    margin: 0;
  }

  .CardArticle h4 {
    font-size: smaller;
    margin: 1rem 0 0;
  }

  .CardArticle img {
    width: 80%;
    height: 80%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .CardArticle {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5% 10%;
    width: 100%;
    height: 23%;
  }

  .CardArticle h2 {
    font-size: 2.5rem;
    width: 80%;
    margin: 0;
  }

  .CardArticle h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: large;
    margin: 1rem 0 0;
  }

  .CardArticle img {
    width: 90%;
    height: 90%;
  }
}

@media screen and (min-width: 1400px) {
  .CardArticle h2 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .CardArticle h2 {
    font-size: 2.8rem;
  }

  .CardArticle h4 {
    font-size: 1.2rem;
  }
}
