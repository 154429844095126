.OurMethod {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5% 0 0;
  min-height: 100vh;
  width: 100%;
}

.OurMethod-Title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 15vh;
}

.OurMethod-Title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.OurMethod-Content {
  display: flex;
  width: 100%;
  height: 60vh;
}

.OurMethod-Parallax {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.OurMethod-Parallax:hover {
  cursor: pointer;
}

.OurMethod-Parallax-Arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5%;
}

.OurMethod-Parallax-Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.OurMethod-Parallax-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.OurMethod-Parallax-Middle {
  display: none;
}

.OurMethod-Parallax-Arrow p {
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.75rem;
  margin: 0;
}

.OurMethod-Parallax h1 {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.125rem;
  -webkit-text-stroke-color: black;
  font-family: "Dida", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  line-height: 1;
  margin: 3rem 0 0;
}

.OurMethod-Parallax h2 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 4rem;
}

.OurMethod-Paragraph {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  text-align: center;
  width: 54%;
  margin: 0;
}

.methods-parallax {
  height: 60% !important;
  overflow: hidden !important;
}

@media screen and (max-width: 479px) {
  .OurMethod-Title {
    height: 10vh;
  }

  .OurMethod-Title h3 {
    font-size: small;
  }

  .OurMethod-Content {
    flex-direction: column;
    height: 80vh;
  }

  .OurMethod-Parallax {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .OurMethod-Parallax-Left {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Right {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Arrow {
    display: none;
  }

  .OurMethod-Parallax-Middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
  }

  .OurMethod-Parallax-Middle p {
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }

  .OurMethod-Parallax h1 {
    font-size: 3rem;
    margin: 0;
  }

  .OurMethod-Parallax h2 {
    font-size: 2.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 80%;
  }

  .methods-parallax {
    height: 80% !important;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .OurMethod-Parallax h1 {
    font-size: 2.5rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .OurMethod-Parallax h1 {
    font-size: 2.2rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.8rem;
    margin: 0 0 0.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.938rem;
    line-height: 1.188rem;
    width: 80%;
  }
}

@media screen and (max-width: 375px) and (min-width: 361px) and (max-height: 612px) {
  .OurMethod-Parallax h1 {
    font-size: 2rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.9rem;
    line-height: 1.1;
    width: 80%;
  }
}

@media screen and (max-height: 570px) {
  .OurMethod-Parallax h1 {
    font-size: 2rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.625rem;
    width: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .OurMethod-Parallax-Middle p {
    font-size: 1.8rem;
  }

  .OurMethod-Parallax h1 {
    font-size: 1.8rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 1.2rem;
    margin: 0 0 0.3rem;
  }

  .OurMethod-Paragraph {
    font-size: 0.625rem;
    line-height: 1.2;
    width: 80%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .OurMethod-Title {
    height: 15%;
  }

  .OurMethod-Title h3 {
    font-size: x-large;
  }

  .OurMethod-Content {
    flex-direction: column;
    height: 80vh;
  }

  .OurMethod-Parallax {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .OurMethod-Parallax-Left {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Right {
    height: 50%;
    width: 100%;
  }

  .OurMethod-Parallax-Arrow {
    display: none;
  }

  .OurMethod-Parallax-Middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
  }

  .OurMethod-Parallax-Middle p {
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 5rem;
    margin: 0;
  }

  .OurMethod-Parallax h1 {
    font-size: 5rem;
    margin: 0;
  }

  .OurMethod-Parallax h2 {
    font-size: 3rem;
  }

  .OurMethod-Paragraph {
    font-size: 1.2rem;
    line-height: 1;
    width: 80%;
  }

  .methods-parallax {
    height: 80% !important;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .OurMethod-Title h3 {
    font-size: 1.5rem;
  }

  .OurMethod-Parallax-Arrow p {
    font-size: 6.25rem;
  }

  .OurMethod-Parallax h1 {
    font-size: 7rem;
  }

  .OurMethod-Parallax h2 {
    font-size: 5.5rem;
  }

  .OurMethod-Paragraph {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 54%;
  }
}
