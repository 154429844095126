.BlogPagination {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000;
  height: 100%;
  width: 100%;
}

.BlogPagination hr {
  border-top: 0.063rem solid #fff;
  width: 80%;
}

.BlogPagination-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 10%;
}

.BlogPagination-Header h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
  width: 20%;
}

.BlogPagination-Header input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 40%;
  height: 3rem;
}

.BlogPagination-Header input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogPagination-Header input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.BlogPagination-Header-Select {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 5% 0;
  width: 70%;
}

.BlogPagination-Header-Select h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 0;
}

.BlogPagination-Content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 75%;
}

.ant-pagination-prev,
.ant-pagination-next {
  font-family: "Trona", Arial, Helvetica, sans-serif !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent !important;
  border: none !important;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  color: #fff !important;
  font-size: 2rem !important;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #fff !important;
  font-size: 1.5rem !important;
}

.ant-pagination-item {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif !important;
  background-color: transparent !important;
  border: none !important;
}

.ant-pagination-item a {
  color: #fff !important;
  font-size: 1rem !important;
}

.ant-pagination-item-active a {
  border-bottom: 0.125rem solid #fff;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #fff !important;
}

.ant-pagination-options {
  display: none !important;
}

@media screen and (max-width: 479px) {
  .BlogPagination hr {
    display: none;
  }

  .BlogPagination-Header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;
    width: 100%;
    height: 15%;
  }

  .BlogPagination-Header h2 {
    color: #fff;
    font-size: 2.5rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    line-height: 1;
    margin: 0 0 1rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    display: none;
  }

  .BlogPagination-Header-Select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .BlogPagination-Header-Select h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    margin: 0 1rem;
  }

  .BlogPagination-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogPagination-Header h2 {
    font-size: 2.5rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    font-size: 1.5rem;
    height: 3.5rem;
  }

  .BlogPagination-Header input::placeholder {
    font-size: 1.5rem;
  }

  .BlogPagination-Header input:focus {
    font-size: 1.5rem;
  }

  .BlogPagination-Header-Select h4 {
    font-size: 0.8rem;
  }

  .CardArticle h2 {
    font-size: 2.5rem;
  }

  .CardArticle h4 {
    font-size: 1rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogPagination hr {
    display: none;
  }

  .BlogPagination-Header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;
    width: 100%;
    height: 15%;
  }

  .BlogPagination-Header h2 {
    color: #fff;
    font-size: 3.5rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    line-height: 1;
    margin: 0 0 1rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    display: none;
  }

  .BlogPagination-Header-Select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .BlogPagination-Header-Select h4 {
    color: #fff;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: large;
    margin: 0 1rem;
  }

  .BlogPagination-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 80%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogPagination-Header h2 {
    font-size: 4rem;
    width: 20%;
  }

  .BlogPagination-Header input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .BlogPagination-Header input::placeholder {
    font-size: 1.8rem;
  }

  .BlogPagination-Header input:focus {
    font-size: 1.8rem;
  }

  .BlogPagination-Header-Select h4 {
    font-size: 1.2rem;
  }

  .CardArticle h2 {
    font-size: 2.8rem;
  }

  .CardArticle h4 {
    font-size: 1.2rem;
  }
}
