body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Trona";
  src: local("Trona"), url("./assets/fonts/Trona/font.woff") format("truetype");
}

@font-face {
  font-family: "CaslonProSemiboldItalic";
  src: local("CaslonProSemiboldItalic"),
    url("./assets/fonts/Caslon/ACaslonProSemiboldItalic/font.woff")
      format("truetype");
}

@font-face {
  font-family: "CaslonRegular";
  src: local("CaslonRegular"),
    url("./assets/fonts/Caslon/ACaslonProRegular/font.woff") format("truetype");
}

@font-face {
  font-family: "CaslonBold";
  src: local("CaslonBold"),
    url("./assets/fonts/Caslon/ACaslonProBold/font.woff") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BaiserCircleBold";
  src: local("BaiserCircleBold"),
    url("./assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.ttf")
      format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BaiserSquareBold";
  src: local("BaiserSquareBold"),
    url("./assets/fonts/BasierSquare/BasierSquare-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Dida";
  src: local("Dida"), url("./assets/fonts/Dida/Dida.otf") format("truetype");
}
