.Introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Introduction-Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 85%;
  width: 100%;
}

.Introduction-Left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.Introduction-Left h1 {
  color: #fff;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  width: 55%;
  margin: 0;
  line-height: 1;
}

.Introduction-Right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.Introduction-Right img {
  display: none;
}

.Introduction-Right p {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3.75rem;
  margin: 0;
}

/* Target portrait smartphones */
@media screen and (max-width: 479px) {
  .Introduction-Top {
    flex-direction: column;
    justify-content: flex-start;
  }

  .Introduction-Left {
    flex-direction: column;
    justify-content: flex-end;
    height: 30%;
    width: 100%;
  }

  .Introduction-Left h1 {
    font-size: 2.5rem;
    width: 60%;
  }

  .Introduction-Right {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 70%;
    width: 100%;
  }

  .Introduction-Right img {
    display: block;
    width: 60%;
  }

  .Introduction-Right p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 354px) and (max-height: 626px) {
  .Introduction-Left h1 {
    font-size: 2.5rem;
  }

  .Introduction-Right p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .Introduction-Left h1 {
    font-size: 2.4rem;
    width: 55%;
  }

  .Introduction-Right p {
    font-size: 3.5rem;
  }

  .Introduction-Right img {
    width: 50%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .Introduction-Top {
    flex-direction: column;
    justify-content: flex-start;
  }

  .Introduction-Left {
    flex-direction: column;
    justify-content: flex-end;
    height: 30%;
    width: 100%;
  }

  .Introduction-Left h1 {
    font-size: 5rem;
    width: 60%;
  }

  .Introduction-Right {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 70%;
    width: 100%;
  }

  .Introduction-Right img {
    display: block;
    width: 50%;
  }

  .Introduction-Right p {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .Introduction-Left h1 {
    font-size: 7rem;
  }

  .Introduction-Right p {
    font-size: 6.25rem;
  }
}
