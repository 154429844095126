.art {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6eb61;
  min-height: 100vh;
  width: 100%;
}

.art-row {
  align-items: flex-end;
  height: 85%;
}

.art-col-title {
  display: grid !important;
  justify-items: center;
  align-items: flex-end;
}

.art-col-title h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 10%;
}

.art-col-text {
  display: grid !important;
  justify-items: flex-start;
  align-items: center;
}

.art-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1;
  width: 60%;
}

.art-col-text h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
}

.art-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 80%;
}

.art-col-text a {
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  color: #000;
  text-decoration: underline;
  font-size: 0.8rem;
}

.art-col-image {
  display: grid !important;
  justify-items: center;
  align-items: center;
}

.art-col-image img {
  width: 40%;
}

.art-col-image button {
  display: none;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .art-col-text h1 {
    font-size: 1.6rem;
    width: 55%;
  }

  .art-col-text p {
    font-size: 0.8rem;
  }

  .art-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .art-col-text h1 {
    font-size: 1.8rem;
  }

  .art-col-text p {
    font-size: 0.9rem;
  }

  .art-col-image img {
    width: 40%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .art-col-title h3 {
    font-size: medium;
  }

  .art-col-text h1 {
    font-size: 2.4rem;
    width: 65%;
  }

  .art-col-text h2 {
    font-size: large;
  }

  .art-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .art-col-image img {
    width: 50%;
  }

  .art-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .art {
    padding: 20% 0 0;
  }

  .art-col-title h3 {
    font-size: large;
  }

  .art-col-text h1 {
    font-size: 2.6rem;
    width: 70%;
  }

  .art-col-text h2 {
    font-size: x-large;
  }

  .art-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .art-col-image img {
    width: 50%;
    margin: 10% 0;
  }

  .art-col-text a {
    font-size: 1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .art-col-title h3 {
    font-size: large;
  }

  .art-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .art-col-text h2 {
    font-size: x-large;
  }

  .art-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .art-col-image img {
    width: 60%;
  }

  .art-col-text a {
    font-size: large;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .art-col-title h3 {
    font-size: x-large;
  }

  .art-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .art-col-image img {
    width: 70%;
  }

  .art-col-text a {
    font-size: x-large;
  }
}

@media screen and (min-height: 1072px) {
  .art-col-title h3 {
    font-size: x-large;
  }

  .art-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .art-col-text a {
    font-size: x-large;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) and (max-height: 700px) {
  .art {
    padding: 10% 10% 0;
  }
}

@media screen and (min-width: 690px) {
  .art-col-title h3 {
    font-size: large;
  }

  .art-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .art-col-text h2 {
    font-size: x-large;
  }

  .art-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .art-col-title h3 {
    font-size: x-large;
  }

  .art-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .art {
    padding: 10% 0 0;
  }

  .art-col-title h3 {
    font-size: x-large;
    margin: 0 0 5%;
  }

  .art-row {
    align-items: center;
    align-content: space-evenly;
  }

  .art-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 4.5%;
    width: 65%;
  }

  .art-col-text h2 {
    font-size: xx-large;
  }

  .art-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 60%;
  }

  .art-col-text a {
    display: none;
  }

  .art-col-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 50%;
    height: 3rem;
  }

  .art-col-image button:hover {
    cursor: pointer;
  }

  .art-col-image img {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .art-col-text h1 {
    font-size: 5rem;
    margin: 0 0 4%;
    width: 45%;
  }

  .art-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 55%;
  }
}

@media screen and (min-width: 1680px) {
  .art-col-text h1 {
    font-size: 6.5rem;
    margin: 0 0 3%;
    width: 50%;
  }

  .art-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 50%;
  }
}
