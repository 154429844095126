.find-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0 0;
  height: 100%;
  width: 100%;
}

.find-out-row {
  align-items: flex-end;
  height: 85%;
}

.find-out-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 0.5rem;
  width: 80%;
}

.find-out-col-text h3 {
  display: none;
}

.find-out-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  margin: 0;
  width: 85%;
}

.find-out-col-text a {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  text-decoration: underline;
}

.find-out-start {
  display: none;
}

.find-out-col-img {
  text-align: center;
}

.find-out-col-img img {
  width: 40%;
}

.find-out-col-img p {
  color: #000;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .find-out-col-text h1 {
    font-size: 1.6rem;
  }

  .find-out-col-text p {
    font-size: 0.8rem;
  }

  .find-out-col-text a {
    font-size: 0.75rem;
  }

  .find-out-col-img img {
    width: 45%;
  }

  .find-out-col-img p {
    font-size: 0.9rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .find-out-col-text h1 {
    font-size: 1.8rem;
  }

  .find-out-col-text p {
    font-size: 0.9rem;
  }

  .find-out-col-text a {
    font-size: 0.85rem;
  }

  .find-out-col-img img {
    width: 50%;
  }

  .find-out-col-img p {
    font-size: 1rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .find-out-col-text h1 {
    font-size: 2rem;
    width: 90%;
  }

  .find-out-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .find-out-col-text a {
    font-size: 0.95rem;
  }

  .find-out-col-img img {
    width: 55%;
  }

  .find-out-col-img p {
    font-size: 1.1rem;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .find-out-col-text h1 {
    font-size: 2.4rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.15rem;
  }

  .find-out-col-img img {
    width: 56%;
  }

  .find-out-col-img p {
    font-size: 1.3rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .find-out-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.35rem;
  }

  .find-out-col-img img {
    width: 55%;
  }

  .find-out-col-img p {
    font-size: 1.5rem;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .find-out-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.45rem;
  }

  .find-out-col-img img {
    width: 60%;
  }

  .find-out-col-img p {
    font-size: 1.6rem;
  }
}

@media screen and (min-height: 1072px) {
  .find-out-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.75rem;
  }

  .find-out-col-img img {
    width: 60%;
  }

  .find-out-col-img p {
    font-size: 1.9rem;
  }
}

@media screen and (min-width: 500px) {
  .find-out-col-img img {
    width: 38%;
  }
}

@media screen and (min-width: 690px) {
  .find-out-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .find-out-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }

  .find-out-col-text a {
    font-size: 1.83rem;
  }

  .find-out-col-img img {
    width: 30%;
  }

  .find-out-col-img p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 921px) {
  .find-out-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .find-out-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }

  .find-out-col-text a {
    font-size: 1.83rem;
  }

  .find-out-col-img img {
    width: 30%;
  }

  .find-out-col-img p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .find-out-row {
    align-items: center;
  }

  .find-out-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 85%;
  }

  .find-out-col-text h3 {
    display: block;
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
  }

  .find-out-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 85%;
  }

  .find-out-start {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
  }

  .find-out-start img {
    width: 20%;
  }

  .find-out-start button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3rem;
  }

  .find-out-start button:hover {
    cursor: pointer;
  }

  .find-out-col-text a {
    display: none;
  }

  .find-out-col-img img {
    display: none;
  }

  .find-out-col-img p {
    font-size: 1.5rem;
  }

  .find-out-col-img p {
    margin: 100% 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .find-out-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 1rem;
    width: 75%;
  }

  .find-out-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 75%;
  }
}

@media screen and (min-width: 1680px) {
  .find-out-col-text h1 {
    font-size: 6rem;
    margin: 0 0 1rem;
    width: 70%;
  }

  .find-out-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 70%;
  }

  .find-out-start button {
    width: 40%;
  }

  .find-out-col-img p {
    font-size: 2rem;
  }
}
