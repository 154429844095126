.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 2% 0 0;
  min-height: 100vh;
  width: 100%;
}

.about-row {
  align-items: flex-end;
  height: 80vh;
}

.about-col-text h1 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 0.5rem;
  width: 80%;
}

.about-col-text h2 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 0 0.2rem;
}

.about-col-text p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  margin: 0;
  width: 85%;
}

.about-col-img {
  text-align: center;
}

.about-img {
  width: 38%;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .about-col-text h1 {
    font-size: 1.6rem;
  }

  .about-col-text p {
    font-size: 0.8rem;
  }

  .about-img {
    width: 45%;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .about-col-text h1 {
    font-size: 1.8rem;
  }

  .about-col-text p {
    font-size: 0.9rem;
  }

  .about-img {
    width: 50%;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .about-col-text h1 {
    font-size: 2rem;
    width: 90%;
  }

  .about-col-text h2 {
    font-size: medium;
  }

  .about-col-text p {
    font-size: 1rem;
    line-height: 1.1;
    width: 90%;
  }

  .about-img {
    width: 55%;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .about-col-text h1 {
    font-size: 2.4rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: large;
  }

  .about-col-text p {
    font-size: 1.2rem;
    line-height: 1.1;
    width: 95%;
  }

  .about-img {
    width: 56%;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .about-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.4rem;
    line-height: 1;
    width: 95%;
  }

  .about-img {
    width: 55%;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .about-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }

  .about-img {
    width: 60%;
  }
}

@media screen and (min-height: 1072px) {
  .about-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: xx-large;
  }

  .about-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }

  .about-img {
    width: 60%;
  }
}

@media screen and (min-width: 500px) {
  .about-img {
    width: 38%;
  }
}

@media screen and (min-width: 690px) {
  .about-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }

  .about-img {
    width: 30%;
  }
}

@media screen and (min-width: 921px) {
  .about-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 80%;
  }

  .about-img {
    width: 30%;
  }
}

@media screen and (min-width: 992px) {
  .about-row {
    align-items: center;
  }

  .about-col-text {
    margin: 0;
  }

  .about-col-text h1 {
    font-size: 3.8rem;
    margin: 0 0 1rem;
    width: 85%;
  }

  .about-col-text h2 {
    font-size: large;
  }

  .about-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 85%;
  }

  .about-img {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .about-col-text h1 {
    font-size: 4.5rem;
    margin: 0 0 1rem;
    width: 75%;
  }

  .about-col-text h2 {
    font-size: x-large;
  }

  .about-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
    width: 75%;
  }
}

@media screen and (min-width: 1680px) {
  .about-col-text h1 {
    font-size: 6rem;
    margin: 0 0 1rem;
    width: 70%;
  }

  .about-col-text h2 {
    font-size: xx-large;
  }

  .about-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 70%;
  }
}
