.peak {
  width: 50% !important;
  height: 75% !important;
  margin-left: 50%;
}

.peak-right {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.peak-right img {
  width: 55%;
}

.squeeze {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.squeeze img {
  width: 25%;
}

.squeeze-cpnt {
  width: 100%;
  height: 100vh;
}

.sticky-cpnt {
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 991px) {
  .peak {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .squeeze img {
    width: 15%;
  }
}

@media screen and (min-width: 1680px) {
  .peak-right img {
    width: 50%;
  }
}
