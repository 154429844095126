.help {
  align-items: flex-start;
  background-color: #cb333b;
  padding: 12% 0 0;
  height: 100%;
  width: 100%;
}

.help-col-q h1 {
  color: #fff;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 2rem;
  width: 70%;
}

.help-col-q h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 0 0 1rem;
  width: 70%;
}

.help-col-q h4:hover {
  cursor: pointer;
}

.help-col-q input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 70%;
  height: 3rem;
  margin: 0 0 2rem;
}

.help-col-q input::placeholder {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.help-col-q input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.help-col-a h2 {
  color: #fff;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 1rem;
  width: 80%;
}

.help-col-a p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 80%;
}

@media screen and (max-width: 479px) {
  .help {
    align-items: center;
    padding: 40% 0 0;
  }

  .help-col-q h1 {
    font-size: 3rem;
    width: 80%;
  }

  .help-col-q h4 {
    font-size: 0.938rem;
    margin: 0 0 0.5rem;
    width: 90%;
  }

  .help-col-a h2 {
    font-size: 2rem;
    margin: 0 0 1rem;
    width: 100%;
  }

  .help-col-a p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .help {
    padding: 40% 0 0;
  }

  .help-col-q h1 {
    font-size: 2.2rem;
    width: 80%;
  }

  .help-col-q h4 {
    font-size: 0.8rem;
    margin: 0 0 0.5rem;
    width: 90%;
  }

  .help-col-a h2 {
    font-size: 1.7rem;
    margin: 0 0 1rem;
    width: 100%;
  }

  .help-col-a p {
    font-size: 0.9rem;
    line-height: 1.2;
    width: 100%;
  }

  .help-col-q input {
    font-size: 1.8rem;
    width: 90%;
    height: 3rem;
    margin: 0 0 2rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .help {
    align-items: center;
    padding: 40% 0 0;
  }

  .help-col-q h1 {
    font-size: 6rem;
    width: 80%;
  }

  .help-col-q h4 {
    font-size: 1.4rem;
    margin: 0 0 0.5rem;
    width: 90%;
  }

  .help-col-a h2 {
    font-size: 3.5rem;
    margin: 0 0 1.5rem;
    width: 100%;
  }

  .help-col-a p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 100%;
  }

  .help-col-q input {
    color: #fff;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 90%;
    height: 4rem;
    margin: 0 0 2rem;
  }

  .help-col-q input::placeholder {
    color: #fff;
    font-size: 1.8rem;
  }

  .help-col-q input:focus {
    outline: none;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1400px) {
  .help-col-q h1 {
    font-size: 5rem;
    width: 70%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .help-col-q h1 {
    font-size: 7rem;
  }

  .help-col-q h4 {
    font-size: 1.2rem;
  }

  .help-col-q input {
    font-size: 1.8rem;
    height: 3.5rem;
    margin: 0 0 2.5rem;
  }

  .help-col-q input::placeholder {
    font-size: 1.8rem;
  }

  .help-col-q input:focus {
    font-size: 1.8rem;
  }

  .help-col-a h2 {
    font-size: 4rem;
    margin: 0 0 2rem;
  }

  .help-col-a p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }
}
