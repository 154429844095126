.twoforall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #cb333b;
  min-height: 100vh;
  width: 100%;
}

.twoforall-row {
  align-items: flex-end;
  height: 80vh;
}

.twoforall-col-title h1 {
  color: #fff;
  font-size: 2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
  width: 80%;
}

.twoforall-col-text h1 {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  margin: 0 0 3%;
  width: 100%;
}

.twoforall-col-text p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.2;
  width: 100%;
}

.twoforall-col-text a {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  text-decoration: underline;
}

@media screen and (min-height: 512px) and (max-height: 571px) {
  .twoforall-col-title h1 {
    font-size: 2.1rem;
  }

  .twoforall-col-text h1 {
    font-size: 1.6rem;
  }

  .twoforall-col-text p {
    font-size: 0.8rem;
  }
}

@media screen and (min-height: 572px) and (max-height: 671px) {
  .twoforall-col-title h1 {
    font-size: 3rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 1.8rem;
  }

  .twoforall-col-text p {
    font-size: 0.9rem;
  }
}

@media screen and (min-height: 672px) and (max-height: 771px) {
  .twoforall-col-title h1 {
    font-size: 3.2rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2rem;
  }

  .twoforall-col-text a {
    font-size: small;
  }

  .twoforall-col-text p {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media screen and (min-height: 772px) and (max-height: 871px) {
  .twoforall-col-title h1 {
    font-size: 3.2rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2.4rem;
  }

  .twoforall-col-text p {
    font-size: 1.1rem;
  }
}

@media screen and (min-height: 872px) and (max-height: 971px) {
  .twoforall-col-title h1 {
    font-size: 3.4rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2.6rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: large;
  }

  .twoforall-col-text p {
    font-size: 1.4rem;
    line-height: 1;
  }
}

@media screen and (min-height: 972px) and (max-height: 1071px) {
  .twoforall-col-title h1 {
    font-size: 3.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 2.7rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.5rem;
    line-height: 1;
    width: 95%;
  }
}

@media screen and (min-height: 1072px) {
  .twoforall-col-title h1 {
    font-size: 3.8rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .twoforall-col-text a {
    font-size: xx-large;
  }

  .twoforall-col-text p {
    font-size: 1.8rem;
    line-height: 0.8;
    width: 95%;
  }
}

@media screen and (min-width: 690px) {
  .twoforall-col-title h1 {
    font-size: 5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
    width: 95%;
  }

  .twoforall-col-text a {
    font-size: large;
  }

  .twoforall-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 95%;
  }
}

@media screen and (min-width: 921px) {
  .twoforall-col-title h1 {
    font-size: 5.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3.6rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
  }
}

@media screen and (min-width: 992px) {
  .twoforall-row {
    align-items: flex-start;
    padding: 8% 0 0;
  }

  .twoforall-col-text h1 {
    font-size: 3.2rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.188rem;
    line-height: 1.438rem;
  }
}

@media screen and (min-width: 1200px) {
  .twoforall-col-title h1 {
    font-size: 6.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 3.5rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}

@media screen and (min-width: 1680px) {
  .twoforall-col-title h1 {
    font-size: 7.5rem;
    width: 60%;
  }

  .twoforall-col-text h1 {
    font-size: 5rem;
    margin: 0 0 1rem;
  }

  .twoforall-col-text a {
    font-size: x-large;
  }

  .twoforall-col-text p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 80%;
  }
}
