.BlogArticle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.BlogArticle-Top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
}

.BlogArticle-Top h1 {
  color: #000;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: center;
  width: 60%;
  margin: 0 0 1rem;
}

.BlogArticle-Top h2 {
  color: #000;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: center;
  width: 50%;
  margin: 0;
}

.BlogArticle-Top h3 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.BlogArticle-Top p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 50%;
}

.BlogArticle-Top hr {
  border-top: 0.063rem solid #000;
  width: 80%;
}

.BlogArticle-Middle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40%;
  width: 100%;
}

.BlogArticle-Left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 40%;
}

.BlogArticle-Social {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  height: 5%;
}

.BlogArticle-Social img {
  width: 2rem;
}

.BlogArticle-Sections {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
}

.BlogArticle-Sections h2 {
  color: #000;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 4rem 0 1rem;
}

.BlogArticle-Sections h3 {
  color: #000;
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0;
}

.BlogArticle-Sections h4 {
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 1rem 0 0;
}

.BlogArticle-Sections hr {
  border-top: 0.063rem solid #000;
  width: 100%;
}

.BlogArticle-Right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 60%;
}

.BlogArticle-SocialMobile {
  display: none;
}

.BlogArticle-Right h3 {
  color: #000;
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: left;
  width: 80%;
  margin: 0 0 2rem;
}

.BlogArticle-Right p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 80%;
}

@media screen and (max-width: 479px) {
  .BlogArticle {
    padding: 40% 0 0;
  }

  .BlogArticle-Top {
    height: 33%;
    justify-content: flex-end;
  }

  .BlogArticle-Top h1 {
    font-size: 2.2rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Top h2 {
    font-size: 1.2rem;
    width: 80%;
    margin: 1rem 0 0.5rem;
  }

  .BlogArticle-Top h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: small;
  }

  .BlogArticle-Top p {
    font-size: 1rem;
    width: 80%;
  }

  .BlogArticle-Top hr {
    border-top: 0.063rem solid #000;
    width: 80%;
  }

  .BlogArticle-Top img {
    width: 80%;
  }

  .BlogArticle-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 62%;
    width: 100%;
  }

  .BlogArticle-Left {
    display: none;
  }

  .BlogArticle-Right {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .BlogArticle-SocialMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    width: 80%;
  }

  .BlogArticle-SocialMobile img {
    width: 6%;
    margin: 0 2%;
  }

  .BlogArticle-Right h3 {
    font-size: 2rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 80%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .BlogArticle {
    padding: 40% 0 0;
  }

  .BlogArticle-Top h1 {
    font-size: 1.5rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Top h2 {
    font-size: 1rem;
    width: 80%;
    margin: 1rem 0 0.5rem;
  }

  .BlogArticle-Top h3 {
    font-size: smaller;
  }

  .BlogArticle-Top p {
    font-size: 1rem;
    width: 80%;
  }

  .BlogArticle-Top hr {
    border-top: 0.063rem solid #000;
    width: 80%;
  }

  .BlogArticle-Top img {
    width: 80%;
  }

  .BlogArticle-SocialMobile img {
    width: 6%;
    margin: 0 2%;
  }

  .BlogArticle-Right h3 {
    font-size: 1.5rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Right p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 80%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogArticle-Top {
    padding: 20% 10% 0;
  }

  .BlogArticle-Top {
    height: 42%;
    justify-content: flex-end;
  }

  .BlogArticle-Top h1 {
    font-size: 3.2rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Top h2 {
    font-size: 2.2rem;
    width: 80%;
    margin: 1rem 0 0.5rem;
  }

  .BlogArticle-Top h3 {
    color: #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: x-large;
  }

  .BlogArticle-Top p {
    font-size: 2rem;
    width: 80%;
  }

  .BlogArticle-Top hr {
    border-top: 0.063rem solid #000;
    width: 80%;
  }

  .BlogArticle-Top img {
    width: 80%;
  }

  .BlogArticle-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58%;
    width: 100%;
  }

  .BlogArticle-Left {
    display: none;
  }

  .BlogArticle-Right {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .BlogArticle-SocialMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    width: 80%;
  }

  .BlogArticle-SocialMobile img {
    width: 8%;
    margin: 0 2%;
  }

  .BlogArticle-Right h3 {
    font-size: 3rem;
    width: 80%;
    margin: 0 0 1rem;
  }

  .BlogArticle-Right p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 80%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogArticle-Top h1 {
    font-size: 5.5rem;
    width: 50%;
  }

  .BlogArticle-Top h2 {
    font-size: 3rem;
    margin: 2% 0 0;
    width: 60%;
  }

  .BlogArticle-Top h3 {
    font-size: 1.5rem;
  }

  .BlogArticle-Top p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }

  .BlogArticle-Top img {
    width: 60%;
  }

  .BlogArticle-Top hr {
    width: 85%;
  }

  .BlogArticle-Middle {
    height: 45%;
  }

  .BlogArticle-Social img {
    width: 3rem;
  }

  .BlogArticle-Sections h2 {
    font-size: 4rem;
    margin: 4rem 0 1rem;
    width: 100%;
  }

  .BlogArticle-Sections h3 {
    font-size: 3rem;
  }

  .BlogArticle-Sections h4 {
    font-size: 1.2rem;
  }

  .BlogArticle-Right h3 {
    font-size: 4rem;
    width: 80%;
    margin: 0 0 2rem;
  }

  .BlogArticle-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 80%;
  }
}
