.Stepper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.Stepper-Top {
  display: flex;
  color: #fff;
  height: 85%;
  width: 100%;
}

@media screen and (max-width: 479px) {
  .Stepper {
    padding: 40% 0 0;
  }
}
