.terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 12% 0 0;
  height: 100%;
  width: 100%;
}

.terms h1 {
  color: #000;
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 0 0.5rem;
}

.terms h2 {
  color: #000;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 3rem;
  margin: 0 0 1.5rem;
  text-align: justify;
  width: 70%;
}

.terms p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.1;
  text-align: justify;
  width: 70%;
}

@media screen and (max-width: 479px) {
  .terms {
    padding: 40% 0 0;
  }

  .terms h1 {
    font-size: 3.75rem;
    margin: 0 0 1rem;
    line-height: 1;
    text-align: center;
    width: 70%;
  }

  .terms h2 {
    font-size: 1.2rem;
    margin: 0 0 1.5rem;
    width: 90%;
  }

  .terms p {
    font-size: 0.9rem;
    line-height: 1.1;
    text-align: center;
    width: 90%;
  }
}

@media screen and (max-height: 570px) and (max-width: 480px) {
  .terms h1 {
    font-size: 2.2rem;
    width: 60%;
  }

  .terms h2 {
    font-size: 1rem;
    width: 80%;
  }

  .terms p {
    font-size: 0.74rem;
    width: 80%;
  }
}

@media screen and (min-width: 414px) and (min-height: 736px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 375px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1220px) and (min-height: 625px) and (max-height: 640px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1600px) and (min-height: 625px) and (max-height: 640px) {
  .terms {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .terms {
    padding: 8% 0 0;
  }

  .terms h1 {
    font-size: 7rem;
  }

  .terms h2 {
    font-size: 5rem;
  }

  .terms p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }
}
