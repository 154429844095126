.WhatsappCpnt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.WhatsappCpnt img {
  width: 5%;
  margin: 1.5rem;
}

.WhatsappCpnt img:hover {
  cursor: pointer;
}

.WhatsappCpnt button {
  display: none;
}

@media screen and (max-width: 479px) {
  .WhatsappCpnt {
    justify-content: space-around;
    align-items: center;
  }

  .WhatsappCpnt img {
    width: 14%;
  }

  .WhatsappCpnt button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 8rem;
    height: 3rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .WhatsappCpnt img {
    width: 10%;
    margin: 1.5rem;
  }
}
