.header-tfa {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.navbar-logo {
  width: 20%;
}

.navbar-mobile-button {
  display: none;
}

.navbar-menu {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 55%;
}

.navbar-menu li {
  padding: 0 5%;
  list-style-type: none;
}

.navbar-menu li a {
  text-decoration: none;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1.063rem;
  color: inherit;
}

.navbar-menu li::after {
  display: block;
  content: "";
  border-bottom: solid 0.125rem #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbar-menu.black li::after {
  border-bottom: solid 0.125rem #000;
}

.navbar-menu li:hover::after {
  transform: scaleX(1);
}

.navbar-list {
  display: block;
  content: "";
  border-bottom: solid 0.125rem #fff;
  transition: transform 250ms ease-in-out;
  transform: scaleX(1);
}

.navbar-list.black {
  border-bottom: solid 0.125rem #000;
}

.navbar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  color: #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1.063rem;
  width: 15%;
  height: 2.5rem;
}

.navbar-button:hover {
  cursor: pointer;
}

.navbar-blank {
  display: none;
}

.navbar-footer {
  display: none;
}

.navbar-mobile {
  display: none;
}

.navbar-mobile button {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 10%;
}

@media screen and (max-width: 966px) {
  .navbar-logo {
    padding: 0 0 0 2%;
    width: 30%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    height: 100%;
  }

  .navbar-mobile-button.active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
  }

  .navbar-mobile-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    color: #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 2.5rem;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 40%;
    margin: 0 0 10%;
    padding: 0;
    width: 100%;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li a {
    font-size: 1.5rem;
  }

  .navbar-button {
    display: none;
  }

  .navbar-blank.active {
    display: flex;
    height: 15%;
  }

  .navbar-footer.active {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 10%;
  }

  .navbar-close {
    height: 40%;
  }

  .navbar-whats {
    width: 7%;
  }

  .navbar-mobile {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
  }

  .mobile-image {
    width: 45%;
  }

  .navbar-mobile button {
    width: 30%;
    height: auto;
  }

  .button-image {
    width: 60%;
  }
}

@media screen and (max-width: 800px) and (min-width: 687px) {
  .navbar-mobile-button button {
    font-size: 1.4rem;
    width: 50%;
    height: 4rem;
  }

  .navbar-logo {
    width: 50%;
  }

  .navbar-whats {
    width: 9%;
  }

  .navbar-mobile {
    width: 20%;
  }
}

@media screen and (min-width: 687px) and (max-width: 966px) {
  .navbar-logo {
    width: 35%;
  }
}

@media screen and (max-width: 686px) {
  .navbar-logo {
    width: 45%;
  }

  .navbar-whats {
    width: 10%;
  }

  .navbar-mobile {
    width: 30%;
  }
}

@media screen and (max-width: 500px) {
  .navbar-logo {
    width: 60%;
  }

  .navbar-whats {
    width: 13%;
  }

  .navbar-mobile {
    width: 40%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .navbar-menu li a {
    font-size: 1rem;
  }

  .navbar-mobile-button {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .navbar-menu {
    justify-content: center;
  }

  .navbar-menu li a {
    font-size: 1.375rem;
  }

  .navbar-button {
    font-size: 1.25rem;
    height: 3rem;
  }
}
