.Footer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.Footer-Top {
  display: flex;
  margin: 2% 0 0;
  width: 100%;
  height: 25%;
}

.Footer-Title {
  display: flex;
  padding: 0 1%;
  width: 50%;
  height: 100%;
}

.Footer-Title img {
  width: 22rem;
}

.Footer-Newsletter {
  display: flex;
  width: 50%;
}

.Newsletter-Description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.Newsletter-Description h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}

.Newsletter-Description p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 80%;
}

.Newsletter-Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.Newsletter-InputName {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.Newsletter-InputName input {
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 50%;
  height: 3rem;
}

.Newsletter-InputName input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-InputName input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Input {
  display: flex;
  width: 75%;
}

.Newsletter-Input input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 100%;
  height: 3rem;
}

.Newsletter-Input input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Input input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.Newsletter-Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 75%;
  margin: 1.5rem 0 1rem;
}

.Newsletter-Button button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 8rem;
  height: 2rem;
}

.Newsletter-Button button:hover {
  cursor: pointer;
}

.Footer-Middle {
  display: flex;
  width: 100%;
  height: 30%;
}

.Middle-Left {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

.Middle-Right {
  display: flex;
  width: 50%;
  height: 100%;
}

.Middle-First {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.Middle-First p {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.Middle-First p:hover {
  cursor: pointer;
}

.Middle-Container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.Middle-Container h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.Middle-Container h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.Middle-Container p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
}

.Middle-Container a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.Middle-Container a:hover {
  cursor: pointer;
}

.Middle-ContainerTwo {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.Middle-ContainerTwo h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.Middle-ContainerTwo h3 {
  color: #fff;
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.Middle-ContainerTwo p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
}

.Middle-ContainerThree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: fit-content;
}

.Middle-ContainerThree img {
  width: 35%;
  margin: 13% 0 0;
}

.Middle-ContainerThree img:hover {
  cursor: pointer;
}

.Middle-ContainerThree p {
  color: #fff;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  margin: 1.5rem 0;
  width: 55%;
}

.Newsletter-Mobile {
  display: none;
}

.Footer-Copyright {
  display: none;
}

.Footer-Bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15%;
  width: 100%;
}

.WhatsappImage {
  width: 4%;
  margin: 0 1rem 1rem;
}

.WhatsappImage:hover {
  cursor: pointer;
}

.TwoforallImage {
  display: none;
}

@media screen and (max-width: 479px) {
  .Footer {
    align-items: center;
  }

  .Footer-Top {
    display: none;
  }

  .Footer-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 65%;
  }

  .Middle-Left {
    width: 100%;
    height: 50%;
  }

  .Middle-Right {
    flex-direction: row-reverse;
    width: 100%;
    height: 50%;
  }

  .Middle-First {
    padding: 15% 10% 0;
    width: 50%;
  }

  .Middle-First p {
    font-size: 0.613rem;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }

  .Middle-Container {
    padding: 10% 0 0;
    width: 50%;
  }

  .Middle-Container h1 {
    font-size: 1.5rem;
  }

  .Middle-Container h3 {
    font-size: 0.875rem;
  }

  .Middle-Container p {
    font-size: 0.813rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerTwo {
    padding: 2% 0 0;
    width: 50%;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1.5rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 0.875rem;
  }

  .Middle-ContainerTwo p {
    font-size: 0.813rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerThree {
    width: 50%;
    height: 100%;
  }

  .Middle-ContainerThree img {
    width: 30%;
    margin: 25% 0 0;
  }

  .Middle-ContainerThree p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0.5rem 0 0;
    width: 75%;
  }

  .Newsletter-Mobile {
    display: flex;
    width: 100%;
    height: 25%;
  }

  .NewsletterMobile-Desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 5%;
    width: 35%;
  }

  .NewsletterMobile-Desc h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1;
  }

  .NewsletterMobile-Desc p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .NewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
  }

  .NewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 75%;
  }

  .NewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3rem;
  }

  .NewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input {
    display: flex;
    width: 75%;
  }

  .NewsletterMobile-Input input {
    color: #fff;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 3rem;
  }

  .NewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .NewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 8rem;
    height: 2rem;
  }

  .Footer-Copyright {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
  }

  .Footer-Copyright p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: smaller;
  }

  .Footer-Copyright i {
    color: #fff;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .Footer-Bottom {
    justify-content: space-around;
    align-items: center;
  }

  .WhatsappImage {
    width: 14%;
  }

  .TwoforallImage {
    display: block;
    width: 60%;
  }
}

@media screen and (max-height: 570px) {
  .Middle-ContainerTwo h1 {
    font-size: 1.2rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 0.813rem;
  }

  .Middle-ContainerTwo p {
    font-size: 0.75rem;
    width: 92%;
  }

  .Middle-Container h1 {
    font-size: 1.2rem;
  }

  .Middle-Container h3 {
    font-size: 0.813rem;
  }

  .Middle-Container p {
    font-size: 0.75rem;
  }

  .Middle-First p {
    font-size: 0.625rem;
  }
}

@media screen and (min-width: 414px) and (min-height: 736px) and (max-width: 480px) and (max-height: 840px) {
  .Footer-Middle {
    height: 55%;
  }
}

@media screen and (min-width: 390px) and (min-height: 844px) and (max-width: 480px) and (max-height: 840px) {
  .Footer-Middle {
    height: 52%;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .Middle-ContainerThree img {
    width: 35%;
  }

  .Middle-ContainerThree p {
    font-size: smaller;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 0.7rem;
  }

  .Middle-ContainerTwo p {
    font-size: 0.75rem;
    width: 92%;
  }

  .Middle-Container h1 {
    font-size: 1.1rem;
  }

  .Middle-Container h3 {
    font-size: 0.7rem;
  }

  .Middle-Container p {
    font-size: 0.75rem;
  }

  .Middle-First p {
    font-size: 0.53rem;
  }

  .NewsletterMobile-Desc h2 {
    font-size: 1.1rem;
  }

  .NewsletterMobile-Desc p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100%;
  }

  .NewsletterMobile-InputName input {
    font-size: 1rem;
    width: 50%;
    height: 2rem;
  }

  .NewsletterMobile-InputName input::placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-InputName input:focus {
    font-size: 1rem;
  }

  .NewsletterMobile-Input input {
    font-size: 1rem;
    width: 100%;
    height: 2rem;
  }

  .NewsletterMobile-Input input::placeholder {
    font-size: 1rem;
  }

  .NewsletterMobile-Input input:focus {
    font-size: 1rem;
  }

  .NewsletterMobile-Button button {
    font-size: 0.8rem;
    width: 7rem;
    height: 2rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .Footer {
    align-items: center;
  }

  .Footer-Top {
    display: none;
  }

  .Footer-Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 65%;
  }

  .Middle-Left {
    width: 100%;
    height: 50%;
  }

  .Middle-Right {
    flex-direction: row-reverse;
    width: 100%;
    height: 50%;
  }

  .Middle-First {
    padding: 15% 10% 0;
    width: 50%;
  }

  .Middle-First p {
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }

  .Middle-Container {
    padding: 10% 0 0;
    width: 50%;
  }

  .Middle-Container h1 {
    font-size: 1.8rem;
  }

  .Middle-Container h3 {
    font-size: 1rem;
  }

  .Middle-Container p {
    font-size: 1.1rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerTwo {
    padding: 2% 0 0;
    width: 50%;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1.8rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 1rem;
  }

  .Middle-ContainerTwo p {
    font-size: 1.1rem;
    line-height: 1.2;
    width: 80%;
  }

  .Middle-ContainerThree {
    width: 50%;
    height: 100%;
  }

  .Middle-ContainerThree img {
    width: 25%;
    margin: 15% 0 0;
  }

  .Middle-ContainerThree p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0.5rem 0 0;
    width: 50%;
  }

  .Newsletter-Mobile {
    display: flex;
    width: 100%;
    height: 35%;
  }

  .NewsletterMobile-Desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 5%;
    width: 35%;
  }

  .NewsletterMobile-Desc h2 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    line-height: 1;
  }

  .NewsletterMobile-Desc p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1.1;
    width: 100%;
  }

  .NewsletterMobile-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
  }

  .NewsletterMobile-InputName {
    display: flex;
    flex-direction: row;
    width: 75%;
  }

  .NewsletterMobile-InputName input {
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    color: #fff;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    height: 3.2rem;
  }

  .NewsletterMobile-InputName input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-InputName input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input {
    display: flex;
    width: 75%;
  }

  .NewsletterMobile-Input input {
    color: #fff;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 3.2rem;
  }

  .NewsletterMobile-Input input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Input input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NewsletterMobile-Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .NewsletterMobile-Button button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 10rem;
    height: 2.2rem;
  }

  .Footer-Copyright {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
  }

  .Footer-Copyright p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: medium;
  }

  .Footer-Copyright i {
    color: #fff;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .Footer-Bottom {
    justify-content: space-around;
    align-items: center;
  }

  .WhatsappImage {
    width: 10%;
  }

  .TwoforallImage {
    display: block;
    width: 50%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1220px) and (min-height: 625px) and (max-height: 640px) {
  .Newsletter-Description h2 {
    font-size: 1.5rem;
    line-height: 1;
  }

  .Newsletter-Description p {
    font-size: 0.75rem;
    line-height: 1rem;
    width: 100%;
  }

  .Newsletter-InputName input {
    border-bottom: 0.063rem solid #fff;
    font-size: 1rem;
    width: 50%;
    height: 2rem;
  }

  .Newsletter-InputName input::placeholder {
    font-size: 1rem;
  }

  .Newsletter-InputName input:focus {
    font-size: 1rem;
  }

  .Newsletter-Input input {
    font-size: 1rem;
    border-bottom: 0.063rem solid #fff;
    height: 2rem;
  }

  .Newsletter-Input input::placeholder {
    font-size: 1rem;
  }

  .Newsletter-Input input:focus {
    font-size: 1rem;
  }

  .Newsletter-Button button {
    font-size: 0.875rem;
    width: 8rem;
    height: 2rem;
  }

  .Middle-First p {
    font-size: small;
  }

  .Middle-Container h1 {
    font-size: 1.5rem;
  }

  .Middle-Container h3 {
    font-size: small;
  }

  .Middle-Container p {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .Middle-ContainerTwo h1 {
    font-size: 1.5rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: small;
  }

  .Middle-ContainerTwo p {
    font-size: 0.75rem;
    line-height: 1rem;
    width: 92%;
  }

  .Middle-ContainerThree p {
    font-size: 0.8rem;
    margin: 1.5rem 0;
    width: 55%;
  }
}

@media screen and (min-width: 1400px) and (min-height: 640px) {
  .Footer-Title img {
    width: 22rem;
  }

  .Newsletter-Description h2 {
    font-size: 2rem;
    line-height: 1;
  }

  .Newsletter-Description p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 100%;
  }

  .Newsletter-InputName {
    width: 75%;
  }

  .Newsletter-InputName input {
    border-bottom: 0.063rem solid #fff;
    font-size: 1.2rem;
    width: 50%;
    height: 3rem;
  }

  .Newsletter-InputName input::placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-InputName input:focus {
    font-size: 1.2rem;
  }

  .Newsletter-Input {
    width: 75%;
  }

  .Newsletter-Input input {
    font-size: 1.2rem;
    border-bottom: 0.063rem solid #fff;
    height: 3rem;
  }

  .Newsletter-Input input::placeholder {
    font-size: 1.2rem;
  }

  .Newsletter-Input input:focus {
    font-size: 1.2rem;
  }

  .Newsletter-Button {
    width: 75%;
    margin: 1.5rem 0 1rem;
  }

  .Newsletter-Button button {
    font-size: 1rem;
    width: 8rem;
    height: 2rem;
  }

  .Middle-First p {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .Middle-Container h1 {
    font-size: 2rem;
  }

  .Middle-Container h3 {
    font-size: 1rem;
  }

  .Middle-Container p {
    font-size: 1.188rem;
    line-height: 1.438rem;
  }

  .Middle-ContainerTwo h1 {
    font-size: 2rem;
  }

  .Middle-ContainerTwo h3 {
    color: #fff;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .Middle-ContainerTwo p {
    font-size: 1.188rem;
    line-height: 1.438rem;
    width: 92%;
  }

  .Middle-ContainerThree p {
    font-size: 1rem;
    margin: 1.5rem 0;
    width: 55%;
  }

  .WhatsappImage {
    width: 5%;
    margin: 0 1rem 1rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .Footer-Title img {
    width: 60%;
  }

  .Newsletter-Description h2 {
    font-size: 3rem;
    line-height: 1;
  }

  .Newsletter-Description p {
    font-size: 1.438rem;
    line-height: 1.688rem;
    width: 90%;
  }

  .Newsletter-InputName input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .Newsletter-InputName input::placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-InputName input:focus {
    font-size: 1.8rem;
  }

  .Newsletter-Input input {
    font-size: 1.8rem;
    height: 3.5rem;
  }

  .Newsletter-Input input::placeholder {
    font-size: 1.8rem;
  }

  .Newsletter-Input input:focus {
    font-size: 1.8rem;
  }

  .Newsletter-Button button {
    font-size: 1.188rem;
    width: 9rem;
    height: 2.5rem;
    margin: 0 0 8%;
  }

  .Middle-First p {
    font-size: 1.2rem;
    margin: 0 0 1.5rem;
  }

  .Middle-Container h1 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
  }

  .Middle-Container h3 {
    font-size: 1.2rem;
  }

  .Middle-Container p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.438rem;
    line-height: 1.688rem;
  }

  .Middle-ContainerTwo h1 {
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
  }

  .Middle-ContainerTwo h3 {
    font-size: 1.2rem;
  }

  .Middle-ContainerTwo p {
    color: #fff;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1.438rem;
    line-height: 1.688rem;
    width: 100%;
  }

  .Middle-ContainerThree p {
    font-size: 1.5rem;
    width: 60%;
  }

  .Footer-Bottom {
    height: 10%;
  }

  .WhatsappImage {
    width: 4.5%;
    margin: 1rem;
  }
}
