.BlogHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.BlogHome-Title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 8% 0 0;
  height: 30%;
  width: 100%;
}

.BlogHome-Left-Title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.BlogHome-Left-Title h1 {
  font-size: 5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 5rem;
}

.BlogHome-Left-Title h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Title-Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
}

.BlogHome-Right-Title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.BlogHome-Right-Title h1 {
  font-size: 3rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 60%;
}

.BlogHome-Right-Title h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Right-Title h4 {
  display: none;
}

.BlogHome-Right-TitleMobile {
  display: none;
}

.BlogHome-Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60%;
}

.BlogHome-Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3% 0 0;
  width: 50%;
  height: 100%;
}

.BlogHome-Left h2 {
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin: 0 0 0.5rem;
}

.BlogHome-Left h2:hover {
  cursor: pointer;
}

.BlogHome-Left h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Left h4 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogHome-Left hr {
  border-top: 0.063rem solid #000;
  width: 100%;
}

.BlogHome-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2.5% 0 0;
  width: 50%;
  height: 100%;
}

.BlogHome-Right h2 {
  font-size: 2.5rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 70%;
  margin: 0 0 1rem;
}

.BlogHome-Right h2:hover {
  cursor: pointer;
}

.BlogHome-Right h3 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  margin: 0 0 2rem;
}

.BlogHome-Right h4 {
  font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
}

.BlogHome-Right p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 75%;
}

.BlogHome-Right p:hover {
  cursor: pointer;
}

.BlogHome-Left-Content {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.BlogHome-Left-Content img {
  width: 20rem;
  margin: 0 0 1.5rem;
}

.BlogHome-Left-Content img:hover {
  cursor: pointer;
}

.BlogHome-Right-Content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.BlogHome-Right-Content img {
  width: 25rem;
  margin: 0 0 2rem;
}

.BlogHome-Right-Content img:hover {
  cursor: pointer;
}

@media screen and (max-width: 479px) {
  .BlogHome-Title {
    flex-direction: column;
    justify-content: center;
    padding: 10% 10% 0;
    height: 25%;
  }

  .BlogHome-Left-Title {
    width: 100%;
    height: 35%;
  }

  .BlogHome-Left-Title h1 {
    font-size: 4rem;
    margin: 0 0 1rem;
  }

  .BlogHome-Left-Title h3 {
    display: none;
  }

  .BlogHome-Title-Content {
    width: 100%;
  }

  .BlogHome-Right-Title {
    width: 100%;
  }

  .BlogHome-Right-Title h1 {
    font-size: 2rem;
    width: 100%;
  }

  .BlogHome-Right-Title h3 {
    display: none;
  }

  .BlogHome-Right-Title h4 {
    display: block;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .BlogHome-Right-TitleMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .BlogHome-Right-TitleMobile h4 {
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Top {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 10%;
    width: 100%;
    height: 65%;
  }

  .BlogHome-Left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .BlogHome-Left h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Left hr {
    width: 100%;
  }

  .BlogHome-Right {
    width: 100%;
  }

  .BlogHome-Right h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogHome-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .BlogHome-Left-Content {
    margin-top: 0;
    align-items: flex-start;
    width: 100%;
  }

  .BlogHome-Left-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content {
    margin-top: 5%;
    width: 100%;
  }

  .BlogHome-Right-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }
}

@media screen and (max-height: 670px) and (max-width: 479px) {
  .BlogHome-Left-Title h1 {
    font-size: 3rem;
  }

  .BlogHome-Right h4 {
    font-size: small;
  }

  .BlogHome-Left h4 {
    font-size: small;
  }

  .BlogHome-Right-Title h1 {
    font-size: 1.5rem;
    margin: 0 0 1rem;
    width: 80%;
  }

  .BlogHome-Left h2 {
    font-size: 1.2rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Right h2 {
    font-size: 1.67rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Right p {
    font-size: 0.813rem;
    line-height: 1;
    margin: 0 0 0.2rem;
    width: 75%;
  }

  .BlogHome-Left-Content img {
    margin: 0 0 0.5rem;
  }

  .BlogHome-Right-Content img {
    margin: 0 0 1rem;
  }
}

@media screen and (max-height: 570px) {
  .BlogHome-Left-Title h1 {
    font-size: 2.5rem;
  }

  .BlogHome-Right-Title h1 {
    font-size: 1.1rem;
    width: 80%;
  }

  .BlogHome-Left h2 {
    font-size: 1.1rem;
  }

  .BlogHome-Right h2 {
    font-size: 1.1rem;
  }

  .BlogHome-Right p {
    font-size: 0.75rem;
    width: 70%;
  }

  .BlogHome-Left-Content img {
    width: 60%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content img {
    width: 70%;
    margin: 0 0 1rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .BlogHome-Title {
    flex-direction: column;
    justify-content: center;
    padding: 10% 10% 0;
    height: 25%;
  }

  .BlogHome-Left-Title {
    width: 100%;
    height: 35%;
  }

  .BlogHome-Left-Title h1 {
    font-size: 4rem;
    margin: 0 0 1rem;
  }

  .BlogHome-Left-Title h3 {
    display: none;
  }

  .BlogHome-Title-Content {
    width: 100%;
  }

  .BlogHome-Right-Title {
    width: 100%;
    height: 65%;
  }

  .BlogHome-Right-Title h1 {
    font-size: 2rem;
    width: 100%;
  }

  .BlogHome-Right-Title h3 {
    display: none;
  }

  .BlogHome-Right-Title h4 {
    display: block;
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
  }

  .BlogHome-Right-TitleMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .BlogHome-Right-TitleMobile h4 {
    font-family: "BaiserCircleBold", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Top {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 10%;
    width: 100%;
    height: 65%;
  }

  .BlogHome-Left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .BlogHome-Left h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .BlogHome-Left hr {
    width: 100%;
  }

  .BlogHome-Right {
    width: 100%;
  }

  .BlogHome-Right h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
    width: 100%;
  }

  .BlogHome-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .BlogHome-Left-Content {
    margin-top: 0;
    align-items: flex-start;
    width: 100%;
  }

  .BlogHome-Left-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content {
    margin-top: 5%;
    width: 100%;
  }

  .BlogHome-Right-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .BlogHome-Title {
    padding: 8% 0 0;
  }

  .BlogHome-Left {
    padding: 0;
  }

  .BlogHome-Right {
    padding: 0;
  }

  .BlogHome-Left-Title h1 {
    font-size: 7rem;
  }

  .BlogHome-Left-Title h3 {
    font-size: 1.3rem;
  }

  .BlogHome-Right-Title h1 {
    font-size: 4.5rem;
    width: 70%;
  }

  .BlogHome-Right-Title h3 {
    font-size: 1.3rem;
  }

  .BlogHome-Left h2 {
    font-size: 3rem;
  }

  .BlogHome-Left h4 {
    font-size: 1.1rem;
  }

  .BlogHome-Right h4 {
    font-size: 1.1rem;
  }

  .BlogHome-Right h2 {
    font-size: 3rem;
  }

  .BlogHome-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    margin: 0 0 0.5rem;
    width: 75%;
  }

  .BlogHome-Left-Content img {
    width: 100%;
    margin: 0 0 1rem;
  }

  .BlogHome-Right-Content img {
    width: 80%;
    margin: 0 0 1rem;
  }
}
