.ContactForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #000;
  padding: 10% 0;
  min-height: 100vh;
  width: 100%;
}

.ContactForm-Left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4% 4% 2%;
  width: 40%;
  height: 100%;
}

.ContactForm-Left h1 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  line-height: 1;
  width: 80%;
}

.ContactForm-Left h2 {
  color: #fff;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2rem;
}

.ContactForm-Left h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.ContactForm-Left p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 72%;
}

.ContactForm-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6% 2% 2%;
  width: 60%;
  height: 100%;
}

.ContactForm-Right h1 {
  display: none;
}

.ContactForm-Right h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: medium;
  margin: 2rem 0 0;
}

.ContactForm-Right p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 75%;
}

.ContactForm-Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.ContactForm-Mobile {
  display: none;
}

.ContactForm-InputName {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.ContactForm-InputName input {
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 50%;
  height: 3rem;
}

.ContactForm-InputName input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-InputName input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Input {
  display: flex;
  width: 75%;
}

.ContactForm-Input input {
  color: #fff;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 100%;
  height: 3rem;
}

.ContactForm-Input input::placeholder {
  color: #606060;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Input input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.ContactForm-Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 75%;
  margin: 0.5rem 0 3rem;
}

.ContactForm-Button button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 7.5rem;
  height: 2rem;
}

.ContactForm-Button button:hover {
  cursor: pointer;
}

.ContactForm-Checkbox {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
}

.ant-checkbox-inner {
  background-color: #000 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #fff !important;
}

.ant-checkbox + span {
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
}

.Checkbox-Left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.Checkbox-Right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

@media screen and (max-width: 479px) {
  .ContactForm {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 40% 0 5%;
  }

  .ContactForm-Left {
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 25%;
  }

  .ContactForm-Left h1 {
    display: none;
  }

  .ContactForm-Left h2 {
    font-size: 1.5rem;
  }

  .ContactForm-Left h3 {
    font-size: small;
  }

  .ContactForm-Left p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    width: 100%;
  }

  .ContactForm-Right {
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 75%;
  }

  .ContactForm-Right h1 {
    display: block;
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 3.75rem;
    line-height: 1;
    margin: 0 0 1rem;
    width: 100%;
  }

  .ContactForm-Right h3 {
    font-size: small;
  }

  .ContactForm-Right p {
    font-size: 1.063rem;
    line-height: 1.25rem;
    margin: 0;
    width: 100%;
  }

  .ContactForm-Form {
    margin: 5% 0;
    height: 60%;
  }

  .ContactForm-InputName {
    display: none;
  }

  .ContactForm-Input {
    display: none;
  }

  .ContactForm-Mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2% 0;
    width: 100%;
  }

  .ContactForm-Mobile p {
    color: #fff;
    font-size: 1.5rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
    width: 45%;
  }

  .ContactForm-Mobile input {
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 2rem;
    margin: 0 1rem 0;
  }

  .ContactForm-Mobile input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ant-checkbox-wrapper {
    font-size: 0.625rem !important;
  }

  .ContactForm-Button {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30%;
    margin: 0;
  }

  .Checkbox-Left {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .Checkbox-Right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }

  .Button-Checkbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40%;
  }

  .Button-Checkbox button {
    font-size: 0.813rem;
    width: 70%;
    height: 2.5rem;
  }

  .Button-Checkbox button:hover {
    cursor: pointer;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .ContactForm-InputName input {
    font-size: 1rem;
    width: 80%;
    height: 2rem;
  }

  .ContactForm-InputName input::placeholder {
    font-size: 1rem;
  }

  .ContactForm-InputName input:focus {
    font-size: 1rem;
  }

  .ContactForm-Input input {
    font-size: 1rem;
    width: 80%;
    height: 2rem;
  }

  .ContactForm-Input input::placeholder {
    font-size: 1rem;
  }

  .ContactForm-Input input:focus {
    font-size: 1rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .ContactForm-Left h2 {
    font-size: 1.5rem;
  }

  .ContactForm-Left h3 {
    font-size: smaller;
  }

  .ContactForm-Left p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100%;
  }

  .ContactForm-Right h1 {
    font-size: 2.5rem;
    width: 70%;
  }

  .ContactForm-Right h3 {
    font-size: smaller;
  }

  .ContactForm-Right p {
    font-size: 0.75rem;
    line-height: 1.2;
    margin: 0;
    width: 100%;
  }

  .ContactForm-Mobile p {
    font-size: 1.2rem;
    width: 45%;
  }

  .ContactForm-Mobile input {
    font-size: 0.8rem;
    width: 100%;
    height: 1.5rem;
    margin: 0 1rem 0;
  }

  .ContactForm-Mobile input::placeholder {
    font-size: 0.8rem;
  }

  .ContactForm-Mobile input:focus {
    font-size: 0.8rem;
  }

  .Button-Checkbox button {
    font-size: 0.7rem;
    width: 60%;
    height: 1.8rem;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .ContactForm {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 20% 0 5%;
  }

  .ContactForm-Left {
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 25%;
  }

  .ContactForm-Left h1 {
    display: none;
  }

  .ContactForm-Left h2 {
    font-size: 3rem;
  }

  .ContactForm-Left h3 {
    font-size: x-large;
  }

  .ContactForm-Left p {
    font-size: 1.4rem;
    line-height: 1.1;
    width: 100%;
  }

  .ContactForm-Right {
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5%;
    margin: 0;
    width: 100%;
    height: 75%;
  }

  .ContactForm-Right h1 {
    display: block;
    color: #fff;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    font-size: 7rem;
    line-height: 1;
    margin: 0 0 1rem;
    width: 100%;
  }

  .ContactForm-Right h3 {
    font-size: x-large;
  }

  .ContactForm-Right p {
    font-size: 1.4rem;
    line-height: 1.1;
    margin: 0;
    width: 100%;
  }

  .ContactForm-Form {
    margin: 5% 0;
    height: 60%;
  }

  .ContactForm-InputName {
    display: none;
  }

  .ContactForm-Input {
    display: none;
  }

  .ContactForm-Mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2% 0;
    width: 100%;
  }

  .ContactForm-Mobile p {
    color: #fff;
    font-size: 2rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
    width: 45%;
  }

  .ContactForm-Mobile input {
    font-size: 1.8em;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 100%;
    height: 2.5rem;
    margin: 0 1rem 0;
  }

  .ContactForm-Mobile input::placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ContactForm-Mobile input:focus {
    outline: none;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .ant-checkbox-wrapper {
    font-size: 1.2rem !important;
  }

  .ContactForm-Button {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30%;
    margin: 0;
  }

  .Checkbox-Left {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .Checkbox-Right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }

  .Button-Checkbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40%;
  }

  .Button-Checkbox button {
    font-size: 1.2rem;
    width: 50%;
    height: 3rem;
  }

  .Button-Checkbox button:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .ContactForm {
    padding: 4% 0;
  }

  .ContactForm-Left {
    padding: 4% 0% 2% 10%;
  }

  .ContactForm-Left h1 {
    font-size: 6rem;
  }

  .ContactForm-Left h2 {
    font-size: 3rem;
  }

  .ContactForm-Left h3 {
    font-size: 1.5rem;
  }

  .ContactForm-Left p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 68%;
  }

  .ContactForm-Right h3 {
    font-size: 1.5rem;
    margin: 2rem 0 0;
  }

  .ContactForm-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 75%;
  }

  .ContactForm-InputName input {
    font-size: 1.8rem;
    height: 4rem;
  }

  .ContactForm-InputName input::placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-InputName input:focus {
    font-size: 1.8rem;
  }

  .ContactForm-Input input {
    font-size: 1.8rem;
    height: 4rem;
  }

  .ContactForm-Input input::placeholder {
    font-size: 1.8rem;
  }

  .ContactForm-Input input:focus {
    font-size: 1.8rem;
  }

  .ContactForm-Button button {
    font-size: 1.188rem;
    width: 9rem;
    height: 3rem;
  }

  .ant-checkbox-wrapper {
    font-size: 1.313rem !important;
  }
}
