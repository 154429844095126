.NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10% 0 0;
  height: 100%;
  width: 100%;
}

.NotFound-Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 90%;
  width: 100%;
}

.NotFound-Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0 0;
  width: 50%;
  height: 100%;
}

.NotFound-Left h1 {
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  width: 70%;
  line-height: 1;
  margin: 0;
}

.NotFound-Left p {
  color: #000;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  width: 62%;
  margin: 1.5rem 4rem 0 0;
}

.NotFound-Search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0 0;
}

.NotFound-Search input {
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.125rem solid #000;
  width: 17rem;
  height: 3rem;
  margin: 0 1rem 0;
}

.NotFound-Search input::placeholder {
  color: #a5a5a5;
  font-size: 1rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.NotFound-Search input:focus {
  outline: none;
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.NotFound-Search button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 8rem;
  height: 2.8rem;
}

.NotFound-Search button:hover {
  cursor: pointer;
}

.NotFound-Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0 0;
  width: 50%;
  height: 100%;
}

.NotFound-Right p {
  color: #000;
  font-size: 1.3rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  text-align: center;
  width: 40%;
}

.NotFound-Right img {
  width: 22rem;
}

.NotFound-Paragraph {
  display: none;
}

.NotFound-SearchMobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .NotFound-Top {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 85%;
    width: 100%;
  }

  .NotFound-Left {
    flex-direction: column;
    align-items: center;
    padding: 0 5% 0;
    width: 100%;
    height: 35%;
  }

  .NotFound-Left h1 {
    font-size: 3rem;
    width: 100%;
  }

  .NotFound-Left p {
    display: none;
  }

  .NotFound-Search {
    display: none;
  }

  .NotFound-Right {
    width: 100%;
    height: 65%;
  }

  .NotFound-Right img {
    width: 50%;
  }

  .NotFound-Right p {
    font-size: 1rem;
    width: 60%;
  }

  .NotFound-Paragraph {
    display: block;
    color: #000;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.438rem;
    text-align: justify;
    width: 80%;
    margin: 0 0 1rem;
  }

  .NotFound-SearchMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .NotFound-SearchMobile input {
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.125rem solid #000;
    width: 60%;
    height: 3rem;
    margin: 0 1rem 0;
  }

  .NotFound-SearchMobile input::placeholder {
    color: #a5a5a5;
    font-size: 1rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NotFound-SearchMobile input:focus {
    outline: none;
    font-size: 1.2rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .NotFound-SearchMobile button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 30%;
    height: 2.8rem;
  }

  .NotFound-SearchMobile button:hover {
    cursor: pointer;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .NotFound-Right img {
    width: 40%;
  }
}

@media screen and (max-height: 570px) {
  .NotFound-Right p {
    font-size: 0.8rem;
  }

  .NotFound-Left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .NotFound-Paragraph {
    display: block;
    color: #000;
    font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    line-height: 1.2;
    text-align: justify;
    width: 80%;
    margin: 0 0 1rem;
  }

  .NotFound-SearchMobile button {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0.125rem solid #000;
    font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
    font-size: 0.813rem;
    width: 25%;
    height: 2.8rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .NotFound {
    padding: 2% 0 0;
  }

  .NotFound-Left h1 {
    font-size: 7rem;
  }

  .NotFound-Left p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    margin: 1.5rem 4.2rem 1.5rem 0;
    width: 60%;
  }

  .NotFound-Search input {
    font-size: 1.8rem;
    width: 30rem;
    height: 3.5rem;
  }

  .NotFound-Search input::placeholder {
    font-size: 1.8rem;
  }

  .NotFound-Search input:focus {
    font-size: 1.8rem;
  }

  .NotFound-Search button {
    font-size: 1.188rem;
    width: 10rem;
    height: 3.5rem;
  }

  .NotFound-Right img {
    width: 50%;
  }

  .NotFound-Right p {
    font-size: 1.5rem;
    width: 35%;
  }
}
