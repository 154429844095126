.Step {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Step-Top {
  display: flex;
  flex-direction: row;
  margin: 0 0 10%;
  width: 100%;
  height: 85%;
}

.Step-Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  height: 100%;
}

.Step-Left h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 65%;
  text-align: end;
}

.Step-Left p {
  font-size: 1.2rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  line-height: 1;
  width: 50%;
  text-align: end;
}

.Step-Right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.Step-Radio {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  height: 40%;
  width: 75%;
}

.Step-RadioButton {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #000 !important;
  border: 0.125rem solid #fff !important;
  color: #fff !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-right: 1rem !important;
  line-height: 1rem !important;
  width: 12rem !important;
  height: 3rem !important;
}

.Step-RadioButton:hover {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  border: 0.125rem solid #000 !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-right: 1rem !important;
  line-height: 1rem !important;
  width: 12rem !important;
  height: 3rem !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  border: 0.125rem solid #000 !important;
  color: #000 !important;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-right: 1rem !important;
  line-height: 1rem !important;
  width: 12rem !important;
  height: 3rem !important;
}

.ant-radio-button-wrapper:hover {
  color: #000 !important;
}

.Step-Bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.Step-Arrows {
  font-family: "Trona", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
}

.Step-Arrows:hover {
  cursor: pointer;
}

.Progress {
  background-color: #fff;
  margin: 0 3rem;
  padding: 0.125rem;
  width: 60%;
  border: 0.188rem solid #fff;
  height: 1.875rem;
}

.Progress .Progress-Bar {
  height: 100%;
  background-color: #000;
  animation: fill-bar 3s infinite;
}

.StepFinal-Top {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 85%;
}

.StepFinal-Bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.StepFinal-Left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80%;
  height: 100%;
}

.StepFinal-Left button {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0.125rem solid #000;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 5% 0;
  font-size: 1rem;
  width: 8rem;
  height: 2.8rem;
}

.StepFinal-Left button:hover {
  cursor: pointer;
}

.StepFinal-Left-Title {
  display: flex;
  justify-content: center;
  width: 100%;
}

.StepFinal-Left-Title h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  text-align: start;
  margin: 0 0 1rem;
}

.StepFinal-MobileHand {
  display: none;
}

.StepFinal-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
}

.StepFinal-Right p {
  color: #fff;
  font-size: 1.1rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  width: 70%;
  text-align: start;
  margin-bottom: 75%;
}

.StepFinal-Right img {
  width: 45%;
}

.StepFinal-Hand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  margin-bottom: 1rem;
}

.StepFinal-Hand p {
  color: #fff;
  font-size: 6rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  margin: 0 1rem;
  padding-bottom: 4rem;
  width: 10%;
}

.StepFinal-InputName {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.StepFinal-Input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.StepFinal-InputName,
.StepFinal-Input p {
  color: #fff;
  font-size: 2.2rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
}

.StepFinal-InputName input {
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 22.8%;
  height: 3rem;
  margin: 0 1rem 0;
}

.StepFinal-InputName input::placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-InputName input:focus {
  outline: none;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-Input input {
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  background: transparent;
  border: none;
  border-bottom: 0.063rem solid #fff;
  width: 60%;
  height: 3rem;
  margin: 0 1rem 0;
}

.StepFinal-Input input::placeholder {
  color: #606060;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-Input input:focus {
  outline: none;
  font-size: 1.5rem;
  font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
}

.StepFinal-InputMobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .Step-Top {
    flex-direction: column;
    width: 100%;
    height: 80%;
  }

  .Step-Left {
    justify-content: flex-end;
    padding: 5%;
    width: 100%;
    height: 50%;
  }

  .Step-Left h1 {
    font-size: 3rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 1rem;
    width: 100%;
    text-align: end;
  }

  .Step-Right {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 50%;
  }

  .Step-Radio {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }

  .Step-RadioButton {
    font-size: 0.75rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 3rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 0.75rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 3rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 0.75rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 3rem !important;
  }

  .ant-radio-button-wrapper:hover {
    color: #000 !important;
  }

  .Step-Bottom {
    height: 20%;
  }

  .Step-Arrows {
    font-size: 2rem;
  }

  .Progress {
    margin: 0 1.5rem;
  }

  .StepFinal-Top {
    flex-direction: column;
  }

  .StepFinal-Left {
    flex-direction: column;
    padding: 0 5%;
    width: 100%;
    height: 100%;
  }

  .StepFinal-Left button {
    font-size: 0.813rem;
    width: 30%;
    height: 2.5rem;
  }

  .StepFinal-Left-Title {
    flex-direction: column;
    width: 100%;
  }

  .StepFinal-Left-Title h1 {
    font-size: 3rem;
  }

  .StepFinal-MobileHand {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .StepFinal-MobileHand img {
    width: 20%;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 1rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-Right {
    display: none;
  }

  .StepFinal-InputName {
    display: none;
  }

  .StepFinal-Input {
    width: 100%;
  }

  .StepFinal-InputMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .StepFinal-Input p {
    color: #fff;
    font-size: 1.8rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile p {
    color: #fff;
    font-size: 1.8rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 60%;
    height: 3rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::placeholder {
    color: #606060;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input:focus {
    outline: none;
    font-size: 1.5rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-Input input {
    width: 60%;
    height: 3rem;
    margin: 0 1rem 0;
  }
}

@media screen and (max-height: 670px) and (max-width: 480px) {
  .Step-Left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 0.75rem;
    width: 100%;
    text-align: end;
  }

  .StepFinal-Left-Title h1 {
    font-size: 1.5rem;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 0.75rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-MobileHand img {
    width: 15%;
  }
}

@media screen and (height: 719px) and (width: 414px) {
  .StepFinal-Left-Title h1 {
    font-size: 2.5rem;
  }

  .StepFinal-MobileHand p {
    font-size: 1rem;
  }

  .StepFinal-MobileHand img {
    width: 18%;
  }

  .StepFinal-InputMobile p {
    font-size: 1.6rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1.2rem;
    width: 65%;
    height: 2.5rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-InputMobile input:focus {
    font-size: 1.2rem;
  }

  .StepFinal-Input p {
    font-size: 1.6rem;
  }

  .StepFinal-Input input {
    font-size: 1.2rem;
    width: 65%;
    height: 2.5rem;
    margin: 0 1rem 0;
  }

  .StepFinal-Input input::placeholder {
    font-size: 1.2rem;
  }

  .StepFinal-Input input:focus {
    font-size: 1.2rem;
  }

  .StepFinal-Left button {
    font-size: 0.813rem;
    margin: 2% 0 0;
    width: 30%;
    height: 2.5rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .Step-Left h1 {
    font-size: 1.8rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 0.75rem;
    line-height: 1.2;
    width: 100%;
    text-align: end;
  }

  .Step-RadioButton {
    font-size: 0.55rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 2.5rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 0.55rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 2.5rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 0.55rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 2.5rem !important;
  }

  .Step-Arrows {
    font-size: 1.5rem;
  }

  .Progress {
    width: 60%;
    height: 1.75rem;
  }

  .StepFinal-Left-Title h1 {
    font-size: 1.5rem;
  }

  .StepFinal-InputMobile p {
    font-size: 1.2rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1rem;
    width: 60%;
    height: 2rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::placeholder {
    font-size: 1rem;
  }

  .StepFinal-InputMobile input:focus {
    font-size: 1rem;
  }

  .StepFinal-Input p {
    font-size: 1.2rem;
  }

  .StepFinal-Input input {
    font-size: 1rem;
    width: 60%;
    height: 2rem;
    margin: 0 1rem 0;
  }

  .StepFinal-Input input::placeholder {
    font-size: 1rem;
  }

  .StepFinal-Input input:focus {
    font-size: 1rem;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 0.75rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-MobileHand img {
    width: 15%;
  }
}

/* Target landscape smartphones, portrait tablets, narrow desktops */
@media screen and (min-width: 480px) and (max-width: 800px) {
  .Step-Top {
    flex-direction: column;
    width: 100%;
    height: 80%;
  }

  .Step-Left {
    justify-content: flex-end;
    padding: 5%;
    width: 100%;
    height: 50%;
  }

  .Step-Left h1 {
    font-size: 4rem;
    width: 100%;
  }

  .Step-Left p {
    font-size: 1.4rem;
    width: 100%;
    text-align: end;
  }

  .Step-Right {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 50%;
  }

  .Step-Radio {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 0 10% !important;
    height: 90%;
    width: 100%;
  }

  .Step-RadioButton {
    font-size: 1rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 4rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 1rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 4rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 1rem !important;
    margin: 1rem !important;
    width: 40% !important;
    height: 4rem !important;
  }

  .ant-radio-button-wrapper:hover {
    color: #000 !important;
  }

  .Step-Bottom {
    height: 20%;
  }

  .Step-Arrows {
    font-size: 2rem;
  }

  .Progress {
    margin: 0 1.5rem;
  }

  .StepFinal-Top {
    flex-direction: column;
  }

  .StepFinal-Left {
    flex-direction: column;
    padding: 0 5%;
    width: 100%;
    height: 100%;
  }

  .StepFinal-Left button {
    font-size: 0.813rem;
    margin: 2% 0 0;
    width: 20%;
    height: 3rem;
  }

  .StepFinal-Left-Title {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .StepFinal-Left-Title h1 {
    font-size: 3.5rem;
  }

  .StepFinal-MobileHand {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .StepFinal-MobileHand img {
    width: 15%;
  }

  .StepFinal-MobileHand p {
    color: #fff;
    font-size: 1.4rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    width: 50%;
    text-align: start;
    margin: 0;
  }

  .StepFinal-Right {
    display: none;
  }

  .StepFinal-InputName {
    display: none;
  }

  .StepFinal-Input {
    width: 100%;
  }

  .StepFinal-InputMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .StepFinal-Input p {
    color: #fff;
    font-size: 2.2rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile p {
    color: #fff;
    font-size: 2.2rem;
    font-family: "Trona", Arial, Helvetica, sans-serif;
    margin: 0 0 0.5rem;
  }

  .StepFinal-InputMobile input {
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
    background: transparent;
    border: none;
    border-bottom: 0.063rem solid #fff;
    width: 70%;
    height: 3rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputMobile input::placeholder {
    color: #606060;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-InputMobile input:focus {
    outline: none;
    font-size: 1.8rem;
    font-family: "CaslonProSemiboldItalic", Arial, Helvetica, sans-serif;
  }

  .StepFinal-Input input {
    width: 70%;
    height: 3rem;
    margin: 0 1rem 0;
  }
}

@media screen and (min-width: 1400px) {
  .Step-Left h1 {
    font-size: 5rem;
    line-height: 1;
    width: 70%;
  }

  .Step-Left p {
    font-size: 1.5rem;
    line-height: 1;
    width: 50%;
  }

  .StepFinal-Left-Title h1 {
    font-size: 5rem;
    margin: 0 0 2.5rem;
  }

  .StepFinal-Right p {
    font-size: 1.3rem;
    width: 70%;
    margin-bottom: 70%;
  }

  .StepFinal-Right img {
    width: 8rem;
  }

  .StepFinal-Hand p {
    font-size: 6rem;
    margin: 0 1rem;
    padding-bottom: 4rem;
    width: 10%;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .Step-Left h1 {
    font-size: 5.5rem;
    width: 65%;
  }

  .Step-Left p {
    font-size: 2rem;
    width: 50%;
  }

  .Step-RadioButton {
    font-size: 1.188rem !important;
    margin: 0 2rem 2rem 0 !important;
    line-height: 1 !important;
    width: 14rem !important;
    height: 5rem !important;
  }

  .Step-RadioButton:hover {
    font-size: 1.188rem !important;
    margin: 0 2rem 2rem 0 !important;
    line-height: 1 !important;
    width: 14rem !important;
    height: 5rem !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-size: 1.188rem !important;
    margin: 0 2rem 2rem 0 !important;
    line-height: 1 !important;
    width: 14rem !important;
    height: 5rem !important;
  }

  .StepFinal-Left-Title h1 {
    font-size: 6rem;
  }

  .StepFinal-Left button {
    font-size: 1.188rem;
    width: 10rem;
    height: 3.5rem;
  }

  .StepFinal-Right p {
    font-size: 1.563rem;
    line-height: 1.813rem;
    width: 70%;
    margin-bottom: 80%;
  }

  .StepFinal-InputName,
  .StepFinal-Input p {
    font-size: 3rem;
  }

  .StepFinal-InputName input {
    font-size: 2.5rem;
    height: 4rem;
    margin: 0 1rem 0;
  }

  .StepFinal-InputName input::placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-InputName input:focus {
    font-size: 2.5rem;
  }

  .StepFinal-Input input {
    font-size: 2.5rem;
    height: 4rem;
    margin: 0 1rem 0;
  }

  .StepFinal-Input input::placeholder {
    font-size: 2.5rem;
  }

  .StepFinal-Input input:focus {
    font-size: 2.5rem;
  }
}
