.privacy-notice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #000;
  padding: 12% 0 0;
  height: 100%;
  width: 100%;
}

.privacy-notice h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Trona", Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 0 0.5rem;
}

.privacy-notice h3 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  margin: 0 0 1.5rem;
}

.privacy-notice h4 {
  color: #fff;
  font-family: "BaiserSquareBold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  margin: 0 0 1rem;
  text-decoration: underline;
}

.privacy-notice p {
  color: #fff;
  font-family: "CaslonRegular", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
  line-height: 1.438rem;
  text-align: center;
  width: 80%;
}

@media screen and (max-width: 479px) {
  .privacy-notice {
    padding: 40% 0 0;
  }

  .privacy-notice h1 {
    font-size: 3.75rem;
    margin: 0 0 1rem;
    line-height: 1;
    text-align: center;
    width: 60%;
  }

  .privacy-notice h3 {
    font-size: 0.9rem;
    margin: 0 0 1.5rem;
  }

  .privacy-notice h4 {
    font-size: 0.8rem;
  }

  .privacy-notice p {
    font-size: 0.9rem;
    line-height: 1.1;
    text-align: center;
    width: 90%;
  }
}

@media screen and (max-height: 570px) and (max-width: 480px) {
  .privacy-notice h1 {
    font-size: 2.2rem;
    width: 60%;
  }

  .privacy-notice h3 {
    font-size: 0.7rem;
    margin: 0 0 1.5rem;
  }

  .privacy-notice h4 {
    font-size: 0.6rem;
  }

  .privacy-notice p {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1792px) and (min-height: 861px) {
  .privacy-notice {
    padding: 8% 0 0;
  }

  .privacy-notice h1 {
    font-size: 7rem;
  }

  .privacy-notice h3 {
    font-size: 1.5rem;
  }

  .privacy-notice h4 {
    font-size: 1.3rem;
  }

  .privacy-notice p {
    font-size: 1.563rem;
    line-height: 1.813rem;
  }
}
